#feature {
        background: #f9f9f9; }

.feature-box-one {
    text-align: center;
    border: 1px solid #e0e0e0;
    padding: 20px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;

    .fea-icon {
        font-size: 30px;
        height: 70px;
        width: 70px;
        margin: 0 auto 15px;
        background: rgba(0,0,0,0.05);
        line-height: 77px;
        border-radius: 50%;
        transition: all 0.3s ease-in-out; }

    h3 {
        font-size: 20px;
        color: #333;
        transition: all 0.3s ease-in-out;
        margin-bottom: 10px; }

    &:hover {
        box-shadow: 0 10px 20px rgba(0,0,0,0.07);
        border-color: $color_theme;
        transform: translateY(-3px);
        background: #FFF;

        .fea-icon {
            background: $color_theme;
            color: #FFF; }

        h3 {
            color: $color_theme; } } }

/* Feature One Colorfull */
.feature-box-one {
    &.color-one {
        .fea-icon {
            background: $color-1;
            color: #FFF;
            box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3); }

        &:hover {
            border-color: $color-1;

            h3 {
                color: $color-1; } } } }



.feature-box-one {
    &.color-two {
        .fea-icon {
            background: $color-2;
            color: #FFF;
            box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3); }

        &:hover {
            border-color: $color-2;

            h3 {
                color: $color-2; } } } }


.feature-box-one {
    &.color-three {
        .fea-icon {
            background: $color_theme;
            color: #FFF;
            box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3); }

        &:hover {
            border-color: $color_theme;

            h3 {
                color: $color_theme; } } } }


.feature-box-one {
    &.color-four {
        .fea-icon {
            background: $color-4;
            color: #FFF;
            box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3); }


        &:hover {
            border-color: $color-4;

            h3 {
                color: $color-4; } } } }

.feature-box-one {
    &.color-five {
        .fea-icon {
            background: $color-5;
            color: #FFF;
            box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3); }


        &:hover {
            border-color: $color-5;

            h3 {
                color: $color-5; } } } }

.feature-box-one {
    &.color-six {
        .fea-icon {
            background: $color-3;
            color: #FFF;
            box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3); }


        &:hover {
            border-color: $color-3;

            h3 {
                color: $color-3; } } } }


/* Icon Box Two */

.feature-box-two {
    background: #FFF;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    transition: all 0.15s ease-in-out;
    box-shadow: 0 10px 20px rgba(0,0,0,0.03);

    .fea-icon {
        margin-bottom: 20px;
        i {
            font-size: 50px; } }

    h3 {
        font-size: 20px;
        margin-bottom: 15px;
        color: #333; }

    p {
        color: #444; }

    &:hover {
        background: $color_theme;

        .fea-icon {
            color: #FFF; }

        h3 {
            color: #FFF; }

        p {
            color: #FFF; } } }

/* Icon Box Three */

.feature-box-three {
    text-align: center;
    margin-bottom: 30px;
    padding: 30px;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;


    .fea-icon {
        margin-bottom: 20px;
        i {
            font-size: 60px;
            color: $color_theme; } }

    h3 {
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        position: relative;
        padding-bottom: 10px;

        &:after {
            position: absolute;
            content: '';
            height: 2px;
            width: 50px;
            background: $color_theme;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            transition: all 0.3s linear; } }

    &:hover {
        box-shadow: 0 0 50px rgba(0,0,0,0.085);
        h3 {
            color: $color_theme;

            &:after {
                width: 70px; } } } }


.feature-box-three {
    &.color-one {

        .fea-icon {
            margin-bottom: 20px;
            i {
                font-size: 60px;
                color: $color-1; } }

        &:hover {
            h3 {
                color: $color-1;

                &:after {
                    background: $color-1; } } } } }

.feature-box-three {
    &.color-two {

        .fea-icon {
            margin-bottom: 20px;
            i {
                font-size: 60px;
                color: $color-2; } }

        &:hover {
            h3 {
                color: $color-2;

                &:after {
                    background: $color-2; } } } } }

.feature-box-three {
    &.color-three {

        .fea-icon {
            margin-bottom: 20px;
            i {
                font-size: 60px;
                color: $color_theme; } }

        &:hover {
            h3 {
                color: $color_theme;

                &:after {
                    background: $color_theme; } } } } }

.feature-box-three {
    &.color-four {

        .fea-icon {
            margin-bottom: 20px;
            i {
                font-size: 60px;
                color: $color-3; } }

        &:hover {
            h3 {
                color: $color-3;

                &:after {
                    background: $color-3; } } } } }

.feature-box-three {
    &.color-five {

        .fea-icon {
            margin-bottom: 20px;
            i {
                font-size: 60px;
                color: $color-4; } }

        &:hover {
            h3 {
                color: $color-4;

                &:after {
                    background: $color-4; } } } } }

.feature-box-three {
    &.color-six {

        .fea-icon {
            margin-bottom: 20px;
            i {
                font-size: 60px;
                color: $color-5; } }

        &:hover {
            h3 {
                color: $color-5;

                &:after {
                    background: $color-5; } } } } }

/* Icon Box four */
.feature-box-four, .feature-box-five {
    margin-bottom: 50px;
    .fea-icon {
        width: 70px;
        height: 70px;
        float: left;
        text-align: center;
        background: $color_theme;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;

        i {
            font-size: 35px;
            line-height: 70px;
            color: #fff; } }

    .contant {

        h3 {
            font-size: 22px;
            margin-bottom: 10px;
            color: #202020;
            transition: all 0.3s ease-in-out; } }

    &:hover {
        .fea-icon {
            box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba($color_theme, 0.6); }

        h3 {
            color: $color_theme; } } }

.feature-box-four {

    .fea-icon {
        float: left; }

    .contant {
        margin-left: 80px; } }

/* Icon Box Five */
.feature-box-five {
    .fea-icon {
        float: right; }

    .contant {
        margin-right: 80px;
        text-align: right; } }


/* Icon Box Six */
.feature-box-six {
    margin-bottom: 30px;
    .fea-icon {
        transition: all 0.3s ease-in-out;
        margin-bottom: 10px;

        i {
            font-size: 45px;
            color: $color_theme;
            transition: all 0.3s ease-in-out; } }

    .content {

        h3 {
            font-size: 22px;
            margin-bottom: 20px;
            padding-bottom: 15px;
            position: relative;

            &:after {
                position: absolute;
                content: '';
                height: 2px;
                width: 50px;
                background: $color_theme;
                left: 0;
                bottom: 0; } } } }

/* Icon Box Animation Seven  */
.feature-box-seven {
    padding: 30px;
    transition: all 0.35s linear;
    margin-bottom: 30px;
    position: relative;
    .animated-icon {
        position: relative;
        display: block;
        font-size: 3.71429em;
        line-height: 60px;
        color: #f42958;
        transition: all 0.3s ease;

        &[data-plugin-animated-icon] {
            // font-size: 1em
            // line-height: 0.5em !important

            svg, object {
                opacity: 0; } }

        svg {
            display: inline-block;
            width: 52px;
            line-height: 0; } }

    h3 {
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 20px;
        transition: all 0.3s ease-in-out;
        position: relative;
        padding-bottom: 15px;
        overflow: hidden;

        &:before, &:after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            transform-origin: left center;
            background: #fc4a1a;
            background: -webkit-linear-gradient(to right, #f7b733, #fc4a1a);
            background: linear-gradient(to right, #4772d9, #6d47d9);
            transition: transform 0.65s cubic-bezier(0.7, 0, 0.2, 1); }

        &:before {
            transform: scale3d(0.13, 1, 1); }

        &:after {
            transform: translate3d(-10px, 0, 0) scale3d(0, 1, 1); } }


    &:hover {
        box-shadow: 0 0 50px rgba(0,0,0,0.085);
        h3 {
            background: $color_gradian;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:before {
                transform-origin: left center;
                transform: translate3d(100%, 0, 0) scale3d(2, 1, 1); }

            &:after {
                transform-origin: left center;
                transition-delay: 0.1s;
                transform: translate3d(0, 0, 0) scale3d(1, 1, 1); } } } }

.appear-animation-visible svg, .appear-animation-visible object {
    opacity: 1 !important; }



.feature-wrap {
    margin-top: 100px; }

.feature-thumb {
    max-width: 400px;
    margin: 0 auto;
    padding: 0 30px;
    img {
        width: 100%;
        height: auto; } }

// .fea-icon[data-icon]:before
//     display: none

#feature-two {
    .section-title {
        margin-bottom: 0;
        h2 {
            font-size: 38px; } } }
/* Iconbox Gradient */
.feature-box-one, .feature-box-two, .feature-box-three, .feature-box-four, .feature-box-five, .feature-box-six {
    &.gredient {
        position: relative;
        transition: all 0.3s ease-in-out;


        .fea-icon {
            background: $color_gradian;
            color: #FFF;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; }
        h3 {
            position: relative;
            transition: all 0.3s ease-in-out; }

        &:hover {


            h3 {
                color: #4772d9;
                background: $color_gradian;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; } } } }

.feature-box-one {
    &.gredient {
        .fea-icon {
            background: $color_gradian;
            color: #FFF;
            -webkit-text-fill-color: #FFF; }

        &:before {
            content: "";
            position: absolute;
            display: block;
            background-color: #fff;
            top: 1px;
            right: 1px;
            bottom: 1px;
            left: 1px;
            border-radius: 5px;
            z-index: -1;
            transition: all 0.3s ease-in-out; }

        &:hover {
            background: $color_gradian;
            z-index: 1;
            border-color: #FFF; } } }

.feature-box-two {
    &.gredient {
        &:hover {
            background:  $color_gradian;

            .fea-icon {
                -webkit-text-fill-color: #FFF; }

            h3 {
                -webkit-text-fill-color: #FFF; } } } }

.feature-box-three, .feature-box-six {
    &.gredient {
        h3 {
            &:after {
                background: $color_gradian; } } } }

.feature-box-five, .feature-box-four {
    &.gredient {
        .fea-icon {
            background: $color_gradian;
            color: #FFF;
            -webkit-text-fill-color: #FFF; }

        &:hover {
            .fea-icon {
                box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(71,114,217, 0.6); } } } }

@media screen and (max-width: 768px) {
    .feature-box-one, .feature-box-two, .feature-box-three , .feature-box-four, .feature-box-five {
        margin: 0 auto 20px;
        max-width: 450px; } }

