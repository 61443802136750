#testimonial {
    padding: 100px 0;
    position: relative; }

#testimonial-two-colorfull {
    background: $color-5; }

.swiper-container {
    width: 90%;
    position: relative; }

.gp-testimonial {
    .gp-testmonial-content {
        padding: 35px 35px 30px 90px;
        background: #f2f5f6;
        position: relative;
        margin-bottom: 40px;

        p {
            font-size: 14px;
            margin-bottom: 0;
            line-height: 30px; }

        &:after {
            content: "";
            position: absolute;
            left: 22px;
            bottom: -20px;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid #f2f5f6;
            background: none; }

        .quote-icon {
            font-size: 90px;
            font-family: 'Catamaran', sans-serif;
            position: absolute;
            left: 38px;
            line-height: 1px;
            top: 83px;
            color: $color_theme; } }

    .gp-testimonial-author {
        position: relative;
        .testi-thumb {
            float: left;
            width: 90px;
            height: 90px;
            border-radius: 50%;

            img {
                width: 100%;
                height: auto;
                border-radius: 50%; } }

        .author-details {
            position: absolute;
            left: 110px;
            top: 50%;
            transform: translateY(-50%);

            h4 {
                margin: 0;
                font-size: 18px;
                color: #000;
                font-weight: 600; }

            span {
                font-size: 13px;
                font-style: italic;
                color: #444; } } } }

.gp-testimonial-wrapper {
    position: relative;

    .slider-control {
        position: absolute;
        width: 100%;
        top: 50%;

        .testi-prev, .testi-next {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background: #f2f5f6;
            text-align: center;
            position: absolute;
            transform: translateY(-100%);
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            i {
                font-size: 30px;
                line-height: 48px; }

            &:hover {
                background: $color_theme;

                i {
                    color: #FFF; } } }

        .testi-prev {
            left: 0;
            margin-right: 3px; }

        .testi-next {
            right: 0;

            i {
                margin-left: 3px; } } } }

.swiper-pagination {
    margin-top: 25px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    .swiper-pagination-bullet {
        height: 14px;
        width: 14px;
        margin-right: 3px;

        &.swiper-pagination-bullet-active {
            background: $color_theme; } }

    &.gradient {
        .swiper-pagination-bullet {
            &.swiper-pagination-bullet-active {
                background: $color_gradian; } } }

    &.colorfull {
        .swiper-pagination-bullet {
            &.swiper-pagination-bullet-active {
                background: $color-3; } } } }

.section-dark {
    .gp-testimonial {
        .gp-testimonial-author {
            .author-details {
                .testi-author-name {
                    color: #FFF; }

                span {
                    color: #b5b5b5; } } } } }

/* Testimonial Two */

.gp-testimonial-wrapper-two {
    .swiper-container {
        width: 100%; } }

#testimonial-two {
    background: #f8f8f8;
    padding: 100px 0; }

.gp-testimonial-two {
    background: #FFF;
    padding: 50px;
    border-radius: 10px;

    .gp-testmonial-content {
        margin-bottom: 30px;

        p {
            font-size: 15px;
            line-height: 26px; } }

    .gp-testimonial-author {
        position: relative;

        .testi-thumb {
            float: left;
            width: 80px;
            height: 80px;
            border-radius: 50%;

            img {
                width: 100%;
                height: auto;
                border-radius: 50%; } }

        .author-details {
            position: absolute;
            left: 100px;
            top: 50%;
            transform: translateY(-50%);

            h4 {
                margin: 0;
                font-size: 18px;
                color: #000;
                font-weight: 600; }

            span {
                font-size: 13px;
                font-style: italic;
                color: #444; }

            &.color-one {
                h4 {
                    color: $color-1; } }

            &.color-two {
                h4 {
                    color: $color-2; } }

            &.color-three {
                h4 {
                    color: $color-3; } }

            &.color-four {
                h4 {
                    color: $color-4; } } } } }

@media screen and (max-width: 500px) {

    .gp-testimonial-wrapper {
        .slider-control {
            display: none; } }

    .gp-testimonial {
        .gp-testmonial-content {
            padding: 25px 12px 30px 50px;

            .quote-icon {
                left: 12px;
                top: 75px; } } } }
