#call-to-action, #call-to-action-two {
    background: $color_theme; }

#call-to-action-two-colorfull, #call-to-action-colorfull {
    background: $color-3; }


#call-to-action-two-gredient, #call-to-action-gredient {
    background: $color_gradian; }

.cta-text {
    width: 75%;
    float: left;

    h3 {
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
        color: #FFF; }

    p {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
        color: #FFF; } }

#call-to-action-two, #call-to-action-two-colorfull, #call-to-action-two-gredient, #call-to-action-gredient {
    p {
        max-width: 650px;
        margin: 0 auto;
        font-size: 16px;
        margin-bottom: 20px; } }

.cta-btncontainer, .cta-btncontainer-two {
    width: 25%;
    float: left;

    .gp-btn {
        border-radius: 30px;
        padding: 15px 40px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        margin: 15px auto 0;
        text-align: center;
        margin-right: 18px;
        text-transform: uppercase;
        font-weight: 500;

        &.btn-dark {
            &:hover {
                background: #fff;
                color: #202020; } } } }

.cta-text-two {
    text-align: center;
    margin-bottom: 20px;

    h3 {
        font-size: 34px;
        color: #FFF;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 25px; }

    p {
        color: #FFF;
        font-size: 20px;
        margin-bottom: 0; } }

.cta-btncontainer-two {
    text-align: center;
    width: 100%;
    float: none; }

@media screen and (max-width: 768px) {

    .cta-text {
        h3 {
            font-size: 24px; }

        p {
            font-size: 14px; } }

    .cta-text-two {
        h3 {
            font-size: 28px;
            margin-bottom: 15px; } }

    .cta-btncontainer .gp-btn, .cta-btncontainer-two .gp-btn {
        padding: 12px 22px;
        margin-right: 0; } }

@media screen and (max-width: 500px) {
    .cta-text-two {
        h3 {
            font-size: 24px;
            margin-bottom: 10px; }

        p {
            font-size: 13px !important; } }

    .cta-text {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;

        h3 {
            font-size: 20px; } }

    .cta-btncontainer, .cta-btncontainer-two {
        width: 100%;
        text-align: center; } }


