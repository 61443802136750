#contact, #contact-gredient, #contact-colorfull, #contact-form-three {
    background: #f8f8f8; }

.contact-title {
    text-align: center;
    margin-bottom: 50px;

    h2 {
        color: #000; } }

.contact-info {
    text-align: center;
    background: #FFF;
    padding: 20px 40px;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.01); }


.con-details {
    border-bottom: 1px solid #eaeaea;
    margin: 0;
    padding: 10px 0;

    .con-icon {
        height: 60px;
        width: 60px;
        text-align: center;
        margin: 0 auto;
        background: lighten($color_theme, 10%);
        border-radius: 50%;
        margin-bottom: 10px;

        i {
            line-height: 60px;
            font-size: 30px;
            color: #FFF; }
        &.color-one {
            background: $color-1; }

        &.color-two {
            background: $color-2; }

        &.color-three {
            background: $color-3; } }

    p {
        color: #444; }

    &:last-child {
        border-bottom: none; } }


.contact-form-area {
    padding: 0; }


#gp-contact-form, #gp-contact-form-two, #gp-contact-form-three, #gp-contact-form-four {
    .form-group {
        margin-bottom: 20px; }

    .form-control {
        height: 55px;
        border: none;
        background: #FFF;
        box-shadow: 0 10px 20px rgba(0,0,0,0.03);
        border-radius: 5px;
        font-size: 16px;
        border-radius: 30px;
        padding: 10px 25px; }

    textarea {
        &.form-control {
            height: 200px;
            padding: 20px;
            margin-bottom: 30px; } }

    .gp-btn {
        display: block;
        max-width: 300px;
        width: 100%;
        border-radius: 30px;
        border: none;
        background: #333;
        color: #FFF;
        transition: all 0.3s ease-in-out;
        outline: none;
        margin: 0 auto;

        &:hover {
            background: $color_theme; }

        &.btn-border-one {
            background: transparent;
            color: $color-1;
            border: 2px solid $color-1;

            &:hover {
                background: $color-1;
                color: #FFF;
                border-color: color-1; } } } }

#gp-contact-form .gp-btn.gredient {
    background: linear-gradient(180deg, #4772d9, #6d47d9);
    color: #FFF; }


.get-in-touch {
    padding: 30px;

    h4 {
        font-size: 16px;
        line-height: 24px;
        color: #444;
        font-weight: 500px;
        margin-bottom: 20px; } }


#contact-two {
    padding-bottom: 0;
    background: #f8f8f8; }

.gp-contact-form-two {
    margin: 0 auto 150px;
    max-width: 800px; }

#map {
    position: relative; }

.contact-details-two {
    position: absolute;
    top: 0;
    max-width: 800px;
    width: 100%;
    z-index: 300;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    border-radius: 15px;
    box-shadow: 10px 10px 20px rgba(0,0,0, 0.1);
    padding: 40px 0;

    .contact-info-two {
        text-align: center;
        display: inline-block;
        width: 33.33%;
        float: left;
        border-right: 1px solid #f5f5f5;

        &:last-child {
            border-right: none; }

        i {
            font-size: 40px;
            display: block;
            color: $color_theme;
            margin-bottom: 15px; }

        p {
            font-size: 15px;
            margin-bottom: 0;
            line-height: 24px; } } }


.gmap3-area {
    height: 500px; }


.con-details {
    &.gredient {
       .con-icon {
            background: $color_gradian; } } }

