/*!
	Theme Name: Avatar
	Theme URI: http://www.gpthemes.co/products/discovery/
	Author: Golden Plus Theme
	Author URI: http://www.gpthemes.co/
	Description: One Page Multipurpose Business HTML Template
	Version: 1.0.0
	License: GNU General Public License v2 or later
	License URI: http://www.gnu.org/licenses/gpl-2.0.html
	Text Domain: avatar
	Tags: agency,business,coporate,startup
	*/
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Header
    ## Banner
    ## About
    ## Feature
    ## Portfolio
    ## Work Prosses
    ## Service
    ## Pricing
    ## Client
    ## Blog
    ## Testimonial
    ## Skills
    ## Team
    ## Call To Action
    ## Countup
    ## Newsletter
    ## Contact
    ## Product
    ## Footer

  --------------------------------------------------------------*/
body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #707070;
  background-color: #FFF;
  overflow-x: hidden;
  transition: opacity 0.24s ease-in-out;
  opacity: 1;
  visibility: visible;
  letter-spacing: 0.5px;
}

#main_content {
  overflow: hidden;
  z-index: 2;
  background: #fff;
}

.footer-fixed {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: -1;
  visibility: visible;
  opacity: 1;
}

@media (min-width: 1200px) {
  .container {
    width: 1220px;
  }
}

svg {
  display: block;
}

a {
  transition: all 0.15s ease-in-out;
}

a,
a:hover, a:focus {
  text-decoration: none;
}

h1, h2, h3, h4, h5 {
  font-family: "Poppins", sans-serif;
  color: #202020;
  font-weight: 600;
  margin: 0 0 5px;
}

.section-title {
  margin-bottom: 50px;
}

.section-title h4 {
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  color: #03a9f4;
  position: relative;
  display: inline-block;
  font-family: "Playfair Display", serif;
}

.section-title h4:before, .section-title h4:after {
  position: absolute;
  height: 1px;
  width: 20px;
  background: #03a9f4;
  content: '';
  top: 50%;
}

.section-title h4:before {
  left: -30px;
}

.section-title h4:after {
  right: -30px;
}

.section-title h2 {
  font-size: 40px;
  font-weight: 800;
  color: #333;
  margin-bottom: 20px;
  position: relative;
  font-family: "Catamaran", sans-serif;
  overflow: hidden;
  padding-bottom: 20px;
}

.section-title h2 span {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: 700;
}

.section-title h2:after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: 0;
  width: 50px;
  height: 2px;
  background: #03a9f4;
  transform: translateX(-50%);
}

.section-title p {
  font-size: 15px;
  color: inherit;
  font-weight: inherit;
  position: relative;
}

.section-title a {
  margin-top: 30px;
}

.section-title.text-left h4:before {
  display: none;
}

.section-title.text-left h2:after {
  left: 0;
  transform: translateX(0);
}

.section-title-two {
  position: relative;
  margin: 40px auto 20px;
}

.section-title-two .title_shadow_text {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  opacity: 0.2;
  color: #fff;
  top: -14px;
  line-height: 1;
  letter-spacing: 1px;
  z-index: 1;
  text-shadow: 2px 5px 2px rgba(0, 0, 0, 0.2);
}

.section-title-small h2 {
  font-size: 28px;
  font-weight: 700;
}

.section-title-small p {
  margin-bottom: 30px;
}

.section-dark .section-title h2 {
  color: #FFF;
}

.section-dark .section-title h4 {
  color: #f5f5f5;
}

.section-dark .section-title p {
  color: #f9f9f9;
}

.section-title.colorfull h4 {
  color: #D81B60;
}

.section-title.colorfull h4:after, .section-title.colorfull h4:before {
  background: #00C851;
}

.section-title.colorfull h2:after {
  background: #9c27b0;
}

.gp-btn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 12px 35px;
  color: #333;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.gp-btn:hover, .gp-btn:focus {
  background: #03a9f4;
  color: #FFF;
  border-color: #03a9f4;
  outline: none;
  text-decoration: none;
}

.gp-btn.small {
  padding: 10px 25px;
}

.gp-btn.larg {
  padding: 14px 40px;
}

.gp-btn.btn-bradius {
  border-radius: 30px;
}

.gp-btn.btn-grey {
  background: #f7f7f7;
  box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18);
  color: #202020;
}

.gp-btn.btn-grey:hover {
  box-shadow: none;
}

.gp-btn.btn-dark {
  background: #121212;
  color: #fff;
}

.gp-btn.btn-dark:hover {
  background: #03a9f4;
  color: #FFF;
}

.gp-btn.btn-light {
  background: #FFF;
  color: #121212;
  box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18);
}

.gp-btn.btn-light:hover {
  background: #121212;
  color: #FFF;
}

.gp-btn.btn-primary {
  background: #03a9f4;
  color: #FFF;
}

.gp-btn.btn-primary:hover {
  background: #121212;
  color: #FFF;
}

.gp-btn.btn-border {
  border: 2px solid #03a9f4;
  color: #03a9f4;
}

.gp-btn.btn-border:hover {
  background: #03a9f4;
  color: #FFF;
  border-color: #03a9f4;
}

.gp-btn.color-one {
  background: #D81B60;
  color: #FFF;
}

.gp-btn.color-one:hover {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(216, 27, 96, 0.46);
  transform: translateY(-1px);
}

.gp-btn.color-two {
  background: #9c27b0;
  color: #FFF;
}

.gp-btn.color-two:hover {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46);
  transform: translateY(-1px);
}

.gp-btn.color-three {
  background: #00C851;
  color: #FFF;
}

.gp-btn.color-three:hover {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 200, 81, 0.46);
  transform: translateY(-1px);
}

.gp-btn.color-four {
  background: #f96332;
  color: #FFF;
}

.gp-btn.color-four:hover {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(249, 99, 50, 0.46);
  transform: translateY(-1px);
}

.button {
  font-size: 14px;
  border-radius: 30px;
  padding: 8px 20px;
  background: #e9e9e9;
  color: #333;
  border: none;
  transition: all 0.3s ease-in-out;
  margin: 0;
}

.button:hover {
  background: #333;
  color: #FFF;
}

.gp-btn.gredient {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
  color: #FFF;
}

.gp-btn.gredient:hover {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(71, 114, 217, 0.6);
}

.gp-btn.btn-border-one {
  border: 2px solid #D81B60;
  color: #D81B60;
}

.gp-btn.btn-border-one:hover {
  background: #D81B60;
  color: #FFF;
  border-color: #D81B60;
}

.gp-btn.btn-border-two {
  border: 2px solid #9c27b0;
  color: #9c27b0;
}

.gp-btn.btn-border-two:hover {
  background: #9c27b0;
  color: #FFF;
  border-color: #9c27b0;
}

.gp-btn.btn-border-three {
  border: 2px solid #00C851;
  color: #00C851;
}

.gp-btn.btn-border-three:hover {
  background: #00C851;
  color: #FFF;
  border-color: #00C851;
}

.no-border-right {
  border-right: none  !important;
}

.no-border-bottom {
  border-bottom: none !important;
}

.overlay, .overlay-colorfull, .overlay-dark {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.overlay {
  background: rgba(3, 169, 244, 0.8);
}

.overlay-dark {
  background: rgba(0, 0, 0, 0.8);
}

.overlay-colorfull {
  background: rgba(216, 27, 96, 0.8);
}

.parallax-bg {
  background-size: cover;
}

input, textarea, select {
  border: 1px solid #dfdfdf;
  font-size: 16px;
  padding: 8px 15px;
  resize: none;
}

input:hover, input:focus, textarea:hover, textarea:focus, select:hover, select:focus {
  outline: none;
}

section {
  padding: 100px 0;
}

@media (min-width: 1200px) {
  width: 1200px;
}

@media (max-width: 768px) {
  section {
    padding: 70px 0;
  }
  .section-title h2 {
    font-size: 28px;
  }
  .section-title p {
    font-size: 14px;
  }
  .section-title p br {
    display: none;
  }
}

@media (max-width: 500px) {
  section {
    padding: 50px 0;
  }
  .section-title h2 {
    font-size: 26px;
  }
  .section-title p {
    font-size: 14px;
  }
  .section-title p br {
    display: none;
  }
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
#header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 200;
}

#header.header-one {
  border-bottom: 2px solid #1f6b57;
}

#header.header-one .navbar-brand {
  border-right: 2px solid #3c9181;
}

#header.header-two #discovery-main-menu {
  background: rgba(0, 0, 0, 0.8);
}

#header.header-three {
  background: #1b1b1a;
  height: 100px;
}

#header.header-three #logo {
  border-right: none;
  padding: 18px 0;
}

#header.header-three #discovery-main-menu {
  position: absolute;
  bottom: -30px;
  width: 100%;
  background: #FFF;
  max-width: 1200px;
  border-radius: 30px;
}

#header.header-three #discovery-main-menu > ul {
  margin-left: 50px;
}

#header.header-three #discovery-main-menu > ul > li > a {
  padding: 18px 10px;
}

#header.header-three #discovery-main-menu > ul > li ul {
  margin: 0;
  border-top: 1px solid #03a9f4;
}

#header.header-three #discovery-main-menu > ul > li ul > li > ul {
  left: 105%;
}

#discohead {
  position: relative;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.07);
  backface-visibility: hidden;
  z-index: 1000;
}

#discohead.fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  animation: headerFixedTop .35s;
}

#discohead.fixed #logo {
  border-right: none;
}

#discohead.fixed .discovery-main-menu > ul > li .sub-menu {
  margin-top: 0;
  border-radius: 5px;
}

.transparent-header #discohead:not(.fixed) {
  box-shadow: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.admin-bar.transparent-header #discohead:not(.fixed) {
  top: 57px;
}

@media screen and (max-width: 782px) {
  .admin-bar.transparent-header #discohead:not(.fixed) {
    top: 71px;
  }
  .admin-bar #discohead.fixed {
    top: 32px;
  }
}

@media screen and (max-width: 782px) {
  .admin-bar #discohead.fixed {
    top: 46px;
  }
}

@media screen and (max-width: 600px) {
  .admin-bar #discohead.fixed {
    top: 0;
  }
}

@media (min-width: 1024px) {
  #discohead > .container-fluid {
    padding-left: 40px;
    padding-right: 40px;
  }
  .transparent-header #discohead-faker {
    display: none;
  }
  @-webkit-keyframes headerFixedTop {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes headerFixedTop {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  #logo {
    display: block;
    padding: 23px 0;
    float: left;
  }
  #logo .logo-contrast {
    display: none;
  }
  .transparent-header #discohead:not(.fixed) #logo .logo-normal {
    display: none;
  }
  .transparent-header #discohead:not(.fixed) #logo .logo-contrast {
    display: block;
  }
  .fixed #logo {
    padding: 20px 0;
  }
  #nav-toggle {
    display: none;
    width: 50px;
    height: 42px;
    float: right;
    background-color: transparent;
    padding: 10px;
    cursor: pointer;
    margin-left: 15px;
    margin-top: 16px;
    margin-right: -10px;
  }
}

@media (max-width: 768px) {
  #nav-toggle {
    display: block;
  }
  .closed-header #nav-toggle {
    display: block;
  }
  #nav-toggle .toggle-inner {
    position: relative;
  }
  #nav-toggle .toggle-inner > span {
    display: block;
    position: relative;
    margin-bottom: 5px;
    width: 100%;
    height: 2px;
    background-color: #000;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  #nav-toggle .toggle-inner > span:nth-child(2) {
    width: 24px;
    margin-left: 6px;
    background-color: #03a9f4 !important;
  }
  #nav-toggle .toggle-inner > span:nth-child(3) {
    width: 24px;
    margin-left: 6px;
    background-color: #03a9f4 !important;
  }
  #nav-toggle .toggle-inner > span:nth-child(4) {
    margin-bottom: 0;
  }
  #nav-toggle.active .toggle-inner > span:nth-child(1) {
    transform: rotate(45deg) translate(7px, 7px);
  }
  #nav-toggle.active .toggle-inner > span:nth-child(4) {
    transform: rotate(-45deg) translate(8px, -8px);
  }
  #nav-toggle.active .toggle-inner > span:nth-child(2), #nav-toggle.active .toggle-inner > span:nth-child(3) {
    opacity: 0;
  }
  .topbar-wrapper {
    display: none;
  }
  .transparent-header #discohead:not(.fixed) #nav-toggle {
    margin-right: 0;
    float: right;
    width: 30px;
    padding: 5px 0;
  }
  .transparent-header #discohead:not(.fixed) #nav-toggle .toggle-inner > span {
    background-color: #fff;
  }
}

@media (min-width: 769px) {
  #discovery-main-menu {
    display: block;
    text-align: center;
  }
  .closed-header #discovery-main-menu {
    transition: all 0.25s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }
  .transparent-header #discohead:not(.fixed) #discovery-main-menu > ul {
    padding-left: 25px;
    padding-right: 25px;
  }
  .transparent-header #discohead:not(.fixed) #discovery-main-menu > ul {
    display: inline-block;
  }
  .transparent-header #discohead:not(.fixed) #discovery-main-menu > ul > li > a {
    padding: 28px 7px;
  }
  #discovery-main-menu.visible {
    visibility: visible;
    opacity: 1;
  }
  #discovery-main-menu > ul {
    padding: 0;
    margin: 0;
    display: inline-block;
  }
  #discovery-main-menu > ul > li {
    text-align: left;
    display: inline-block;
    position: relative;
  }
  #discovery-main-menu > ul > li > a {
    display: block;
    font-size: 14px;
    padding: 26px 10px;
    color: #000;
    font-weight: 500;
  }
  #discovery-main-menu > ul > li > a:hover {
    color: #03a9f4;
  }
  #discovery-main-menu .secondary-navigation {
    display: inline-block;
    padding: 27px 0;
  }
  #discovery-main-menu .secondary-navigation > ul {
    margin: 0;
    padding: 0;
  }
  #discovery-main-menu .secondary-navigation > ul > li {
    display: inline-block;
    padding: 15px 0;
  }
  #discovery-main-menu .secondary-navigation > ul > li .cart-indication {
    position: relative;
    text-decoration: none;
    margin-right: 20px;
  }
  #discovery-main-menu .secondary-navigation > ul > li .cart-indication span {
    color: #202020;
  }
  #discovery-main-menu .secondary-navigation > ul > li .cart-indication .badge {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 17px;
    text-align: center;
    background: #03a9f4;
    color: #fff;
    position: absolute;
    top: -4px;
    left: 8px;
    padding: 0;
  }
  #discovery-main-menu .secondary-navigation > ul > li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
  }
  #discovery-main-menu .secondary-navigation > ul > li .search-btn {
    text-decoration: none;
  }
  #discovery-main-menu .secondary-navigation > ul > li .search-btn span {
    font-size: 14px;
    color: #202020;
  }
  .fixed #discovery-main-menu > ul > li > a {
    padding: 25px 7px;
  }
  .fixed #discovery-main-menu .secondary-navigation {
    padding: 10px 0;
  }
  .fixed #discovery-main-menu .secondary-navigation > ul > li {
    padding: 14px 0;
  }
  .fixed #discovery-main-menu .secondary-navigation > ul > li .search-btn span {
    color: #202020;
  }
  .transparent-header #discohead:not(.fixed) #discovery-main-menu > ul > li > a {
    color: #FFF;
  }
  .transparent-header #discohead:not(.fixed) #discovery-main-menu > ul > li > a:hover {
    color: #03a9f4;
  }
  .transparent-header #discohead:not(.fixed) #discovery-main-menu .secondary-navigation > ul > li {
    padding: 0;
  }
  .transparent-header #discohead:not(.fixed) #discovery-main-menu .secondary-navigation > ul > li .cart-indication span, .transparent-header #discohead:not(.fixed) #discovery-main-menu .secondary-navigation > ul > li .search-btn span {
    color: #FFF;
  }
  #discovery-main-menu > ul > li ul {
    position: absolute;
    margin: 0;
    background-color: white;
    padding: 10px;
    min-width: 240px;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all 0.25s ease-in-out;
  }
  #discovery-main-menu > ul > li ul {
    box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.2);
  }
  #discovery-main-menu > ul > li ul > li {
    list-style: none;
    position: relative;
  }
  #discovery-main-menu > ul > li ul > li > a {
    display: block;
    color: #000;
    padding: 5px 10px;
    font-size: 13px;
  }
  #discovery-main-menu > ul > li ul > li > a:hover {
    color: #03a9f4;
    background: #f3f3f3;
  }
  #discovery-main-menu > ul > li ul > li > ul {
    top: -10px;
    left: 98%;
    margin: 0 0 0 12px;
    border-left: 1px solid #03a9f4;
  }
  #discovery-main-menu > ul > li ul > li > ul:after, #discovery-main-menu > ul > li ul > li > ul:before {
    display: none;
  }
  #discovery-main-menu > ul > li ul > li.menu-item-has-children > a:after {
    content: '\f105';
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #discovery-main-menu > ul > li ul > li.menu-item-has-children:hover > ul {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
  #discovery-main-menu > ul > li.menu-item-has-children > a:after {
    content: '\f107';
    font: normal normal normal 14px/1 FontAwesome;
    padding-left: 10px;
  }
  #discovery-main-menu > ul > li.menu-item-has-children:hover > ul {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  #discovery-main-menu > ul > li:nth-last-child(-n+2) ul {
    left: auto;
    right: 0;
  }
  #discovery-main-menu > ul > li:nth-last-child(-n+2) ul:before {
    left: auto;
    right: 10px;
  }
  #discovery-main-menu > ul > li:nth-last-child(-n+2) ul li.menu-item-has-children > a:after {
    content: '\f104';
  }
  #discovery-main-menu > ul > li:nth-last-child(-n+2) ul li > ul {
    right: 100%;
    margin-right: 12px;
    border-left: none;
    border-right: 1px solid #03a9f4;
  }
  .menu-two .dt-header #discovery-main-menu {
    text-align: right;
  }
}

.secondary-navigation {
  position: relative;
}

.secondary-navigation .sub-menu {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);
  opacity: 0;
  visibility: hidden;
  z-index: 101;
  line-height: 1.8;
  position: absolute;
  background: #FFF;
  text-align: left;
  width: 350px;
  padding: 20px;
  margin-top: 0;
  right: 0;
  border-radius: 5px;
  top: 140%;
  transition: all 0.3s ease-in-out;
}

.secondary-navigation .sub-menu .cart-item {
  margin-bottom: 10px;
  display: block;
  position: relative;
}

.secondary-navigation .sub-menu .cart-item .product-thumbnail {
  width: 60px;
  margin-right: 1.3em;
  float: left;
}

.secondary-navigation .sub-menu .cart-item .product-thumbnail img {
  width: 100%;
}

.secondary-navigation .sub-menu .cart-item .product-details {
  position: relative;
}

.secondary-navigation .sub-menu .cart-item .product-details .product-title {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  display: block;
  text-decoration: none;
}

.secondary-navigation .sub-menu .cart-item .product-details .product-title:hover {
  color: #03a9f4;
}

.secondary-navigation .sub-menu .cart-item .product-details .product-remove {
  position: absolute;
  right: 0;
  top: 42%;
  height: 18px;
  width: 18px;
  font-size: 9px;
  background: #808080;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  padding: 4px 6px;
  color: #f3f3f3;
  transition: all 0.3s ease-in-out;
}

.secondary-navigation .sub-menu .cart-item .product-details .product-remove:hover {
  background: #444;
  color: #FFF;
}

.secondary-navigation .sub-menu .cart-item .product-details .product-quantity {
  display: inline-block;
}

.secondary-navigation .sub-menu .cart-subtotal {
  display: block;
  padding: 15px 0;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
}

.secondary-navigation .sub-menu .cart-subtotal .amount {
  float: right;
}

.secondary-navigation .sub-menu .cart-actions {
  display: block;
  padding-top: 20px;
}

.secondary-navigation .sub-menu .cart-actions .view-cart, .secondary-navigation .sub-menu .cart-actions .checkout {
  padding: 8px 25px;
  text-decoration: none;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  border: 2px solid transparent;
  text-transform: uppercase;
  font-size: 12px;
  display: inline-block;
  letter-spacing: 1px;
  font-weight: 600;
}

.secondary-navigation .sub-menu .cart-actions .view-cart {
  border-color: #dcdcdc;
  color: #333;
}

.secondary-navigation .sub-menu .cart-actions .view-cart:hover {
  background: #dcdcdc;
  color: #333;
}

.secondary-navigation .sub-menu .cart-actions .checkout {
  background: #03a9f4;
  color: #FFF;
  float: right;
}

.secondary-navigation .sub-menu .cart-actions .checkout:hover {
  background: #0398db;
}

body.search-open {
  overflow: hidden;
}

button.close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  background: none;
  border: 0;
  font-size: 20px;
  cursor: pointer;
  color: #FFF;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  outline: none;
  opacity: 1;
  height: 40px;
  width: 40px;
}

button.close:hover {
  background: #03a9f4;
  color: #FFF;
  opacity: 1;
}

.search-box.search-elem {
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.95);
  transition: -webkit-transform .8s ease, -moz-transform .8s ease, -o-transform .8s ease, transform .8s ease, opacity .5s ease, visibility .5s ease;
  transform: scale(0.7);
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
}

.search-box.search-elem .inner {
  width: 50%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.search-box.search-elem label {
  color: white;
  font-weight: 300;
}

.search-box.search-elem button.submit {
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 70px;
  padding: 10px 2rem;
  background: transparent;
  font-size: 20px;
  color: #FFF;
  outline: none;
  border: none;
}

.search-box.search-elem button.submit[disabled] {
  background: transparent;
  border: none;
  color: #FFF;
  outline: none;
}

.search-box.search-elem input[type="text"] {
  padding: 0;
  height: 72px;
  font-size: 36px;
  font-weight: 300;
  border: none;
  border-bottom: solid 2px #999;
  transition: border 0.3s;
  border-radius: 0;
  background: transparent;
  font-weight: 700;
  color: #FFF;
  width: 100%;
}

.search-box.search-elem input[type="text"]:focus {
  border-bottom: solid 2px #03a9f4;
  box-shadow: none;
  outline: none;
}

.search-box.search-elem label.placeholder {
  position: absolute;
  top: 10px;
  left: 2rem;
  font-size: 32px;
  font-weight: 300;
  transition: all .3s;
  color: #f5f5f5;
}

.search-box.search-elem label.placeholder.move-up {
  top: -25px;
  color: #f8f8f8;
  font-size: 16px;
  left: 0;
}

.search-box.search-elem.search-open {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.search-btn {
  text-transform: uppercase;
  font-size: 0.875rem;
  padding: 0 12px;
  display: inline-block;
}

.search-btn:hover {
  color: #00A896;
}

.menu-two .dt-header {
  background: rgba(0, 0, 0, 0.3);
}

.menu-two .dt-header #discovery-main-menu #menu-home {
  border-right: 1px solid #5f5f5f;
}

.menu-two #discohead.fixed {
  top: -35px;
}

.menu-two #discohead.fixed #discovery-main-menu #menu-home {
  border-color: #d2d2d2;
}

.topbar-wrapper {
  padding: 5px;
  border-bottom: 1px solid #5f5f5f;
}

.topbar-wrapper .topbar-left {
  text-align: left;
  width: 50%;
  float: left;
}

.topbar-wrapper .topbar-left ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.topbar-wrapper .topbar-left ul li {
  display: inline-block;
  margin-right: 20px;
  color: #FFF;
  position: relative;
}

.topbar-wrapper .topbar-left ul li span {
  font-weight: 600;
}

.topbar-wrapper .topbar-left ul li:first-child:after {
  content: '';
  position: absolute;
  right: -12px;
  top: 6px;
  height: 12px;
  width: 1px;
  background: #f5f5f5;
}

.topbar-wrapper .topbar-right {
  text-align: right;
  width: 50%;
  float: right;
}

.topbar-wrapper .topbar-right .top-soc-link {
  margin: 0;
  padding: 0;
  list-style: none;
}

.topbar-wrapper .topbar-right .top-soc-link li {
  display: inline-block;
  margin-right: 10px;
}

.topbar-wrapper .topbar-right .top-soc-link li a {
  color: #FFF;
  text-decoration: none;
}

.topbar-wrapper .topbar-right .top-soc-link li a:hover {
  color: #03a9f4;
}

.menu-wrap {
  width: 95%;
  margin: 0 auto;
}

/* Loader */
.loader-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFF;
  z-index: 999999;
}

.cssload-square {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 12px;
  width: 12px;
  background-color: #03a8f4;
  border-radius: 50%;
  animation: cssload-s1 2.3s infinite;
  animation--o-animation: cssload-s1 2.3s infinite;
  animation--ms-animation: cssload-s1 2.3s infinite;
  animation--webkit-animation: cssload-s1 2.3s infinite;
  animation--moz-animation: cssload-s1 2.3s infinite;
}

.cssload-square div {
  position: absolute;
  background-color: #d81b60;
  box-shadow: 0 0 10px #f96432;
  box-shadow--o-box-shadow: 0 0 10px #f96432;
  box-shadow--ms-box-shadow: 0 0 10px #f96432;
  box-shadow--webkit-box-shadow: 0 0 10px #f96432;
  box-shadow--moz-box-shadow: 0 0 10px #f96432;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 6px;
  right: 6px;
  animation: cssload-s1 2.3s infinite;
  animation--o-animation: cssload-s1 2.3s infinite;
  animation--ms-animation: cssload-s1 2.3s infinite;
  animation--webkit-animation: cssload-s1 2.3s infinite;
  animation--moz-animation: cssload-s1 2.3s infinite;
  animation-timing-function: cubic-bezier(1, 0.45, 0.45, 1);
  animation-timing-function--o-animation-timing-function: cubic-bezier(1, 0.45, 0.45, 1);
  animation-timing-function--ms-animation-timing-function: cubic-bezier(1, 0.45, 0.45, 1);
  animation-timing-function--webkit-animation-timing-function: cubic-bezier(1, 0.45, 0.45, 1);
  animation-timing-function--moz-animation-timing-function: cubic-bezier(1, 0.45, 0.45, 1);
  transform-origin: 50%;
  transform-origin--o-transform-origin: 50%;
  transform-origin--ms-transform-origin: 50%;
  transform-origin--webkit-transform-origin: 50%;
  transform-origin--moz-transform-origin: 50%;
}

.cssload-square div + div, .cssload-square div + div div {
  position: absolute;
  background-color: #00c850;
  height: 100%;
  width: 100%;
  top: -6px;
  right: -6px;
}

.cssload-two {
  transform: rotate(90deg);
  transform--o-transform: rotate(90deg);
  transform--ms-transform: rotate(90deg);
  transform--webkit-transform: rotate(90deg);
  transform--moz-transform: rotate(90deg);
  animation: cssload-s1 1.15s infinite;
  animation--o-animation: cssload-s1 1.15s infinite;
  animation--ms-animation: cssload-s1 1.15s infinite;
  animation--webkit-animation: cssload-s1 1.15s infinite;
  animation--moz-animation: cssload-s1 1.15s infinite;
}

/*Back To Top */
.return-to-top {
  position: fixed;
  bottom: -42px;
  right: 42px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
  z-index: 998;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: bottom .5s ease, opacity .5s ease;
  -moz-transition: bottom .5s ease, opacity .5s ease;
  transition: bottom .5s ease, opacity .5s ease;
}

.return-to-top:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  opacity: 1;
  display: block;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  border-radius: inherit;
  -webkit-transition: transform .5s ease, opacity .6s ease;
  -moz-transition: transform .5s ease, opacity .6s ease;
  transition: transform .5s ease, opacity .6s ease;
}

.return-to-top:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 0px 0px transparent;
  box-shadow: 0px 0px 0px 0px transparent;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  border-radius: inherit;
}

.return-to-top > i {
  position: relative;
  overflow: hidden;
  font-size: 12px;
  width: inherit;
  height: inherit;
  line-height: inherit;
  display: block;
  color: transparent;
  text-shadow: 0px 0px #fff, 0px 50px #fff;
  -webkit-transition: text-shadow .2s ease;
  -moz-transition: text-shadow .2s ease;
  transition: text-shadow .2s ease;
  z-index: 1;
}

.return-to-top:hover:after {
  transform: scale(1.07);
  background: #03a9f4;
  -webkit-box-shadow: 0px 10px 20px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 10px 20px 8px rgba(0, 0, 0, 0.15);
}

.return-to-top:hover > i {
  text-shadow: 0px -50px #fff, 0px 0px #fff;
}

.return-to-top.back-top {
  bottom: 42px;
  opacity: 1;
}

@keyframes cssload-s1 {
  50% {
    transform: rotate(360deg);
    opacity: .7;
  }
}

@-o-keyframes cssload-s1 {
  50% {
    -o-transform: rotate(360deg);
    opacity: .7;
  }
}

@-ms-keyframes cssload-s1 {
  50% {
    -ms-transform: rotate(360deg);
    opacity: .7;
  }
}

@-webkit-keyframes cssload-s1 {
  50% {
    -webkit-transform: rotate(360deg);
    opacity: .7;
  }
}

@-moz-keyframes cssload-s1 {
  50% {
    -moz-transform: rotate(360deg);
    opacity: .7;
  }
}

@media (max-width: 768px) {
  .transparent-header #discohead {
    background: rgba(0, 0, 0, 0.3);
    padding: 10px 20px;
    position: relative;
  }
  .transparent-header #discohead .logo-normal {
    display: none;
  }
  .transparent-header #discohead.fixed #nav-toggle {
    float: right;
  }
  #discovery-main-menu {
    position: absolute;
    background-color: #fff;
    top: 100%;
    right: 0;
    left: 0;
    padding: 15px 15px;
    border-bottom: 1px solid #eee;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  #discovery-main-menu.visible {
    visibility: visible;
    opacity: 1;
  }
  #discovery-main-menu > ul > li > a {
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
  }
  #discovery-main-menu > ul > li > a:hover {
    color: #03a9f4;
  }
  #discovery-main-menu ul {
    padding: 0;
    list-style: none;
    margin: 0;
    border-top: 1px solid #eee;
  }
  #discovery-main-menu ul.sub-menu {
    display: none;
  }
  #discovery-main-menu ul > li {
    position: relative;
  }
  #discovery-main-menu ul > li > a {
    display: block;
    padding: 7px 5px;
    border-top: 1px solid #eee;
    color: #000;
    position: relative;
  }
  #discovery-main-menu ul > li > a:hover {
    color: #03a9f4;
  }
  #discovery-main-menu ul > li:first-child > a {
    border-top: none;
  }
  #discovery-main-menu ul > li > ul {
    padding-left: 10px;
  }
  #discovery-main-menu ul > li.menu-item-has-children > a:after {
    content: '\f105';
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    top: 0;
    right: 5px;
    height: 100%;
    text-align: center;
    line-height: 2.8em;
  }
  #discovery-main-menu ul > li.menu-item-has-children.sub-menu-open > a:after {
    content: '\f107';
  }
}

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
#main_slider {
  overflow: hidden;
}

.tparrows {
  background: transparent;
}

.tparrows:before {
  font-size: 35px;
}

.tparrows:hover {
  background: transparent;
}

.tparrows:hover:before {
  color: #03a9f4;
}

.tp-bullet {
  border-radius: 50%;
}

.tp-bullet.selected, .tp-bullet:hover {
  background: #03a9f4;
}

.tp-caption.gp-slider-title span {
  color: #03a9f4;
}

#slider_two .zeus .tp-bullet {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  border-radius: 50%;
  background-color: transparent;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  width: 13px;
  height: 13px;
  border: 2px solid white;
}

#slider_two .zeus .tp-bullet:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 50%;
  background-color: white;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
}

#slider_two .zeus .tp-bullet:hover:after,
#slider_two .zeus .tp-bullet.selected:after {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

#slider_two .zeus .tp-bullet-image,
#slider_two .zeus .tp-bullet-imageoverlay {
  width: 135px;
  height: 60px;
  position: absolute;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  bottom: 13px;
  margin-bottom: 10px;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  box-sizing: border-box;
  background-size: cover;
  background-position: center center;
  visibility: hidden;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 4px;
}

#slider_two .zeus .tp-bullet-title,
#slider_two .zeus .tp-bullet-imageoverlay {
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

#slider_two .zeus .tp-bullet-title {
  color: white;
  text-align: center;
  line-height: 15px;
  font-size: 13px;
  font-weight: 600;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  bottom: 45px;
  width: 135px;
  vertical-align: middle;
}

#slider_two .zeus .tp-bullet:hover .tp-bullet-title,
#slider_two .zeus .tp-bullet:hover .tp-bullet-image,
#slider_two .zeus .tp-bullet:hover .tp-bullet-imageoverlay {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px) translateX(-50%);
  transform: translateY(0px) translateX(-50%);
}

#slider_two .zeus.nav-dir-vertical .tp-bullet-image,
#slider_two .zeus.nav-dir-vertical .tp-bullet-imageoverlay {
  bottom: auto;
  margin-right: 10px;
  margin-bottom: 0px;
  right: 13px;
  transform: translateX(0px) translateY(-50%);
  -webkit-transform: translateX(0px) translateY(-50%);
}

#slider_two .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-image {
  transform: translateX(0px) translateY(-50%);
  -webkit-transform: translateX(0px) translateY(-50%);
}

#slider_two .zeus.nav-dir-vertical .tp-bullet-title,
#slider_two .zeus.nav-dir-vertical .tp-bullet-imageoverlay {
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transform: translateX(0px) translateY(-50%);
  -webkit-transform: translateX(0px) translateY(-50%);
}

#slider_two .zeus.nav-dir-vertical .tp-bullet-title {
  bottom: auto;
  right: 100%;
  margin-right: 10px;
}

#slider_two .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-title,
#slider_two .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-image,
#slider_two .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-imageoverlay {
  transform: translateX(0px) translateY(-50%);
  -webkit-transform: translateX(0px) translateY(-50%);
}

#slider_two .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-image,
#slider_two .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-imageoverlay {
  bottom: auto;
  margin-left: 10px;
  margin-bottom: 0px;
  left: 13px;
  transform: translateX(0px) translateY(-50%);
  -webkit-transform: translateX(0px) translateY(-50%);
}

#slider_two .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet:hover .tp-bullet-image {
  transform: translateX(0px) translateY(-50%);
  -webkit-transform: translateX(0px) translateY(-50%);
}

#slider_two .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-title,
#slider_two .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-imageoverlay {
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transform: translateX(0px) translateY(-50%);
  -webkit-transform: translateX(0px) translateY(-50%);
}

#slider_two .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-title {
  bottom: auto;
  left: 100%;
  margin-left: 10px;
}

#slider_two .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-image,
#slider_two .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-imageoverlay {
  bottom: auto;
  top: 13px;
  margin-top: 10px;
  margin-bottom: 0px;
  left: 0px;
  transform: translateY(0px) translateX(-50%);
  -webkit-transform: translateX(0px) translateX(-50%);
}

#slider_two .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet:hover .tp-bullet-image {
  transform: scale(1) translateY(0px) translateX(-50%);
  -webkit-transform: scale(1) translateY(0px) translateX(-50%);
}

#slider_two .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-title,
#slider_two .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-imageoverlay {
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transform: translateY(0px) translateX(-50%);
  -webkit-transform: translateY(0px) translateX(-50%);
}

#slider_two .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-title {
  bottom: auto;
  top: 13px;
  margin-top: 20px;
}

.custom.tparrows {
  cursor: pointer;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  position: absolute;
  display: block;
  z-index: 100;
}

.custom.tparrows:hover {
  background: #000;
}

.custom.tparrows:before {
  font-family: "revicons";
  font-size: 15px;
  color: #fff;
  display: block;
  line-height: 40px;
  text-align: center;
}

.custom.tparrows.tp-leftarrow:before {
  content: "\e824";
}

.custom.tparrows.tp-rightarrow:before {
  content: "\e825";
}

.new-bullet-bar.tp-bullets:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
  box-sizing: content-box;
}

.new-bullet-bar .tp-bullet {
  width: 60px;
  height: 3px;
  position: absolute;
  background: #aaa;
  background: rgba(45, 48, 50, 0.2);
  cursor: pointer;
  box-sizing: content-box;
}

.new-bullet-bar .tp-bullet:hover, .new-bullet-bar .tp-bullet.selected {
  background: rgba(45, 48, 50, 0.65);
}

/* Creative Banner */
#banner-creative {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.modules.scrollview {
  width: 100%;
  height: 100%;
}

.modules.scrollview .module {
  width: 100%;
  height: 100%;
  position: absolute;
}

.modules.scrollview .module .page {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.modules.scrollview .module .page .container-1 {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background-image: url(/assets/img/fff.png);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.modules.scrollview .module .page .container-1 .container-2 {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background-image: url(/assets/img/fff.png);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.modules.scrollview .module .page .container-1 .container-2 .image {
  width: 100%;
  height: 100%;
}

.modules.scrollview .module .page .container-1 .container-2 .image .custom {
  width: 100%;
  height: 100%;
}

.modules.scrollview .module .page .container-1 .container-2 .image .custom .content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.banner-bg {
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.scene {
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: #0f0f13;
  width: 100%;
  height: 100%;
}

.layer {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

.layer .imatge {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  animation-name: float;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes float {
  0% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}

.layer img {
  width: 100%;
}

/* DUST DELANTE */
#img-1 {
  opacity: 0.8;
  background: url("/assets/img/dust-delante.png");
  background-repeat: repeat;
  animation-duration: 330s;
  -webkit-animation-direction: normal;
  animation-direction: normal;
}

/* DUST MEDIO */
#img-2 {
  opacity: 1;
  background: url("/assets/img/dust-medio.png");
  background-repeat: repeat;
  animation-duration: 555s;
  -webkit-animation-direction: normal;
  animation-direction: normal;
}

/* FONDO NEGRO ESTRELLAS GRISES */
#img-3 {
  opacity: 1;
  background: url("/assets/img/bg-dust.png");
  background-repeat: repeat;
  /*animation-duration: 800s*/
  -webkit-animation: 800s ease-in-out infinite;
  -webkit-animation-direction: normal;
  animation-direction: normal;
}

/* AZUL */
.color1 {
  -webkit-animation: color1 10s ease-in-out infinite;
}

@keyframes color1 {
  0% {
    -webkit-transform: rotate(0) translateY(0);
  }
  25% {
    -webkit-transform: rotate(-3deg) translateY(-25px) translateX(-25px);
  }
  50% {
    -webkit-transform: rotate(-3deg) translateY(-50px) translateX(-60px);
  }
  75% {
    -webkit-transform: rotate(-3deg) translateY(-25px) translateX(-25px);
  }
  100% {
    -webkit-transform: rotate(0) translateY(0);
  }
}

/* LILA */
.colors {
  left: 50% !important;
  top: 50% !important;
}

.color2 {
  -webkit-animation: color2 18s ease-in-out infinite;
}

@keyframes color2 {
  50% {
    -webkit-transform: rotate(8deg) scale(0.7) translateY(30px) translateX(60px);
  }
}

/* ROJO */
.color3 {
  -webkit-animation: color3 6s ease-in-out infinite;
}

@keyframes color3 {
  50% {
    -webkit-transform: rotate(-20deg) translateY(-30px) translateX(45px);
  }
}

/* VERDE */
.color4 {
  -webkit-animation: color4 12s ease-in-out infinite;
}

@keyframes color4 {
  50% {
    -webkit-transform: rotate(8deg) scale(1.2) translateY(10px) translateX(-10px);
  }
}

.content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.content .modules .module.header .content > div {
  position: relative;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  top: 47.5%;
  opacity: 0;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.content .modules .module.header .content > div h1 {
  font-family: 'Playfair Display', sans-serif;
  -webkit-font-smoothing: subpixel-antialiased !important;
  font-weight: 700;
  font-size: 80px;
  line-height: 1.1em;
  letter-spacing: 0em;
  max-width: 75%;
  margin: auto;
  color: #fff;
  text-rendering: geometricPrecision;
}

.content .modules .module.header .content > div h1 span {
  margin: 0px auto 20px auto;
  color: #ffe200;
  font-weight: 700;
  text-rendering: geometricPrecision;
  font-family: 'Montserrat', sans-serif;
}

.content .modules .module.header .content > div h1 {
  transform: translate3d(0px, 60px, 0px);
  transition: all .5s ease-in-out;
}

.content .modules .module.header .content > div.visible {
  opacity: 1;
}

.content .modules .module.header .content > div.visible h1 {
  transform: translate3d(0px, 0px, 0px);
}

.content .modules .module .custom > a {
  position: absolute;
  bottom: 32px;
  left: 50%;
  margin-left: -26px;
  width: 51px;
  height: 51px;
  text-align: center;
  z-index: 9;
  background-image: url(/assets/img/down.png) transparent 0 0 no-repeat;
  background-image: none, url("/assets/img/down.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-indent: -9999px;
  opacity: 1;
  z-index: 20;
}

.content .modules .module .custom > a:hover {
  opacity: 0.3;
}

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}

.typed-fade-out {
  opacity: 0;
  animation: 0;
  transition: opacity .25s;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#banner-particales, #banner-ripple {
  height: 100vh;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.header-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.header-content h1 {
  color: #fff;
  font-size: 70px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 700;
}

.header-content h1 span {
  font-family: "Playfair Display", serif;
}

.header-content p {
  color: #FFF;
  margin-bottom: 30px;
  font-size: 16px;
}

.header-content .gp-btn.btn-border {
  margin-right: 10px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
}

.header-content .gp-btn.btn-border:hover, .header-content .gp-btn.btn-border:focus {
  color: #FFF;
  background: #03a9f4;
  border-color: #03a9f4;
}

.header-content .gp-btn {
  margin-left: 10px;
  border: 2px solid #03a9f4;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
}

.header-content .gp-btn:hover, .header-content .gp-btn:focus {
  border-color: #fff;
  color: #202020;
  background: #FFF;
}

.scrolldown {
  position: absolute;
  left: 50%;
  bottom: 32px;
  font-size: 20px;
  color: #FFF;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .header-content h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .header-content p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .header-content .gp-btn {
    font-size: 14px !important;
  }
  .header-content .gp-btn.larg {
    padding: 12px 25px;
  }
  .content .modules .module.header .content > div h1 {
    font-size: 50px;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .content .modules .module.header .custom > a {
    bottom: 7px;
  }
  .content .modules .module.project .custom > a {
    bottom: 0px;
    right: 30px;
    margin: 0;
    left: auto;
  }
  .header-content h1 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .header-content p {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .header-content .gp-btn {
    font-size: 14px !important;
  }
  .header-content .gp-btn.larg {
    padding: 10px 20px;
  }
  .content .modules .module.header .content > div h1 {
    font-size: 32px;
  }
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .content .modules .module.header .custom > a {
    bottom: 7px;
  }
  .content .modules .module.project .custom > a {
    bottom: 0px;
    right: 30px;
    margin: 0;
    left: auto;
  }
  .header-content h1 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .header-content p {
    font-size: 12px;
    margin-bottom: 10px;
    width: 350px;
    margin: 0 auto 20px;
  }
  .header-content p br {
    display: none;
  }
  .header-content .gp-btn {
    font-size: 14px !important;
  }
  .header-content .gp-btn.larg {
    padding: 10px 20px;
  }
  .content .modules .module.header .content > div h1 {
    font-size: 32px;
  }
}

/*--------------------------------------------------------------
  ##  About
  --------------------------------------------------------------*/
#about-colorfull {
  background: #f8f8f8;
}

#about-colorfull .about-feature li:before {
  color: #D81B60;
}

.about-details {
  padding-left: 20px;
}

.about-details .about-title {
  font-size: 24px;
  color: #000;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 10px;
  position: relative;
  padding-bottom: 10px;
}

.about-details .about-title:after {
  position: absolute;
  content: '';
  width: 50px;
  height: 1px;
  background: #03a9f4;
  left: 0;
  bottom: 0;
}

.about-details p {
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
}

.about-feature {
  margin: 20px 0 0 20px;
  padding: 0;
  list-style: none;
}

.about-feature li {
  font-size: 15px;
  font-weight: 500;
  color: #444;
  line-height: 30px;
  position: relative;
}

.about-feature li:before {
  position: absolute;
  content: "\f046";
  font: normal normal normal 15px/1 FontAwesome;
  color: #03a9f4;
  left: -20px;
  top: 5px;
}

.about-video {
  position: relative;
  border-radius: 15px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.3);
}

.about-video img {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.video-play-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  font-size: 6rem;
  background-color: #03a9f4;
  border-radius: 50%;
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.2);
  line-height: 44px;
  text-align: center;
}

.video-play-icon i {
  margin-left: 5px;
  font-size: 30px;
  color: #FFF;
}

.video-play-icon.color-play-btn {
  background: #D81B60;
  box-shadow: 0 0 0 6px rgba(216, 27, 96, 0.5);
}

.about-details.colorfull .about-title:after {
  background: #D81B60;
}

section#about-two {
  padding: 0;
}

.about-wrap {
  background-position: center center;
  background-size: cover;
  padding: 100px 0;
}

.about-thumb img {
  width: 100%;
  height: auto;
}

.tabs-container {
  overflow: hidden;
}

.tabs-container .tabs {
  margin-bottom: 30px;
  padding: 0;
}

.tabs-container .tabs .tab__title:not(.btn) {
  padding: 0;
}

.tabs-container .tabs li {
  display: inline-block;
}

.tabs-container .tabs li:not(:last-child) {
  margin-right: 3.25em;
}

.tabs-container:not(.tabs-5) .tabs-content {
  margin-bottom: 0;
  position: relative;
}

.tabs-container:not(.tabs-5) .tabs-content > li.active .tab__content {
  animation: fadeIn .75s ease forwards;
}

.tabs-container:not(.tabs-5) .tabs-content > li:not(.active) {
  position: absolute;
  top: 0;
  pointer-events: none;
}

.tabs-container:not(.tabs-5) .tabs-content > li:not(.active) .tab__content {
  display: block;
  pointer-events: none;
  animation: fadeOut .5s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
    -webkit-transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
    -webkit-transform: translate3d(-20px, 0, 0);
  }
}

@media all and (max-width: 767px) {
  .tabs-container .tabs {
    margin-bottom: 1.625em;
  }
  .tabs-container .tabs li {
    margin: 0 1.625em 0.40625em 1.625em;
  }
  .tabs-container .tabs li:not(:last-child) {
    margin-right: 0;
  }
}

.tabs-1 .tabs li {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}

.tabs-1 .tabs li:not(.active) {
  opacity: .8;
}

.tabs-1 .tabs li:hover {
  opacity: .9;
}

.tabs-1 .tabs li .btn {
  border-color: transparent;
  transition: 0.3s ease;
  color: #000;
  backface-visibility: hidden;
  text-transform: uppercase;
  border-bottom: 1px solid #FFF;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 600;
}

.tabs-1 .tabs li:not(:last-child) {
  margin-right: 1.625em;
}

.tabs-1 .tabs li.active .btn {
  background: #03a9f4;
  color: #FFF;
  border-color: #03a9f4;
  box-shadow: 0px 17.321px 40px 0px rgba(34, 35, 40, 0.198);
  border-radius: 25px;
}

.tabs-content {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tabs-content .tab-content-inner h6 {
  font-size: 17px;
  font-weight: 600;
}

.tabs-content .tab-content-inner p {
  font-size: 14px;
  line-height: 28px;
  color: #707070;
}

.tabs-2 .tabs h5, .tabs-2 h6 {
  margin: 0;
}

.tabs-3.tabs-container .tabs li {
  margin-right: 0;
}

.tabs-3.tabs-container .tabs .tab__title {
  position: relative;
  overflow: hidden;
  width: 250px;
  height: 150px;
  text-align: center;
}

.tabs-3.tabs-container .tabs .tab__title h5 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  z-index: 2;
  color: #fff;
  width: 100%;
}

.about-details-three h4 {
  color: #4772d9;
  background: linear-gradient(180deg, #4772d9, #6d47d9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-details-three h2 {
  font-size: 35px;
  color: #202020;
  margin: 20px 0;
  line-height: 40px;
}

.about-details-three p {
  margin-bottom: 30px;
  color: #333;
  line-height: 28px;
}

#about-three {
  padding: 150px 0 250px;
  background: #fafafa;
  background-position: center center;
  background-size: cover;
}

.ab-three-thumb {
  position: relative;
}

.ab-three-thumb img {
  position: absolute;
  left: 0;
}

@media screen and (max-width: 768px) {
  .about-video {
    max-width: 95%;
    margin: 0 auto 30px;
  }
}

@media screen and (max-width: 500px) {
  .about-wrap .section-title {
    margin-top: 20px;
  }
  #feature-two .section-title a {
    margin-bottom: 20px;
  }
  .tabs-1 .tabs li:not(:last-child) {
    margin-right: 0;
  }
  .tabs-1 .tabs li .btn {
    padding: 8px;
  }
  .tabs-container .tabs li {
    margin: 0;
  }
}

/*--------------------------------------------------------------
  ##  Feature
  --------------------------------------------------------------*/
#feature {
  background: #f9f9f9;
}

.feature-box-one {
  text-align: center;
  border: 1px solid #e0e0e0;
  padding: 20px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.feature-box-one .fea-icon {
  font-size: 30px;
  height: 70px;
  width: 70px;
  margin: 0 auto 15px;
  background: rgba(0, 0, 0, 0.05);
  line-height: 77px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.feature-box-one h3 {
  font-size: 20px;
  color: #333;
  transition: all 0.3s ease-in-out;
  margin-bottom: 10px;
}

.feature-box-one:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
  border-color: #03a9f4;
  transform: translateY(-3px);
  background: #FFF;
}

.feature-box-one:hover .fea-icon {
  background: #03a9f4;
  color: #FFF;
}

.feature-box-one:hover h3 {
  color: #03a9f4;
}

/* Feature One Colorfull */
.feature-box-one.color-one .fea-icon {
  background: #D81B60;
  color: #FFF;
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
}

.feature-box-one.color-one:hover {
  border-color: #D81B60;
}

.feature-box-one.color-one:hover h3 {
  color: #D81B60;
}

.feature-box-one.color-two .fea-icon {
  background: #9c27b0;
  color: #FFF;
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
}

.feature-box-one.color-two:hover {
  border-color: #9c27b0;
}

.feature-box-one.color-two:hover h3 {
  color: #9c27b0;
}

.feature-box-one.color-three .fea-icon {
  background: #03a9f4;
  color: #FFF;
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
}

.feature-box-one.color-three:hover {
  border-color: #03a9f4;
}

.feature-box-one.color-three:hover h3 {
  color: #03a9f4;
}

.feature-box-one.color-four .fea-icon {
  background: #f96332;
  color: #FFF;
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
}

.feature-box-one.color-four:hover {
  border-color: #f96332;
}

.feature-box-one.color-four:hover h3 {
  color: #f96332;
}

.feature-box-one.color-five .fea-icon {
  background: #ffe200;
  color: #FFF;
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
}

.feature-box-one.color-five:hover {
  border-color: #ffe200;
}

.feature-box-one.color-five:hover h3 {
  color: #ffe200;
}

.feature-box-one.color-six .fea-icon {
  background: #00C851;
  color: #FFF;
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
}

.feature-box-one.color-six:hover {
  border-color: #00C851;
}

.feature-box-one.color-six:hover h3 {
  color: #00C851;
}

/* Icon Box Two */
.feature-box-two {
  background: #FFF;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  transition: all 0.15s ease-in-out;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
}

.feature-box-two .fea-icon {
  margin-bottom: 20px;
}

.feature-box-two .fea-icon i {
  font-size: 50px;
}

.feature-box-two h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
}

.feature-box-two p {
  color: #444;
}

.feature-box-two:hover {
  background: #03a9f4;
}

.feature-box-two:hover .fea-icon {
  color: #FFF;
}

.feature-box-two:hover h3 {
  color: #FFF;
}

.feature-box-two:hover p {
  color: #FFF;
}

/* Icon Box Three */
.feature-box-three {
  text-align: center;
  margin-bottom: 30px;
  padding: 30px;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}

.feature-box-three .fea-icon {
  margin-bottom: 20px;
}

.feature-box-three .fea-icon i {
  font-size: 60px;
  color: #03a9f4;
}

.feature-box-three h3 {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  position: relative;
  padding-bottom: 10px;
}

.feature-box-three h3:after {
  position: absolute;
  content: '';
  height: 2px;
  width: 50px;
  background: #03a9f4;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  transition: all 0.3s linear;
}

.feature-box-three:hover {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.085);
}

.feature-box-three:hover h3 {
  color: #03a9f4;
}

.feature-box-three:hover h3:after {
  width: 70px;
}

.feature-box-three.color-one .fea-icon {
  margin-bottom: 20px;
}

.feature-box-three.color-one .fea-icon i {
  font-size: 60px;
  color: #D81B60;
}

.feature-box-three.color-one:hover h3 {
  color: #D81B60;
}

.feature-box-three.color-one:hover h3:after {
  background: #D81B60;
}

.feature-box-three.color-two .fea-icon {
  margin-bottom: 20px;
}

.feature-box-three.color-two .fea-icon i {
  font-size: 60px;
  color: #9c27b0;
}

.feature-box-three.color-two:hover h3 {
  color: #9c27b0;
}

.feature-box-three.color-two:hover h3:after {
  background: #9c27b0;
}

.feature-box-three.color-three .fea-icon {
  margin-bottom: 20px;
}

.feature-box-three.color-three .fea-icon i {
  font-size: 60px;
  color: #03a9f4;
}

.feature-box-three.color-three:hover h3 {
  color: #03a9f4;
}

.feature-box-three.color-three:hover h3:after {
  background: #03a9f4;
}

.feature-box-three.color-four .fea-icon {
  margin-bottom: 20px;
}

.feature-box-three.color-four .fea-icon i {
  font-size: 60px;
  color: #00C851;
}

.feature-box-three.color-four:hover h3 {
  color: #00C851;
}

.feature-box-three.color-four:hover h3:after {
  background: #00C851;
}

.feature-box-three.color-five .fea-icon {
  margin-bottom: 20px;
}

.feature-box-three.color-five .fea-icon i {
  font-size: 60px;
  color: #f96332;
}

.feature-box-three.color-five:hover h3 {
  color: #f96332;
}

.feature-box-three.color-five:hover h3:after {
  background: #f96332;
}

.feature-box-three.color-six .fea-icon {
  margin-bottom: 20px;
}

.feature-box-three.color-six .fea-icon i {
  font-size: 60px;
  color: #ffe200;
}

.feature-box-three.color-six:hover h3 {
  color: #ffe200;
}

.feature-box-three.color-six:hover h3:after {
  background: #ffe200;
}

/* Icon Box four */
.feature-box-four, .feature-box-five {
  margin-bottom: 50px;
}

.feature-box-four .fea-icon, .feature-box-five .fea-icon {
  width: 70px;
  height: 70px;
  float: left;
  text-align: center;
  background: #03a9f4;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.feature-box-four .fea-icon i, .feature-box-five .fea-icon i {
  font-size: 35px;
  line-height: 70px;
  color: #fff;
}

.feature-box-four .contant h3, .feature-box-five .contant h3 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #202020;
  transition: all 0.3s ease-in-out;
}

.feature-box-four:hover .fea-icon, .feature-box-five:hover .fea-icon {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(3, 169, 244, 0.6);
}

.feature-box-four:hover h3, .feature-box-five:hover h3 {
  color: #03a9f4;
}

.feature-box-four .fea-icon {
  float: left;
}

.feature-box-four .contant {
  margin-left: 80px;
}

/* Icon Box Five */
.feature-box-five .fea-icon {
  float: right;
}

.feature-box-five .contant {
  margin-right: 80px;
  text-align: right;
}

/* Icon Box Six */
.feature-box-six {
  margin-bottom: 30px;
}

.feature-box-six .fea-icon {
  transition: all 0.3s ease-in-out;
  margin-bottom: 10px;
}

.feature-box-six .fea-icon i {
  font-size: 45px;
  color: #03a9f4;
  transition: all 0.3s ease-in-out;
}

.feature-box-six .content h3 {
  font-size: 22px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  position: relative;
}

.feature-box-six .content h3:after {
  position: absolute;
  content: '';
  height: 2px;
  width: 50px;
  background: #03a9f4;
  left: 0;
  bottom: 0;
}

/* Icon Box Animation Seven  */
.feature-box-seven {
  padding: 30px;
  transition: all 0.35s linear;
  margin-bottom: 30px;
  position: relative;
}

.feature-box-seven .animated-icon {
  position: relative;
  display: block;
  font-size: 3.71429em;
  line-height: 60px;
  color: #f42958;
  transition: all 0.3s ease;
}

.feature-box-seven .animated-icon[data-plugin-animated-icon] svg, .feature-box-seven .animated-icon[data-plugin-animated-icon] object {
  opacity: 0;
}

.feature-box-seven .animated-icon svg {
  display: inline-block;
  width: 52px;
  line-height: 0;
}

.feature-box-seven h3 {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  position: relative;
  padding-bottom: 15px;
  overflow: hidden;
}

.feature-box-seven h3:before, .feature-box-seven h3:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: left center;
  background: #fc4a1a;
  background: -webkit-linear-gradient(to right, #f7b733, #fc4a1a);
  background: linear-gradient(to right, #4772d9, #6d47d9);
  transition: transform 0.65s cubic-bezier(0.7, 0, 0.2, 1);
}

.feature-box-seven h3:before {
  transform: scale3d(0.13, 1, 1);
}

.feature-box-seven h3:after {
  transform: translate3d(-10px, 0, 0) scale3d(0, 1, 1);
}

.feature-box-seven:hover {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.085);
}

.feature-box-seven:hover h3 {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-box-seven:hover h3:before {
  transform-origin: left center;
  transform: translate3d(100%, 0, 0) scale3d(2, 1, 1);
}

.feature-box-seven:hover h3:after {
  transform-origin: left center;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

.appear-animation-visible svg, .appear-animation-visible object {
  opacity: 1 !important;
}

.feature-wrap {
  margin-top: 100px;
}

.feature-thumb {
  max-width: 400px;
  margin: 0 auto;
  padding: 0 30px;
}

.feature-thumb img {
  width: 100%;
  height: auto;
}

#feature-two .section-title {
  margin-bottom: 0;
}

#feature-two .section-title h2 {
  font-size: 38px;
}

/* Iconbox Gradient */
.feature-box-one.gredient, .feature-box-two.gredient, .feature-box-three.gredient, .feature-box-four.gredient, .feature-box-five.gredient, .feature-box-six.gredient {
  position: relative;
  transition: all 0.3s ease-in-out;
}

.feature-box-one.gredient .fea-icon, .feature-box-two.gredient .fea-icon, .feature-box-three.gredient .fea-icon, .feature-box-four.gredient .fea-icon, .feature-box-five.gredient .fea-icon, .feature-box-six.gredient .fea-icon {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
  color: #FFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-box-one.gredient h3, .feature-box-two.gredient h3, .feature-box-three.gredient h3, .feature-box-four.gredient h3, .feature-box-five.gredient h3, .feature-box-six.gredient h3 {
  position: relative;
  transition: all 0.3s ease-in-out;
}

.feature-box-one.gredient:hover h3, .feature-box-two.gredient:hover h3, .feature-box-three.gredient:hover h3, .feature-box-four.gredient:hover h3, .feature-box-five.gredient:hover h3, .feature-box-six.gredient:hover h3 {
  color: #4772d9;
  background: linear-gradient(180deg, #4772d9, #6d47d9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-box-one.gredient .fea-icon {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
  color: #FFF;
  -webkit-text-fill-color: #FFF;
}

.feature-box-one.gredient:before {
  content: "";
  position: absolute;
  display: block;
  background-color: #fff;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border-radius: 5px;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.feature-box-one.gredient:hover {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
  z-index: 1;
  border-color: #FFF;
}

.feature-box-two.gredient:hover {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
}

.feature-box-two.gredient:hover .fea-icon {
  -webkit-text-fill-color: #FFF;
}

.feature-box-two.gredient:hover h3 {
  -webkit-text-fill-color: #FFF;
}

.feature-box-three.gredient h3:after, .feature-box-six.gredient h3:after {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
}

.feature-box-five.gredient .fea-icon, .feature-box-four.gredient .fea-icon {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
  color: #FFF;
  -webkit-text-fill-color: #FFF;
}

.feature-box-five.gredient:hover .fea-icon, .feature-box-four.gredient:hover .fea-icon {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(71, 114, 217, 0.6);
}

@media screen and (max-width: 768px) {
  .feature-box-one, .feature-box-two, .feature-box-three, .feature-box-four, .feature-box-five {
    margin: 0 auto 20px;
    max-width: 450px;
  }
}

/*--------------------------------------------------------------
  ##  Portfolio
  --------------------------------------------------------------*/
#portfolio-two, #portfolio-two-colorfull {
  background: #f5f5f5;
}

.grid-item {
  float: left;
}

.grid-item img {
  width: 100%;
  height: auto;
}

.gp-isotope:before, .gp-isotope:after {
  display: table;
  content: "";
}

.gp-isotope:after {
  clear: both;
}

.grid-sizer, .grid-item {
  width: 25%;
}

.grid-item {
  overflow: hidden;
}

.gp-isotope-filter {
  margin: 0 0 40px;
  text-align: center;
  padding: 0;
}

.gp-isotope-filter > li {
  display: inline-block;
  padding-left: 0;
  padding-right: 10px;
}

.gp-isotope-filter > li > a {
  position: relative;
  font-size: 13px;
  transition: all .3s;
  text-transform: uppercase;
  color: #666;
  font-weight: 500;
  padding: 8px 18px;
  border-radius: 30px;
  display: inline-block;
}

.gp-isotope-filter > li > a:after {
  content: '';
  -webkit-transition: all .3s;
  transition: all .3s;
}

.gp-isotope-filter > li:hover a {
  background: #f5f5f5;
  color: #202020;
}

.gp-isotope-filter > li.current a {
  color: #FFF;
  text-decoration: none;
  background: #03a9f4;
}

.gp-portfolio-items {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}

.gp-portfolio-item .portfolio_details_wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  color: #FFF;
  text-align: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.gp-portfolio-item .portfolio_details_wrap:before {
  content: '';
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition: all .3s;
  text-align: center;
  opacity: 1;
  background: rgba(3, 169, 244, 0.9);
  position: absolute;
  margin: 15px;
  transform: scale(0.5);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  transition: all 0.5s cubic-bezier(0.05, -0.17, 0.39, 1.65);
}

.gp-portfolio-item .portfolio_details_wrap .portfolio-info {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transition: all 0.3s ease-in-out;
  transform: translate(-50%, -50%);
}

.gp-portfolio-item .portfolio_details_wrap .portfolio-info h4 {
  color: #FFF;
  font-size: 22px;
}

.gp-portfolio-item .portfolio_details_wrap .portfolio-info .popup-modal {
  height: 40px;
  width: 40px;
  line-height: 28px;
  text-align: center;
  border: 1px solid #FFF;
  border-radius: 5px;
  color: #FFF;
  font-size: 13px;
  border-radius: 50%;
  display: block;
  margin: 0 auto 20px;
  transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  transform: translateY(-20px);
  opacity: 0;
  visibility: hidden;
}

.gp-portfolio-item .portfolio_details_wrap .portfolio-info .popup-modal i {
  line-height: 37px;
  font-size: 18px;
}

.gp-portfolio-item .portfolio_details_wrap .portfolio-info .popup-modal:hover {
  background: #202020;
  border-color: #202020;
  text-decoration: none;
  color: #FFF;
}

.gp-portfolio-item .portfolio_details_wrap .portfolio-info .portfolio-title {
  margin-bottom: 0;
  font-size: 20px;
  transition: all 0.33s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  top: 20px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
}

.gp-portfolio-item .portfolio_details_wrap .portfolio-info .portfolio-categorie {
  transform: translateY(15px);
  transition: all 0.7s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  top: 20px;
  opacity: 0;
  display: inline-block;
  visibility: hidden;
}

.gp-portfolio-item:hover .portfolio_details_wrap {
  opacity: 1;
}

.gp-portfolio-item:hover .portfolio_details_wrap:before {
  transform: scale(1);
  margin: 20px;
}

.gp-portfolio-item:hover .portfolio_details_wrap .portfolio-info .popup-modal, .gp-portfolio-item:hover .portfolio_details_wrap .portfolio-info .portfolio-title, .gp-portfolio-item:hover .portfolio_details_wrap .portfolio-info .portfolio-categorie {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.gp-portfolio-item:hover .portfolio_details_wrap .portfolio-info .portfolio-title {
  transition-delay: 0.25s;
}

.gp-portfolio-item:hover .portfolio_details_wrap .portfolio-info .portfolio-categorie {
  transition-delay: 0.33s;
}

.gp-isotope-grid__img {
  overflow: hidden;
}

.gp-isotope-1 .gp-isotope-filter {
  display: block;
}

.gp-isotope-1 .grid-item {
  padding: 7px;
}

.gp-isotope-1 .gp-isotope-grid {
  margin-bottom: 44px;
}

/* Portfolio Colorfull */
.gp-isotope-filter.colorfull > li.current a {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6);
  background: #9c27b0;
}

.gp-portfolio-item.color-one .portfolio_details_wrap:before {
  background: rgba(216, 27, 96, 0.9);
}

.gp-portfolio-item.color-two .portfolio_details_wrap:before {
  background: rgba(156, 39, 176, 0.9);
}

.gp-portfolio-item.color-three .portfolio_details_wrap:before {
  background: rgba(3, 169, 244, 0.9);
}

.gp-portfolio-item.color-four .portfolio_details_wrap:before {
  background: rgba(0, 200, 81, 0.9);
}

.gp-portfolio-item.color-five .portfolio_details_wrap:before {
  background: rgba(249, 99, 50, 0.9);
}

.gp-portfolio-item.color-six .portfolio_details_wrap:before {
  background: rgba(255, 226, 0, 0.9);
}

/* Portfolio Gredient */
.gp-isotope-filter.greadient > li.current a {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(71, 114, 217, 0.6);
  background: linear-gradient(180deg, #4772d9, #6d47d9);
}

.gp-portfolio-item.gredient .portfolio_details_wrap:before {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
  opacity: 0.9;
}

@media screen and (max-width: 1024px) {
  .grid-sizer, .grid-item {
    width: 33%;
  }
}

@media screen and (max-width: 768px) {
  .grid-sizer, .grid-item {
    width: 50%;
  }
}

@media screen and (max-width: 500px) {
  .grid-sizer, .grid-item {
    width: 100%;
  }
  .gp-isotope-filter > li {
    padding-right: 5px;
  }
  .gp-isotope-filter > li > a {
    font-size: 10px;
    padding: 3px 9px;
  }
}

/*--------------------------------------------------------------
  ##  Work Prosses
  --------------------------------------------------------------*/
#work-prosses {
  padding: 100px 0;
}

.pss-wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pss-wrapper li {
  display: inline-block;
  width: 22%;
  text-align: center;
  position: relative;
  margin-right: 32px;
}

.pss-wrapper li:after {
  position: absolute;
  content: '';
  width: 35px;
  height: 5px;
  background: #f3f3f3;
  right: -35px;
  top: 26%;
  border-radius: 30px;
}

.pss-wrapper li:last-child:after {
  display: none;
}

.pss-container .icon-circle {
  height: 160px;
  width: 160px;
  background: #f3f3f3;
  border-radius: 50%;
  margin: 0 auto 20px;
  position: relative;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.05);
}

.pss-container .icon-circle i {
  font-size: 60px;
  color: #03a9f4;
  line-height: 160px;
}

.pss-text-area h4 {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
  font-family: "Catamaran", sans-serif;
  letter-spacing: 1px;
}

.pss-text-area .count {
  font-size: 20px;
  font-weight: 600;
  color: #03a9f4;
  display: block;
  margin-bottom: 15px;
}

/* Work Stape Two */
.work-prosses#work-prosses-two {
  background: #f5f5f5;
  padding: 100px 0;
}

.gp-process-steps.gp-process-steps-two .icon-circle {
  background: #FFF;
}

/* Prosses Colorfull */
.pss-container.color-one .icon-circle i {
  color: #D81B60;
}

.pss-container.color-one .pss-text-area .count {
  color: #D81B60;
}

.pss-container.color-two .icon-circle i {
  color: #9c27b0;
}

.pss-container.color-two .pss-text-area .count {
  color: #9c27b0;
}

.pss-container.color-three .icon-circle i {
  color: #03a9f4;
}

.pss-container.color-three .pss-text-area .count {
  color: #03a9f4;
}

.pss-container.color-four .icon-circle i {
  color: #00C851;
}

.pss-container.color-four .pss-text-area .count {
  color: #00C851;
}

.pss-container.gredient .icon-circle i {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
  color: #FFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pss-container.gredient .pss-text-area .count {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
  color: #FFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 768px) {
  .pss-wrapper li {
    width: 44%;
    margin-bottom: 20px;
    margin-right: 28px;
  }
  .pss-wrapper li:nth-child(2):after {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .pss-wrapper li {
    width: 100%;
  }
  .pss-wrapper li:after {
    display: none;
  }
}

/*--------------------------------------------------------------
  ##  Service
  --------------------------------------------------------------*/
#service, #service-colofull, #service-animate {
  padding: 100px 0;
  background: #f8f8f8;
}

/*--------------------------------------------------------------
  ##  Pricing
  --------------------------------------------------------------*/
#pricing {
  padding: 100px 0;
}

.gp-price-table-one, .gp-price-table-two {
  background-color: #efefef;
  border-radius: 15px;
  max-width: 406px;
}

.gp-price-table-one .price-inner .currency, .gp-price-table-two .price-inner .currency {
  font-size: 25px;
  vertical-align: top;
  line-height: 0;
  font-weight: 900;
  line-height: 17px;
  color: #202020;
}

.gp-price-table-one .price-inner .price, .gp-price-table-two .price-inner .price {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
  display: inline-block;
  line-height: 40px;
  color: #202020;
}

.gp-price-table-one .price-table-container, .gp-price-table-two .price-table-container {
  border-radius: 15px;
  display: inline-block;
  text-align: center;
  width: 100%;
  max-width: 377px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: all .3s;
  margin-top: 4px;
  padding: 50px 30px 30px 35px;
}

.gp-price-table-one .price-table-container .title, .gp-price-table-two .price-table-container .title {
  font-size: 20px;
  font-weight: 700;
  color: #03a9f4;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 30px;
  display: inline-block;
}

.gp-price-table-one .price-table-container .title:after, .gp-price-table-two .price-table-container .title:after {
  position: absolute;
  content: '';
  width: 40px;
  height: 2px;
  background: #03a9f4;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.gp-price-table-one .price-table-container .description, .gp-price-table-two .price-table-container .description {
  margin: 0 0 35px;
  padding: 0;
  list-style: none;
}

.gp-price-table-one .price-table-container .description li, .gp-price-table-two .price-table-container .description li {
  line-height: 36px;
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.gp-price-table-one .price-table-container .gp-btn, .gp-price-table-two .price-table-container .gp-btn {
  padding: 14px 40px;
  background: #FFF;
  display: inline-block;
  color: #000;
  font-weight: 700;
  border-radius: 30px;
  width: 180px;
  border: none;
}

.gp-price-table-one .price-table-container .gp-btn:hover, .gp-price-table-two .price-table-container .gp-btn:hover {
  background: #03a9f4;
  color: #FFF;
}

.gp-price-table-one .price-table-container:hover, .gp-price-table-two .price-table-container:hover {
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.15);
  margin-top: 0;
}

.gp-price-table-one.color-active, .gp-price-table-two.color-active {
  background: #D81B60;
}

.gp-price-table-one.color-active .price-table-container .title, .gp-price-table-two.color-active .price-table-container .title {
  color: #FFF;
}

.gp-price-table-one.color-active .price-table-container .description li, .gp-price-table-two.color-active .price-table-container .description li {
  color: #FFF;
}

.gp-price-table-one.color-active .price-inner .currency, .gp-price-table-one.color-active .price-inner .price, .gp-price-table-two.color-active .price-inner .currency, .gp-price-table-two.color-active .price-inner .price {
  color: #FFF;
}

.gp-price-table-one.color-one:hover, .gp-price-table-two.color-one:hover {
  background: #9c27b0;
}

.gp-price-table-one.color-one:hover .price-table-container .title, .gp-price-table-two.color-one:hover .price-table-container .title {
  color: #FFF;
}

.gp-price-table-one.color-one:hover .price-table-container .description li, .gp-price-table-two.color-one:hover .price-table-container .description li {
  color: #FFF;
}

.gp-price-table-one.color-one:hover .price-inner .currency, .gp-price-table-one.color-one:hover .price-inner .price, .gp-price-table-two.color-one:hover .price-inner .currency, .gp-price-table-two.color-one:hover .price-inner .price {
  color: #FFF;
}

.gp-price-table-one.color-two:hover, .gp-price-table-two.color-two:hover {
  background: #00C851;
}

.gp-price-table-one.color-two:hover .price-table-container .title, .gp-price-table-two.color-two:hover .price-table-container .title {
  color: #FFF;
}

.gp-price-table-one.color-two:hover .price-table-container .description li, .gp-price-table-two.color-two:hover .price-table-container .description li {
  color: #FFF;
}

.gp-price-table-one.color-two:hover .price-inner .currency, .gp-price-table-one.color-two:hover .price-inner .price, .gp-price-table-two.color-two:hover .price-inner .currency, .gp-price-table-two.color-two:hover .price-inner .price {
  color: #FFF;
}

/* Pricing two */
#pricing-two {
  padding: 100px 0;
  background: #f5f5f5;
}

.gp-price-table-two {
  background: rgba(0, 0, 0, 0.03);
}

.gp-price-table-two .price-table-container {
  max-width: 406px;
  margin-top: 0;
  box-shadow: none;
}

.gp-price-table-two .title-inner {
  margin-bottom: 40px;
}

.gp-price-table-two .title-inner .title {
  font-size: 18px;
  margin-bottom: 0px;
  padding-bottom: 0;
  color: #333;
}

.gp-price-table-two .title-inner .title:after {
  display: none;
}

.gp-price-table-two .title-inner span {
  display: block;
  color: #444;
}

.gp-price-table-two .price-inner {
  margin-bottom: 40px;
}

.gp-price-table-two .price-inner .price {
  display: block;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 10px;
}

.gp-price-table-two .price-inner .price-time {
  font-size: 15px;
  font-weight: 500;
}

.gp-price-table-two .description li {
  font-size: 14px !important;
  position: relative;
  font-weight: 400;
}

.gp-price-table-two .description li i {
  margin-right: 10px;
  font-size: 14px;
  color: #03a9f4;
}

.gp-price-table-two .gp-btn {
  background-color: transparent;
  border: 2px solid #03a9f4;
  color: #03a9f4;
  padding: 14px 40px;
}

.gp-price-table-two .gp-btn:hover {
  background-color: #03a9f4;
  color: #FFF;
}

.gp-price-table-two:hover .price-table-container {
  box-shadow: none;
}

.gp-price-table-two.active {
  background: #FFF;
  border-radius: 0;
  position: relative;
}

.gp-price-table-two.active:before, .gp-price-table-two.active:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 30px;
  background: #FFF;
}

.gp-price-table-two.active:before {
  top: -20px;
  left: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.gp-price-table-two.active:after {
  bottom: -20px;
  left: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.gp-price-table-two.active .price-table-container {
  box-shadow: 5px 8.7px 40px rgba(99, 117, 138, 0.3);
}

.gp-price-table-two.active .price-table-container .gp-btn {
  background: #03a9f4;
  color: #FFF;
  box-shadow: 4px 6.9px 16px rgba(3, 169, 244, 0.4);
}

.gp-price-table-two.active .price-table-container .gp-btn:hover {
  background: rgba(3, 169, 244, 0.8);
  box-shadow: 4px 6.9px 16px rgba(3, 169, 244, 0.6);
  color: #FFF;
}

/* Pricing Table Gradient */
.gp-price-table-two {
  z-index: 1;
}

.gp-price-table-two.gradiant {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
}

.gp-price-table-two.gradiant .price-table-container {
  box-shadow: none;
}

.gp-price-table-two.gradiant .price-table-container .title-inner .title {
  color: #FFF;
}

.gp-price-table-two.gradiant .price-table-container .title-inner span {
  color: #efefef;
}

.gp-price-table-two.gradiant .price-table-container .price-inner .price {
  color: #FFF;
}

.gp-price-table-two.gradiant .price-table-container .price-inner .price-time {
  color: #dedede;
}

.gp-price-table-two.gradiant .price-table-container .price-table-container {
  box-shadow: none;
}

.gp-price-table-two.gradiant .price-table-container .description li {
  color: #FFF;
}

.gp-price-table-two.gradiant .price-table-container .description li i {
  color: #03a9f4;
}

.gp-price-table-two.gradiant.active {
  background: #fc4a1a;
  background: -webkit-linear-gradient(to bottom, #f7b733, #fc4a1a);
  background: linear-gradient(to bottom, #f7b733, #fc4a1a);
  transform: scale(1.07);
  border-radius: 20px;
  z-index: 10;
}

.gp-price-table-two.gradiant.active:after, .gp-price-table-two.gradiant.active:before {
  background: #fc4a1a;
  background: -webkit-linear-gradient(to bottom, #f7b733, #fc4a1a);
  background: linear-gradient(to bottom, #f7b733, #fc4a1a);
  display: none;
}

@media screen and (max-width: 768px) {
  .gp-price-table-one, .gp-price-table-two {
    margin: 0 auto 20px;
  }
  .price-table-container {
    padding-bottom: 20px;
  }
}

/*--------------------------------------------------------------
  ##  Client
  --------------------------------------------------------------*/
#client {
  padding: 70px 0 100px;
}

.brand-logo img {
  width: 90%;
}

#client-two {
  padding: 100px 0;
}

.client {
  margin: 0;
  padding: 0;
  list-style: none;
}

.client li {
  display: inline-block;
  width: 24%;
  padding: 25px;
  text-align: center;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  float: left;
  transition: all 0.3s ease-in-out;
}

.client li img {
  width: 120px;
  height: 120px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.client li:hover {
  transform: scale(1.05);
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  border-color: transparent;
  background: #FFF;
}

.client li:hover img {
  filter: grayscale(0);
}

@media screen and (max-width: 768px) {
  .client li {
    width: 32%;
  }
  .client li.no-border-right {
    border-right: 1px solid #e7e7e7 !important;
  }
  .client li.no-border-bottom {
    border-bottom: 1px solid #e7e7e7 !important;
  }
}

@media screen and (max-width: 500px) {
  .client li {
    width: 50%;
  }
  .client li img {
    width: 100px;
    height: 100px;
  }
}

/*--------------------------------------------------------------
  ##  Blog
  --------------------------------------------------------------*/
.blog-post {
  background: #f5f5f5;
  margin-bottom: 20px;
}

.blog-post.thumb-left .blog-thumb {
  float: left;
}

.blog-post.thumb-left .blog-content {
  margin-left: 380px;
}

.blog-post.thumb-right .blog-thumb {
  float: right;
}

.blog-post.thumb-right .blog-content {
  margin-left: 0;
  margin-right: 380px;
}

.blog-post .blog-thumb {
  position: relative;
}

.blog-post .blog-thumb img {
  width: 100%;
}

.blog-post .blog-thumb .date {
  height: 55px;
  width: 55px;
  background: rgba(3, 169, 244, 0.8);
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 15px;
  font-weight: 700;
  color: #FFF;
  text-align: center;
  border-radius: 3px;
  padding: 4px;
}

.blog-post .blog-thumb .date span {
  font-size: 25px;
  font-weight: 800;
  position: relative;
}

.blog-post .blog-thumb .date span:after {
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 30px;
  height: 1px;
  background: #FFF;
  content: '';
}

.blog-post .blog-content {
  min-height: 300px;
  padding: 30px 20px;
}

.blog-post .blog-content .post-meta {
  margin-bottom: 10px;
  padding: 0;
  list-style: none;
}

.blog-post .blog-content .post-meta li {
  display: inline-block;
  margin-right: 20px;
  font-weight: 600;
  color: #444;
  position: relative;
  font-size: 15px;
}

.blog-post .blog-content .post-meta li:first-child:after {
  position: absolute;
  right: -20px;
  top: 0;
  content: 'In';
  color: #000;
}

.blog-post .blog-content .post-meta li a {
  color: #03a9f4;
  transition: all 0.3s ease-in-out;
  font-weight: 300;
  font-style: italic;
}

.blog-post .blog-content .post-meta li a:hover {
  color: #000;
}

.blog-post .blog-content h3 {
  margin-bottom: 20px;
}

.blog-post .blog-content h3 a {
  color: #444;
  font-weight: 700;
  font-size: 22px;
}

.blog-post .blog-content h3 a:hover {
  color: #03a9f4;
}

.blog-post .blog-content p {
  margin-bottom: 15px;
}

.read-more-btn {
  font-size: 15px;
  color: #03a9f4;
  font-weight: 500;
  font-style: italic;
}

.read-more-btn:hover {
  color: #000;
  text-decoration: underline;
}

#blog-two {
  background: #f8f8f8;
  padding: 100px 0;
}

.blog-post-2 {
  background: #FFF;
  border-radius: 10px;
}

.blog-post-2 .blog-thumb {
  position: relative;
  overflow: hidden;
}

.blog-post-2 .blog-thumb img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.blog-post-2 .blog-thumb .blog-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.blog-post-2 .blog-thumb .blog-overlay:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  content: '';
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
}

.blog-post-2 .blog-content {
  padding: 20px;
}

.blog-post-2 .blog-content .post-meta {
  padding: 0;
  list-style: none;
}

.blog-post-2 .blog-content .post-meta li {
  display: inline-block;
  margin-right: 10px;
  color: #03a9f4;
  font-style: italic;
  position: relative;
  font-size: 13px;
}

.blog-post-2 .blog-content .post-meta li:after {
  position: absolute;
  content: '/';
  right: -10px;
  top: 0;
  font-size: 12px;
}

.blog-post-2 .blog-content .post-meta li:last-child:after {
  display: none;
}

.blog-post-2 .blog-content .post-meta li a {
  color: #03a9f4;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.blog-post-2 .blog-content .post-meta li a:hover {
  color: #000;
}

.blog-post-2 .blog-content h3 a {
  color: #444;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.blog-post-2 .blog-content h3 a:hover {
  color: #03a9f4;
}

.blog-post-2 .blog-content p {
  margin-bottom: 15px;
}

.blog-post-2:hover .blog-thumb .blog-overlay:after {
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
}

.blog-post-2.color-one .blog-content .post-meta li a {
  color: #D81B60;
}

.blog-post-2.color-one .blog-content .post-meta li a:hover {
  color: #000;
}

.blog-post-2.color-one .blog-content h3 a:hover {
  color: #D81B60;
}

.blog-post-2.color-one .read-more-btn {
  color: #D81B60;
}

.blog-post-2.color-one .read-more-btn:hover {
  color: #202020;
}

.blog-post-2.color-two .blog-content .post-meta li a {
  color: #9c27b0;
}

.blog-post-2.color-two .blog-content .post-meta li a:hover {
  color: #000;
}

.blog-post-2.color-two .blog-content h3 a:hover {
  color: #9c27b0;
}

.blog-post-2.color-two .read-more-btn {
  color: #9c27b0;
}

.blog-post-2.color-two .read-more-btn:hover {
  color: #202020;
}

.blog-post-2.color-three .blog-content .post-meta li a {
  color: #00C851;
}

.blog-post-2.color-three .blog-content .post-meta li a:hover {
  color: #000;
}

.blog-post-2.color-three .blog-content h3 a:hover {
  color: #00C851;
}

.blog-post-2.color-three .read-more-btn {
  color: #00C851;
}

.blog-post-2.color-three .read-more-btn:hover {
  color: #202020;
}

/*=========  Blog Banner  ==========*/
.page-banner {
  height: 550px;
  position: relative;
}

.breadcrumbs-inner {
  height: 450px;
  position: relative;
}

.breadcrumb-inner-wrap {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.breadcrumb-inner-wrap .breadcrumbs-title {
  font-size: 50px;
  font-weight: 700;
  color: #FFF;
  margin: 0 0 10px;
}

.breadcrumb-inner-wrap .breadcrumbs-holder {
  margin-bottom: 10px;
}

.breadcrumb-inner-wrap .breadcrumbs-holder a {
  color: #FFF;
  font-size: 14px;
}

.breadcrumb-inner-wrap .breadcrumbs-holder a:hover {
  color: #03a9f4;
}

.breadcrumb-inner-wrap .breadcrumbs-holder span {
  font-size: 14px;
  color: #FFF;
}

.breadcrumb-inner-wrap h3 {
  color: #FFF;
  font-size: 20px;
}

/*=========  Blog Standard  ==========*/
.blog-standard {
  padding: 70px 0;
  background: #f8f8f8;
}

.blog-post-standard {
  background: #FFF;
  border-radius: 10px;
  margin-bottom: 30px;
}

.blog-post-standard .blog-thumb img {
  width: 100%;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.blog-post-standard .entry-content {
  padding: 40px 40px 0 40px;
}

.blog-post-standard .entry-content > a {
  color: #333;
}

.blog-post-standard .entry-content > a .blog-post-title {
  font-size: 24px;
  margin-top: 0;
  font-weight: 700;
}

.blog-post-standard .entry-content > a:hover {
  color: #03a9f4;
}

.blog-post-standard .entry-content .page-content {
  margin-bottom: 20px;
}

.blog-post-standard .entry-content .page-content p {
  color: #808285;
}

.blog-post-standard .entry-meta {
  border-top: 1px solid #e2e2e2;
  padding: 15px 0;
}

.blog-post-standard .entry-meta span {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600px;
}

.blog-post-standard .entry-meta span i {
  margin-right: 3px;
  color: #03a9f4;
  font-size: 14px;
}

.blog-post-standard .entry-meta span a {
  color: #333;
  transition: all 0.3s ease-in-out;
}

.blog-post-standard .entry-meta span a:hover {
  color: #03a9f4;
}

.blog-post-standard .entry-meta .post-count {
  float: right;
}

.pagination ul {
  margin: 50px 0 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.pagination ul li {
  display: inline-block;
}

.pagination ul li a {
  font-size: 16px;
  height: 40px;
  width: 40px;
  border: 1px solid #333;
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  margin: 3px;
  transition: all 0.3s ease-in-out;
  color: #333;
}

.pagination ul li a.current, .pagination ul li a:hover {
  background: #03a9f4;
  border-color: #03a9f4;
  color: #FFF;
}

.pagination ul li:first-child {
  float: left;
}

.pagination ul li:first-child a {
  font-size: 18px;
}

.pagination ul li:last-child {
  float: right;
}

.pagination ul li:last-child a {
  font-size: 18px;
}

/*=========  Blog Grid  ==========*/
.blog-grid {
  background: #f8f8f8;
  padding: 70px 0;
}

.blog-grid .blog-post-2 {
  margin-bottom: 30px;
}

/*=========  Blog List  ==========*/
.blog-list {
  padding: 70px 0;
}

.blog-list .blog-post {
  margin-bottom: 30px;
}

/*=========  Blog Single  ==========*/
.blog-single {
  padding: 70px 0;
  background: #f8f8f8;
}

.blog-single .blog-content p {
  margin-bottom: 20px;
}

.gp-blockquote {
  background: #f8f8f8;
  border-left: none;
  padding: 40px;
  background: #03a9f4;
}

.gp-blockquote p {
  font-size: 18px;
  font-weight: 600px;
  line-height: 24px;
  font-style: italic;
  color: #FFF;
}

.gp-blockquote span {
  font-size: 14px;
  display: block;
  position: relative;
  margin-left: 15px;
  color: #f8f8f8;
}

.gp-blockquote span:before {
  position: absolute;
  content: '';
  width: 10px;
  height: 1px;
  left: -15px;
  background: #f8f8f8;
  top: 50%;
  transform: translateY(-50%);
}

.entry-author {
  background: #FFF;
  padding: 30px;
  margin-top: 50px;
  border-radius: 10px;
}

.entry-author .author-avatar {
  height: 100px;
  width: 100px;
  float: left;
}

.entry-author .author-avatar img {
  width: 100%;
  height: author;
  border-radius: 50%;
}

.entry-author .author-description {
  margin-left: 120px;
}

.entry-author .author-description .author-name {
  font-size: 17px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}

.entry-author .author-description .author-biographical-info {
  margin-bottom: 10px;
}

.entry-author .author-description .author-biographical-info p {
  color: #444;
  margin-bottom: 0;
  line-height: 26px;
  font-size: 15px;
}

.entry-author .author-description .author-social-networks {
  margin: 0;
  padding: 0;
  list-style: none;
}

.entry-author .author-description .author-social-networks li {
  display: inline-block;
}

.entry-author .author-description .author-social-networks li a {
  color: #000;
  text-decoration: none;
  display: block;
  margin-right: 5px;
  font-size: 16px;
}

.entry-author .author-description .author-social-networks li a:hover {
  color: #03a9f4;
}

.gp-post-info-bottom {
  padding-top: 20px;
  margin-top: 30px;
  border-top: 1px solid #c4c4c4;
}

.gp-post-info-bottom .tagcloud a {
  font-size: 10px;
  line-height: 25px;
}

.gp-post-info-bottom .tagcloud span {
  float: left;
  margin-right: 10px;
  font-weight: 700;
}

.gp-blog-share-link {
  margin: 0;
  padding: 0;
  list-style: none;
}

.gp-blog-share-link li {
  display: inline-block;
  margin-right: 5px;
}

.gp-blog-share-link li a {
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.gp-blog-share-link li a.facebook-bg {
  background: #305fb3;
}

.gp-blog-share-link li a.twitter-bg {
  background: #5eaade;
}

.gp-blog-share-link li a.google-plus-bg {
  background: #d7482a;
}

.gp-blog-share-link li a.pinterest-bg {
  background: #bd081c;
}

.gp-blog-share-link li a:hover {
  background: #111;
  color: #FFF;
}

/* Releted Post */
.releted-post {
  margin-top: 50px;
}

.releted-post .related-post-title {
  font-size: 22px;
  font-weight: 700;
  color: #333;
  margin-bottom: 30px;
}

.releted-post .blog-post-2 .blog-content {
  padding: 15px;
}

.releted-post .blog-post-2 .blog-content .post-meta {
  margin: 0;
}

.releted-post .blog-post-2 .blog-content .post-meta li {
  font-size: 12px;
}

.releted-post .blog-post-2 .blog-content h3 {
  line-height: 20px;
  font-size: 18px;
}

.releted-post .blog-post-2 .blog-content p {
  margin-bottom: 0;
}

/* Blog Full Width */
.blog-fullwidth {
  width: 83%;
  margin: 0 auto;
}

/* Comment List */
#comments {
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid #ccc;
}

#comments .gp-form-heading {
  font-size: 24px;
  font-weight: 700;
  color: #333;
}

#comments .commentlist {
  margin: 50px 0 0;
  padding: 0;
  list-style: none;
}

#comments .commentlist > li.comment {
  padding-bottom: 45px;
  margin-bottom: 45px;
  border-bottom: 1px solid #e7e7e7;
}

#comments .commentlist > li.comment .comment {
  margin-top: 50px;
}

#comments .commentlist .comment div.clearfix .avatar-box {
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
  float: left;
  border-radius: 50%;
}

#comments .commentlist .comment div.clearfix .avatar-box img {
  width: 100%;
  height: auto;
}

#comments .commentlist .comment div.clearfix .comment-content {
  margin-left: 100px;
}

#comments .commentlist .comment div.clearfix .comment-content .comment-author {
  margin-bottom: 10px;
}

#comments .commentlist .comment div.clearfix .comment-content .comment-author .entry-meta {
  color: #7b7b7b;
}

#comments .commentlist .comment div.clearfix .comment-content .comment-author .author {
  float: left;
  font-size: 15px;
  line-height: inherit;
  margin-right: 20px;
  color: #111;
  font-size: 16px;
  font-weight: 700;
}

#comments .commentlist .comment div.clearfix .comment-content .comment-author .reply {
  float: right;
  line-height: inherit;
}

#comments .commentlist .comment div.clearfix .comment-content .comment-author .reply .comment-reply-link {
  font-size: 12px;
  color: #444;
  text-decoration: none;
  background: #ececec;
  display: block;
  padding: 3px 10px;
  border-radius: 30px;
}

#comments .commentlist .comment div.clearfix .comment-content .comment-author .reply .comment-reply-link:hover {
  background: #333;
  color: #FFF;
}

#comments .commentlist .comment ul {
  margin-left: 70px;
  padding: 0;
  list-style: none;
}

/* Comment Form */
#respond {
  margin-top: 50px;
}

#respond .gp-form-heading {
  font-size: 26px;
  font-weight: 700;
}

#respond form > *.comment-form-author, #respond form > *.comment-form-email, #respond form > *.comment-form-url {
  width: 33.33%;
  float: left;
  border-radius: 30px;
}

#respond form > *.comment-form-author input, #respond form > *.comment-form-email input, #respond form > *.comment-form-url input {
  border-radius: 5px;
  outline: none;
  padding: 12px 20px;
  background: #FFF;
  width: 100%;
}

#respond form > * {
  padding: 0 10px;
}

#respond form > .comment-form-comment textarea {
  border-radius: 10px;
  outline: none;
  padding: 20px;
  background: #FFF !important;
  width: 100%;
}

#respond .form-submit .gp-btn {
  display: block;
  max-width: 250px;
  width: 100%;
  margin: 0 auto;
  background: #333;
  border: none;
  transition: all 0.3s ease-in-out;
  color: #FFF;
  border-radius: 30px;
  outline: none;
}

#respond .form-submit .gp-btn:hover {
  background: #03a9f4;
  color: #FFF;
}

/*=========  Widgets  ==========*/
.widget {
  margin-bottom: 40px;
}

.widget .widget-title {
  font-size: 22px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
}

.widget .widget-title:before {
  content: '';
  position: absolute;
  height: 3px;
  width: 40px;
  border: 1px solid #444;
  background: #FFF;
  border-radius: 5px;
  left: 0;
  bottom: 1px;
  z-index: 50;
}

.widget .widget-title:after {
  position: absolute;
  content: '';
  width: 40%;
  height: 1px;
  background: #444;
  left: 0;
  bottom: 2px;
}

/* Widget Search */
.widget_search .search-form {
  position: relative;
  display: flex;
  border-radius: 30px;
  background: #FFF;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
}

.widget_search .search-form label {
  margin-bottom: 0;
}

.widget_search .search-form .search-field {
  background: transparent;
  border: none;
  margin-bottom: 0;
  outline: none;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 400;
}

.widget_search .search-form .search-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}

.widget_search .search-form .search-submit {
  width: 50px;
  background-color: transparent;
  text-indent: 100px;
  margin-bottom: 0;
  border: none;
  outline: none;
}

.widget_search .search-form:hover .search-icon {
  color: #03a9f4;
}

/* Widget Recent Post */
.widget_recent_entries .recent-post {
  border-color: #e4e4e4;
}

.widget_recent_entries .recent-post a {
  text-decoration: none;
}

.widget_recent_entries .recent-post .post-content h3 {
  color: #333;
  font-weight: 600;
  font-size: 16px;
}

.widget_recent_entries .recent-post .post-content .post-meta p {
  color: #848484;
}

/* Widget categories */
.gp_custom_menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.gp_custom_menu li {
  margin-bottom: 10px;
}

.gp_custom_menu li a {
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  display: block;
  line-height: 20px;
  color: #444;
  display: block;
}

.gp_custom_menu li a span {
  float: right;
}

.gp_custom_menu li a:hover {
  color: #03a9f4;
}

/* Widget Tags */
.tagcloud a {
  position: relative;
  float: left;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 7px;
  margin-right: 7px;
  background: #e7e7e7;
  border-radius: 4px;
  line-height: 30px;
  padding: 0px 15px;
  transform: perspective(0) translateY(0);
  transition: all .3s ease-in-out;
  color: #3a3a3a;
  text-transform: uppercase;
  font-weight: 500;
}

.tagcloud a:hover {
  background: #fff;
  transform: translateY(-2px);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
}

/* Page Pagination */
.page-pagination {
  text-align: center;
  margin-top: 50px;
}

.page-pagination .pagination {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-pagination .pagination li {
  display: inline-block;
}

.page-pagination .pagination li a {
  display: block;
  height: 35px;
  width: 35px;
  border: 1px solid #c1c1c1;
  color: #c1c1c1;
  border-radius: 50%;
  margin: 0 3px;
  background-color: transparent;
}

.page-pagination .pagination li a:hover, .page-pagination .pagination li a.active {
  background: #03a9f4;
  border-color: #03a9f4;
  color: #FFF;
}

@media screen and (max-width: 768px) {
  .blog-post, .blog-post-2 {
    max-width: 500px;
    margin: 0 auto 20px;
  }
  .blog-post.thumb-left .blog-thumb, .blog-post.thumb-right .blog-thumb, .blog-post-2.thumb-left .blog-thumb, .blog-post-2.thumb-right .blog-thumb {
    float: none;
  }
  .blog-post.thumb-left .blog-content, .blog-post.thumb-right .blog-content, .blog-post-2.thumb-left .blog-content, .blog-post-2.thumb-right .blog-content {
    margin: 0;
  }
}

/*--------------------------------------------------------------
  ##  Testimonial
  --------------------------------------------------------------*/
#testimonial {
  padding: 100px 0;
  position: relative;
}

#testimonial-two-colorfull {
  background: #ffe200;
}

.swiper-container {
  width: 90%;
  position: relative;
}

.gp-testimonial .gp-testmonial-content {
  padding: 35px 35px 30px 90px;
  background: #f2f5f6;
  position: relative;
  margin-bottom: 40px;
}

.gp-testimonial .gp-testmonial-content p {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 30px;
}

.gp-testimonial .gp-testmonial-content:after {
  content: "";
  position: absolute;
  left: 22px;
  bottom: -20px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #f2f5f6;
  background: none;
}

.gp-testimonial .gp-testmonial-content .quote-icon {
  font-size: 90px;
  font-family: 'Catamaran', sans-serif;
  position: absolute;
  left: 38px;
  line-height: 1px;
  top: 83px;
  color: #03a9f4;
}

.gp-testimonial .gp-testimonial-author {
  position: relative;
}

.gp-testimonial .gp-testimonial-author .testi-thumb {
  float: left;
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.gp-testimonial .gp-testimonial-author .testi-thumb img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.gp-testimonial .gp-testimonial-author .author-details {
  position: absolute;
  left: 110px;
  top: 50%;
  transform: translateY(-50%);
}

.gp-testimonial .gp-testimonial-author .author-details h4 {
  margin: 0;
  font-size: 18px;
  color: #000;
  font-weight: 600;
}

.gp-testimonial .gp-testimonial-author .author-details span {
  font-size: 13px;
  font-style: italic;
  color: #444;
}

.gp-testimonial-wrapper {
  position: relative;
}

.gp-testimonial-wrapper .slider-control {
  position: absolute;
  width: 100%;
  top: 50%;
}

.gp-testimonial-wrapper .slider-control .testi-prev, .gp-testimonial-wrapper .slider-control .testi-next {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #f2f5f6;
  text-align: center;
  position: absolute;
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.gp-testimonial-wrapper .slider-control .testi-prev i, .gp-testimonial-wrapper .slider-control .testi-next i {
  font-size: 30px;
  line-height: 48px;
}

.gp-testimonial-wrapper .slider-control .testi-prev:hover, .gp-testimonial-wrapper .slider-control .testi-next:hover {
  background: #03a9f4;
}

.gp-testimonial-wrapper .slider-control .testi-prev:hover i, .gp-testimonial-wrapper .slider-control .testi-next:hover i {
  color: #FFF;
}

.gp-testimonial-wrapper .slider-control .testi-prev {
  left: 0;
  margin-right: 3px;
}

.gp-testimonial-wrapper .slider-control .testi-next {
  right: 0;
}

.gp-testimonial-wrapper .slider-control .testi-next i {
  margin-left: 3px;
}

.swiper-pagination {
  margin-top: 25px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-pagination .swiper-pagination-bullet {
  height: 14px;
  width: 14px;
  margin-right: 3px;
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #03a9f4;
}

.swiper-pagination.gradient .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
}

.swiper-pagination.colorfull .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #00C851;
}

.section-dark .gp-testimonial .gp-testimonial-author .author-details .testi-author-name {
  color: #FFF;
}

.section-dark .gp-testimonial .gp-testimonial-author .author-details span {
  color: #b5b5b5;
}

/* Testimonial Two */
.gp-testimonial-wrapper-two .swiper-container {
  width: 100%;
}

#testimonial-two {
  background: #f8f8f8;
  padding: 100px 0;
}

.gp-testimonial-two {
  background: #FFF;
  padding: 50px;
  border-radius: 10px;
}

.gp-testimonial-two .gp-testmonial-content {
  margin-bottom: 30px;
}

.gp-testimonial-two .gp-testmonial-content p {
  font-size: 15px;
  line-height: 26px;
}

.gp-testimonial-two .gp-testimonial-author {
  position: relative;
}

.gp-testimonial-two .gp-testimonial-author .testi-thumb {
  float: left;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.gp-testimonial-two .gp-testimonial-author .testi-thumb img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.gp-testimonial-two .gp-testimonial-author .author-details {
  position: absolute;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
}

.gp-testimonial-two .gp-testimonial-author .author-details h4 {
  margin: 0;
  font-size: 18px;
  color: #000;
  font-weight: 600;
}

.gp-testimonial-two .gp-testimonial-author .author-details span {
  font-size: 13px;
  font-style: italic;
  color: #444;
}

.gp-testimonial-two .gp-testimonial-author .author-details.color-one h4 {
  color: #D81B60;
}

.gp-testimonial-two .gp-testimonial-author .author-details.color-two h4 {
  color: #9c27b0;
}

.gp-testimonial-two .gp-testimonial-author .author-details.color-three h4 {
  color: #00C851;
}

.gp-testimonial-two .gp-testimonial-author .author-details.color-four h4 {
  color: #f96332;
}

@media screen and (max-width: 500px) {
  .gp-testimonial-wrapper .slider-control {
    display: none;
  }
  .gp-testimonial .gp-testmonial-content {
    padding: 25px 12px 30px 50px;
  }
  .gp-testimonial .gp-testmonial-content .quote-icon {
    left: 12px;
    top: 75px;
  }
}

/*--------------------------------------------------------------
  ##  Skills
  --------------------------------------------------------------*/
#skills {
  padding: 100px 0;
}

.skill-bar, .skill-bar-two {
  text-align: left;
  margin: 50px 0 0 0;
  padding-left: 0;
  list-style: none;
  max-width: 600px;
}

.skill-bar li.progress, .skill-bar-two li.progress {
  border-radius: 0;
  height: 18px;
  margin-bottom: 40px;
  overflow: visible;
  box-shadow: none;
  background: #eee;
  margin-bottom: 50px;
  border-radius: 20px;
}

.skill-bar li .skill-bar-wrap, .skill-bar-two li .skill-bar-wrap {
  margin-bottom: 20px;
  box-shadow: none;
}

.skill-bar li .progress-bar, .skill-bar-two li .progress-bar {
  height: 18px;
  width: 0;
  display: block;
  text-align: right;
  overflow: visible !important;
  position: relative;
  box-shadow: none;
  border-radius: 20px;
  opacity: 1;
  background: #03a9f4;
}

.skill-bar li .progress-bar:after, .skill-bar-two li .progress-bar:after {
  position: absolute;
  content: '';
  top: -8px;
  right: 20px;
  content: '';
  width: 1px;
  height: 6px;
  background: #a5a5a5;
}

.skill-bar li .skill-name, .skill-bar li .percentage, .skill-bar-two li .skill-name, .skill-bar-two li .percentage {
  color: #000;
  position: relative;
  top: -25px;
  font-size: 16px;
}

.skill-bar li .skill-name, .skill-bar-two li .skill-name {
  position: absolute;
  left: 0;
  font-weight: 700;
  font-size: 16px;
}

.skill-bar-two li .progress-bar {
  height: 5px;
}

.skill-bar-two li.progress {
  height: 5px;
}

.panel-group .panel {
  margin-bottom: 10px;
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.panel-group .panel-heading {
  padding: 0;
  background: none;
  border: 0;
}

.panel-group .panel-heading .panel-title {
  color: #303030;
  font-size: 16px;
  line-height: 1;
  border-radius: 0;
  transition: all 150ms ease-in-out;
  background: rgba(30, 30, 30, 0.1);
}

.panel-group .panel-title > a {
  display: block;
  padding: 14px 20px;
  color: #303030;
  font-size: 14px;
  font-weight: 600;
}

.panel-group .panel-active a,
.panel-group .panel-title > a:hover,
.panel-group .panel-title > a:focus {
  background: #03a9f4;
  border: 0;
  color: #fff;
  position: relative;
  text-decoration: none;
}

.panel-group .panel-active a:after {
  left: 25px;
}

.panel-default > .panel-heading + .panel-collapse .panel-body {
  border: 0;
}

.panel-body {
  padding: 20px;
  background: #f5f5f5;
}

.acc-border-radius .panel-group .panel-title,
.acc-border-radius .panel-group .panel-active a,
.acc-border-radius .panel-group .panel-title > a:hover,
.acc-border-radius .panel-group .panel-title > a:focus {
  border-radius: 30px;
}

.acc-border-radius .panel-body {
  background: transparent;
}

section#skills-two {
  padding: 0;
}

section#skills-two #skill-bar {
  padding-left: 50px;
}

section#skills-colorfull {
  padding: 0;
}

section#skills-colorfull #skill-bar {
  padding-right: 50px;
}

.skill-bar.gradients li .progress-bar {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
}

.skill-bar li .progress-bar.color-one {
  background: #D81B60;
}

.skill-bar li .progress-bar.color-two {
  background: #9c27b0;
}

.skill-bar li .progress-bar.color-three {
  background: #00C851;
}

.skill-bar li .progress-bar.color-four {
  background: #f96332;
}

.flex-wrap {
  display: flex;
  align-items: center;
}

.skills-thumb img {
  width: 100%;
}

/*--------------------------------------------------------------
  ##  Team
  --------------------------------------------------------------*/
#team {
  padding: 100px 0;
}

#team-colorfull {
  background: #D81B60;
}

.team-member {
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.team-member .member-thumb {
  position: relative;
  overflow: hidden;
}

.team-member .member-thumb img {
  width: 100%;
  height: auto;
}

.team-member .member-thumb .overlay {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(3, 169, 244, 0.7);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.team-member .member-thumb .overlay .profile-link {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.team-member .member-thumb .overlay .profile-link li {
  display: inline-block;
  margin: 0 2px;
}

.team-member .member-thumb .overlay .profile-link li a {
  height: 35px;
  width: 35px;
  background: #FFF;
  display: inline-block;
  border-radius: 50%;
  color: #000;
  transition: all 0.3s ease-in-out;
}

.team-member .member-thumb .overlay .profile-link li a i {
  line-height: 35px;
}

.team-member .member-thumb .overlay .profile-link li a:hover {
  color: #03a9f4;
}

.team-member .team-details {
  text-align: center;
  padding: 20px;
}

.team-member .team-details .name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #000;
}

.team-member .team-details .position {
  font-size: 15px;
  color: #444;
  line-height: 16px;
  display: block;
  margin-bottom: 10px;
}

.team-member:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.team-member:hover .member-thumb .overlay {
  opacity: 1;
}

.team-member:hover .member-thumb .overlay .profile-link {
  bottom: 10px;
}

#team-two {
  padding: 100px 0;
  background: #f5f5f5;
}

.team-member-two {
  background: #FFF;
  position: relative;
  border: 1px solid #e8e8e8;
  box-shadow: 0 10px 20px -12px rgba(0, 0, 0, 0.07), 0 3px 20px 0px rgba(0, 0, 0, 0.05), 0 8px 10px -5px rgba(0, 0, 0, 0.09);
}

.team-member-two .member-thumb {
  position: relative;
  overflow: hidden;
}

.team-member-two .member-thumb img {
  width: 100%;
  transition: all 0.5s ease-in-out;
  transform: scale(1);
}

.team-member-two .member-thumb .team-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.team-member-two .member-thumb .team-overlay:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  content: '';
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
}

.team-member-two .team-details {
  padding: 20px;
}

.team-member-two .team-details .name {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 22px;
  color: #000;
}

.team-member-two .team-details .position {
  font-size: 12px;
  display: block;
  margin-bottom: 15px;
  color: #808080;
}

.team-member-two .team-details .profile-link {
  margin: 0;
  padding: 0;
  list-style: none;
}

.team-member-two .team-details .profile-link li {
  display: inline-block;
  margin-right: 10px;
}

.team-member-two .team-details .profile-link li a {
  color: #000;
  transition: all 0.3s ease-in-out;
}

.team-member-two .team-details .profile-link li a:hover {
  color: #03a9f4;
}

.team-member-two:hover .member-thumb img {
  transform: scale(1.1);
}

.team-member-two:hover .member-thumb .team-overlay:after {
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
}

.team-member-three {
  position: relative;
}

.team-member-three .member-thumb img {
  width: 100%;
  transition: all 0.5s ease-in-out;
  transform: scale(1);
}

.team-member-three .team-details-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  top: 12px;
  left: 12px;
  bottom: 12px;
  right: 12px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transform: scale(0.5);
}

.team-member-three .team-details-wrap .overlay {
  background: rgba(3, 169, 244, 0.9);
}

.team-member-three .team-details-wrap .team-details {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.team-member-three .team-details-wrap .team-details .name, .team-member-three .team-details-wrap .team-details .position {
  color: #FFF;
  transform: translateY(10px);
  transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  opacity: 0;
  visibility: hidden;
}

.team-member-three .team-details-wrap .team-details .name {
  text-transform: uppercase;
}

.team-member-three .team-details-wrap .team-details .position {
  margin-bottom: 30px;
  display: block;
}

.team-member-three .team-details-wrap .team-details .profile-link {
  margin: 0;
  padding: 0;
  list-style: none;
}

.team-member-three .team-details-wrap .team-details .profile-link li {
  display: inline-block;
  margin-right: 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  transform: translateY(8px) scale(0.8);
}

.team-member-three .team-details-wrap .team-details .profile-link li a {
  color: #FFF;
  transition: all 0.3s ease-in-out;
  font-size: 20px;
}

.team-member-three .team-details-wrap .team-details .profile-link li a:hover {
  color: #202020;
}

.team-member-three:hover .team-details-wrap {
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.4);
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.team-member-three:hover .team-details-wrap .team-details .name, .team-member-three:hover .team-details-wrap .team-details .position {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.team-member-three:hover .team-details-wrap .team-details .name {
  transition-delay: 0.25s;
}

.team-member-three:hover .team-details-wrap .team-details .position {
  transition-delay: 0.33s;
}

.team-member-three:hover .team-details-wrap .team-details .profile-link li {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.team-member-three:hover .team-details-wrap .team-details .profile-link li:nth-child(1) {
  transition-delay: .07692s;
}

.team-member-three:hover .team-details-wrap .team-details .profile-link li:nth-child(2) {
  transition-delay: .15385s;
}

.team-member-three:hover .team-details-wrap .team-details .profile-link li:nth-child(3) {
  transition-delay: .23077s;
}

.team-member-three:hover .team-details-wrap .team-details .profile-link li:nth-child(4) {
  transition-delay: .3092s;
}

.team-member-three.gradiant .team-details-wrap .overlay {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
  opacity: 0.9;
}

.team-member-three.color-one .team-details-wrap .overlay {
  background: rgba(216, 27, 96, 0.9);
}

.team-member-three.color-two .team-details-wrap .overlay {
  background: rgba(156, 39, 176, 0.9);
}

.team-member-three.color-three .team-details-wrap .overlay {
  background: rgba(0, 200, 81, 0.9);
}

.team-member-three.color-four .team-details-wrap .overlay {
  background: rgba(249, 99, 50, 0.9);
}

@media screen and (max-width: 768px) {
  .team-member, .team-member-two {
    max-width: 400px;
    margin: 0 auto 20px;
  }
}

/*--------------------------------------------------------------
  ##  Call To Action
  --------------------------------------------------------------*/
#call-to-action, #call-to-action-two {
  background: #03a9f4;
}

#call-to-action-two-colorfull, #call-to-action-colorfull {
  background: #00C851;
}

#call-to-action-two-gredient, #call-to-action-gredient {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
}

.cta-text {
  width: 75%;
  float: left;
}

.cta-text h3 {
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  color: #FFF;
}

.cta-text p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  color: #FFF;
}

#call-to-action-two p, #call-to-action-two-colorfull p, #call-to-action-two-gredient p, #call-to-action-gredient p {
  max-width: 650px;
  margin: 0 auto;
  font-size: 16px;
  margin-bottom: 20px;
}

.cta-btncontainer, .cta-btncontainer-two {
  width: 25%;
  float: left;
}

.cta-btncontainer .gp-btn, .cta-btncontainer-two .gp-btn {
  border-radius: 30px;
  padding: 15px 40px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  margin: 15px auto 0;
  text-align: center;
  margin-right: 18px;
  text-transform: uppercase;
  font-weight: 500;
}

.cta-btncontainer .gp-btn.btn-dark:hover, .cta-btncontainer-two .gp-btn.btn-dark:hover {
  background: #fff;
  color: #202020;
}

.cta-text-two {
  text-align: center;
  margin-bottom: 20px;
}

.cta-text-two h3 {
  font-size: 34px;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 25px;
}

.cta-text-two p {
  color: #FFF;
  font-size: 20px;
  margin-bottom: 0;
}

.cta-btncontainer-two {
  text-align: center;
  width: 100%;
  float: none;
}

@media screen and (max-width: 768px) {
  .cta-text h3 {
    font-size: 24px;
  }
  .cta-text p {
    font-size: 14px;
  }
  .cta-text-two h3 {
    font-size: 28px;
    margin-bottom: 15px;
  }
  .cta-btncontainer .gp-btn, .cta-btncontainer-two .gp-btn {
    padding: 12px 22px;
    margin-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .cta-text-two h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cta-text-two p {
    font-size: 13px !important;
  }
  .cta-text {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
  .cta-text h3 {
    font-size: 20px;
  }
  .cta-btncontainer, .cta-btncontainer-two {
    width: 100%;
    text-align: center;
  }
}

/*--------------------------------------------------------------
  ##  Countup
  --------------------------------------------------------------*/
#countup, #countup-two {
  position: relative;
}

.counter-box .count i, .counter-box-two .count i {
  font-size: 45px;
  margin-bottom: 10px;
  color: #FFF;
  display: block;
}

.counter-box .count h2, .counter-box-two .count h2 {
  font-size: 50px;
  font-weight: 700;
  color: #03a9f4;
}

.counter-box .count p, .counter-box-two .count p {
  color: #FFF;
  font-size: 16px;
}

.counter-box.color-one, .counter-box-two.color-one {
  padding: 30px 20px;
  border: 1px solid #D81B60;
  box-shadow: 0 0px 60px rgba(29, 27, 27, 0.2);
}

.counter-box.color-one .count i, .counter-box-two.color-one .count i {
  color: #D81B60;
}

.counter-box.color-one .count h2, .counter-box-two.color-one .count h2 {
  color: #D81B60;
}

.counter-box.color-one .count p, .counter-box-two.color-one .count p {
  color: #000;
}

.counter-box.color-two, .counter-box-two.color-two {
  padding: 30px 20px;
  border: 1px solid #9c27b0;
  box-shadow: 0 0px 60px rgba(29, 27, 27, 0.2);
}

.counter-box.color-two .count i, .counter-box-two.color-two .count i {
  color: #9c27b0;
}

.counter-box.color-two .count h2, .counter-box-two.color-two .count h2 {
  color: #9c27b0;
}

.counter-box.color-two .count p, .counter-box-two.color-two .count p {
  color: #000;
}

.counter-box.color-three, .counter-box-two.color-three {
  padding: 30px 20px;
  border: 1px solid #00C851;
  box-shadow: 0 0px 60px rgba(29, 27, 27, 0.2);
}

.counter-box.color-three .count i, .counter-box-two.color-three .count i {
  color: #00C851;
}

.counter-box.color-three .count h2, .counter-box-two.color-three .count h2 {
  color: #00C851;
}

.counter-box.color-three .count p, .counter-box-two.color-three .count p {
  color: #000;
}

.counter-box.color-four, .counter-box-two.color-four {
  padding: 30px 20px;
  border: 1px solid #f96332;
  box-shadow: 0 0px 60px rgba(29, 27, 27, 0.2);
}

.counter-box.color-four .count i, .counter-box-two.color-four .count i {
  color: #f96332;
}

.counter-box.color-four .count h2, .counter-box-two.color-four .count h2 {
  color: #f96332;
}

.counter-box.color-four .count p, .counter-box-two.color-four .count p {
  color: #000;
}

.counter-box-two .count i {
  color: #202020;
}

.counter-box-two .count p {
  color: #202020;
}

@media screen and (max-width: 768px) {
  .counter-box {
    max-width: 450px;
    margin: 0 auto 30px;
  }
}

/*--------------------------------------------------------------
  ##  Newsletter
  --------------------------------------------------------------*/
#newsletter {
  padding: 100px 0;
  background: #f5f5f5;
  position: relative;
}

.input-group input, .input-group textarea, .input-group select {
  margin: 0;
  border-radius: 4px 0 0 4px;
  border: none;
  padding: 15px 25px;
  background: transparent;
  color: #202020;
  width: 100%;
}

.input-group input:focus, .input-group textarea:focus, .input-group select:focus {
  outline: none;
}

.newsletter-form .input-group {
  border: 1px solid #fff;
  border-radius: 40px;
  padding: 3px;
  background: #FFF;
}

.input-group-btn .btn {
  border-radius: 0 4px 4px 0;
}

.input-group-btn .btn.btn-large {
  line-height: 2px;
  height: 62px;
  padding: 15px 25px !important;
}

.subscribe-title {
  font-size: 34px;
  font-weight: 800;
  margin-bottom: 20px;
  color: #FFF;
}

.sub-content {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 30px;
  color: #f5f5f5;
}

.center-col {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.input-group-btn .btn.btn-large {
  line-height: 2px;
  height: 55px;
  padding: 12px 50px !important;
  color: #fff;
  font-size: 30px;
  background: #03a9f4;
  transition: all 0.3s ease-in-out;
  border-radius: 40px;
}

.input-group-btn .btn.btn-large:hover {
  background: #333;
}

/*--------------------------------------------------------------
  ##  Contact
  --------------------------------------------------------------*/
#contact, #contact-gredient, #contact-colorfull, #contact-form-three {
  background: #f8f8f8;
}

.contact-title {
  text-align: center;
  margin-bottom: 50px;
}

.contact-title h2 {
  color: #000;
}

.contact-info {
  text-align: center;
  background: #FFF;
  padding: 20px 40px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.01);
}

.con-details {
  border-bottom: 1px solid #eaeaea;
  margin: 0;
  padding: 10px 0;
}

.con-details .con-icon {
  height: 60px;
  width: 60px;
  text-align: center;
  margin: 0 auto;
  background: #2ebcfc;
  border-radius: 50%;
  margin-bottom: 10px;
}

.con-details .con-icon i {
  line-height: 60px;
  font-size: 30px;
  color: #FFF;
}

.con-details .con-icon.color-one {
  background: #D81B60;
}

.con-details .con-icon.color-two {
  background: #9c27b0;
}

.con-details .con-icon.color-three {
  background: #00C851;
}

.con-details p {
  color: #444;
}

.con-details:last-child {
  border-bottom: none;
}

.contact-form-area {
  padding: 0;
}

#gp-contact-form .form-group, #gp-contact-form-two .form-group, #gp-contact-form-three .form-group, #gp-contact-form-four .form-group {
  margin-bottom: 20px;
}

#gp-contact-form .form-control, #gp-contact-form-two .form-control, #gp-contact-form-three .form-control, #gp-contact-form-four .form-control {
  height: 55px;
  border: none;
  background: #FFF;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  font-size: 16px;
  border-radius: 30px;
  padding: 10px 25px;
}

#gp-contact-form textarea.form-control, #gp-contact-form-two textarea.form-control, #gp-contact-form-three textarea.form-control, #gp-contact-form-four textarea.form-control {
  height: 200px;
  padding: 20px;
  margin-bottom: 30px;
}

#gp-contact-form .gp-btn, #gp-contact-form-two .gp-btn, #gp-contact-form-three .gp-btn, #gp-contact-form-four .gp-btn {
  display: block;
  max-width: 300px;
  width: 100%;
  border-radius: 30px;
  border: none;
  background: #333;
  color: #FFF;
  transition: all 0.3s ease-in-out;
  outline: none;
  margin: 0 auto;
}

#gp-contact-form .gp-btn:hover, #gp-contact-form-two .gp-btn:hover, #gp-contact-form-three .gp-btn:hover, #gp-contact-form-four .gp-btn:hover {
  background: #03a9f4;
}

#gp-contact-form .gp-btn.btn-border-one, #gp-contact-form-two .gp-btn.btn-border-one, #gp-contact-form-three .gp-btn.btn-border-one, #gp-contact-form-four .gp-btn.btn-border-one {
  background: transparent;
  color: #D81B60;
  border: 2px solid #D81B60;
}

#gp-contact-form .gp-btn.btn-border-one:hover, #gp-contact-form-two .gp-btn.btn-border-one:hover, #gp-contact-form-three .gp-btn.btn-border-one:hover, #gp-contact-form-four .gp-btn.btn-border-one:hover {
  background: #D81B60;
  color: #FFF;
  border-color: color-1;
}

#gp-contact-form .gp-btn.gredient {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
  color: #FFF;
}

.get-in-touch {
  padding: 30px;
}

.get-in-touch h4 {
  font-size: 16px;
  line-height: 24px;
  color: #444;
  font-weight: 500px;
  margin-bottom: 20px;
}

#contact-two {
  padding-bottom: 0;
  background: #f8f8f8;
}

.gp-contact-form-two {
  margin: 0 auto 150px;
  max-width: 800px;
}

#map {
  position: relative;
}

.contact-details-two {
  position: absolute;
  top: 0;
  max-width: 800px;
  width: 100%;
  z-index: 300;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 15px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  padding: 40px 0;
}

.contact-details-two .contact-info-two {
  text-align: center;
  display: inline-block;
  width: 33.33%;
  float: left;
  border-right: 1px solid #f5f5f5;
}

.contact-details-two .contact-info-two:last-child {
  border-right: none;
}

.contact-details-two .contact-info-two i {
  font-size: 40px;
  display: block;
  color: #03a9f4;
  margin-bottom: 15px;
}

.contact-details-two .contact-info-two p {
  font-size: 15px;
  margin-bottom: 0;
  line-height: 24px;
}

.gmap3-area {
  height: 500px;
}

.con-details.gredient .con-icon {
  background: linear-gradient(180deg, #4772d9, #6d47d9);
}

/*--------------------------------------------------------------
  ##  Product
  --------------------------------------------------------------*/
.product-banner {
  height: 50vh;
  position: relative;
}

.product-banner .breadcrumbs-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Shop */
#shop, #shop-nosidebar, #shop-sidebar {
  padding: 100px 0;
  background: #fafafa;
}

.product-count {
  margin-bottom: 50px;
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.form-select {
  border: 2px solid #eee;
  background-color: #fff;
  position: relative;
  border-radius: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
}

.form-select select {
  vertical-align: top;
  appearance: none;
  border: 0;
  margin: 0;
  border-radius: 30px;
  outline: none;
}

.form-control {
  height: 50px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 50px;
  border: 2px solid #eee;
  border-radius: 0;
  box-shadow: none;
  transition: all .3s ease;
  outline: none;
}

.form-select:after {
  font: normal normal normal 15px/1 FontAwesome;
  content: "\f107";
  pointer-events: none;
  position: absolute;
  top: 1.8rem;
  right: 2rem;
}

.shop-product {
  text-align: center;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);
}

.shop-product .product-photo {
  position: relative;
  overflow: hidden;
}

.shop-product .product-photo img {
  width: 100%;
  height: auto;
  transition: all 0.3s ease-in-out;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.shop-product .product-photo .alt-thumb {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.shop-product .product-photo .gp-cart-btn {
  padding: 6px 15px;
  background: #000;
  border-radius: 30px;
  color: #FFF;
  font-size: 11px;
  transition: all 0.5s ease-in-out;
  position: absolute;
  left: 50%;
  bottom: -40px;
  transform: translateX(-50%);
  z-index: 100;
}

.shop-product .product-photo .gp-cart-btn i {
  margin-right: 5px;
}

.shop-product .product-photo .gp-cart-btn:hover {
  background: #03a9f4;
}

.shop-product .product-photo .sold-out {
  display: block;
  position: absolute;
  padding: 6px 22px;
  background: #333;
  color: #FFF;
  left: 52%;
  top: 50%;
  border-radius: 30px;
  font-size: 12px;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.shop-product .product-photo .new-pro {
  padding: 3px 12px;
  font-size: 12px;
  background: #03a9f4;
  color: #FFF;
  position: absolute;
  left: 20px;
  top: 20px;
  border-radius: 30px;
  text-transform: uppercase;
}

.shop-product .product-photo .sale {
  padding: 3px 12px;
  font-size: 12px;
  text-transform: uppercase;
  font-size: 12px;
  display: block;
  background: #FFF;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 5px;
  color: #000;
  letter-spacing: 1px;
}

.shop-product .product-photo .sale span {
  color: #03a9f4;
}

.shop-product .product-details {
  padding: 20px;
}

.shop-product .product-details .product-name {
  font-size: 16px;
  font-weight: 600;
}

.shop-product .product-details .product-price {
  font-size: 15px;
  font-weight: 500;
  color: #444;
  margin-bottom: 10px;
}

.shop-product .product-details .price del {
  margin-right: 20px;
}

.shop-product .product-details .price del .amount {
  color: #444;
}

.shop-product .product-details .price ins {
  text-decoration: none;
}

.shop-product .product-details .price ins .amount {
  color: #03a9f4;
}

.shop-product .product-details .product-rang {
  margin: 0;
  padding: 0;
  list-style: none;
}

.shop-product .product-details .product-rang li {
  display: inline-block;
  color: #03a9f4;
}

.shop-product:hover .product-photo .alt-thumb {
  opacity: 1;
}

.shop-product:hover .product-photo .gp-cart-btn {
  bottom: 20px;
}

/*=========  Single Product  ==========*/
.single-product {
  padding: 100px 0;
  background: #fafafa;
}

.column-9 {
  width: 80%;
  margin: 0 auto;
}

.single-product-wrapper {
  padding-bottom: 50px;
  border-bottom: 1px solid #e8e8e8;
}

.single-product-wrapper .product-title {
  font-size: 28px;
  font-weight: 700;
  color: #333;
}

.single-product-wrapper .product-sub-title {
  font-size: 18px;
  font-weight: 600;
  color: #696969;
  margin-bottom: 20px;
}

.star-rating {
  margin: 0 10px 0 0;
  padding: 0;
  list-style: none;
  float: left;
}

.star-rating li {
  display: inline-block;
  margin-right: 3px;
  color: #03a9f4;
}

.review-link {
  color: #444;
}

.review-link:hover {
  color: #000;
}

.woocommerce-product-rating .price {
  margin-top: 20px;
}

.woocommerce-product-rating .price del, .woocommerce-product-rating .price ins {
  display: block;
}

.woocommerce-product-rating .price del {
  font-size: 20px;
  color: #ccc;
  margin-bottom: 10px;
}

.woocommerce-product-rating .price ins {
  font-size: 32px;
  text-decoration: none;
  font-weight: 700;
  color: #000;
}

.woocommerce-product-details__short-description {
  margin-top: 30px;
}

.woocommerce-product-details__short-description p {
  font-size: 14px;
  color: #7b7b7b;
  margin-bottom: 0;
}

.woocommerce-product-details__short-description p span {
  color: #444;
  font-weight: 600;
}

.woocommerce-product-details__short-description .product-details-title {
  font-size: 13px;
  text-transform: uppercase;
  color: #333;
  font-weight: 700;
  margin: 20px 0 10px;
}

.cart {
  margin-top: 30px;
}

.cart .quantity {
  position: relative;
  background: #FFF;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.09);
  width: 80px;
  height: 40px;
  border: none;
  border-radius: 30px;
  float: left;
  padding: 6px 12px;
  margin-right: 10px;
}

.cart .quantity .fa-minus, .cart .quantity .fa-plus {
  font-size: 8px;
  cursor: pointer;
}

.cart .quantity input {
  margin: 0;
  width: 50%;
  margin: 0 auto;
  border: none;
  padding: 0;
  text-align: center;
  font-size: 13px;
  margin-left: 5px;
}

.cart .gp-cart-btn, .cart .add_to_wishlist {
  background: #25abd1;
  padding: 8px 17px;
  color: #FFF;
  border: none;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  letter-spacing: 1px;
  font-size: 13px;
}

.cart .gp-cart-btn i, .cart .add_to_wishlist i {
  margin-right: 5px;
}

.cart .gp-cart-btn:hover, .cart .add_to_wishlist:hover {
  background: #333;
  color: #FFF;
}

.cart .add_to_wishlist {
  background: #fff;
  color: #000;
  padding: 13px 20px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
}

.cart .add_to_wishlist i {
  color: #fa6047;
}

.cart .add_to_wishlist:hover {
  background: #444;
  color: #FFF;
}

.product_meta {
  margin-top: 30px;
}

.product_meta .sku_wrapper {
  font-size: 15px;
  color: #333;
  display: block;
}

.product_meta .sku_wrapper span {
  color: #8e8e8e;
}

.product_meta .posted_in {
  display: block;
  margin-bottom: 10px;
}

.product_meta .posted_in a {
  color: #8e8e8e;
  text-decoration: none;
}

.product_meta .tagcloud {
  margin-bottom: 15px;
}

.gp-product-tab {
  margin: 0 0 50px;
  padding: 0 0 20px 0;
  list-style: none;
  text-align: center;
}

.gp-product-tab li {
  display: inline-block;
}

.gp-product-tab li a {
  padding: 10px 20px;
  text-decoration: none;
  color: #444;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  border-top: 1px solid transparent;
}

.gp-product-tab li:hover a, .gp-product-tab li.active a {
  color: #03a9f4;
  border-color: #03a9f4;
}

.comments-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.review-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.review-list .review-comment {
  margin-bottom: 20px;
}

.review-list .review-comment .user-avatar {
  height: 70px;
  width: 70px;
  float: left;
  border-radius: 50%;
  overflow: hidden;
}

.review-list .review-comment .user-avatar img {
  width: 100%;
  height: auto;
}

.review-list .review-comment .comment-content {
  margin-left: 90px;
}

.review-list .review-comment .comment-content .name {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.review-list .review-comment .comment-content .comment-meta {
  margin-bottom: 10px;
}

.review-list .review-comment .comment-content .comment-meta .star-rating li {
  font-size: 12px;
  margin-right: 0;
}

.review-list .review-comment .comment-content .comment-meta .post-date {
  font-size: 12px;
  color: #a7a7a7;
}

.rating-title {
  font-size: 18px;
  font-weight: 700;
}

.choose-ratting .rating-container {
  margin-bottom: 20px;
}

.choose-ratting .rating-container .filled-stars {
  color: #03a9f4;
  -webkit-text-stroke: 0;
  text-shadow: none;
}

.choose-ratting .rating-container .star {
  font-size: 18px;
}

.choose-ratting .rating-container .caption {
  font-size: 57%;
  margin-top: 0;
}

.review-form textarea, .review-form input {
  border: 1px solid #c9c9c9;
  border-radius: 10px;
  outline: none;
  font-size: 14px;
  width: 100%;
}

.review-form textarea {
  height: 150px;
}

.review-form input {
  height: 50px;
  margin-top: 20px;
}

.review-form .gp-btn {
  max-width: 250px;
  margin: 30px 0  0 15px;
  display: inline-block;
  width: 100%;
  text-align: center;
  background: #333;
  color: #FFF;
  border-radius: 30px;
  padding: 10px 0px;
  transition: all 0.3s ease-in-out;
  border: none;
  outline: none;
}

.review-form .gp-btn:hover {
  background: #03a9f4;
}

.shop_attributes {
  width: 100%;
}

.shop_attributes tbody {
  border: 1px solid #dcdcdc;
}

.shop_attributes tbody tr {
  height: 40px;
  border-bottom: 1px solid #dcdcdc;
}

.shop_attributes tbody tr th {
  width: 30%;
  border-right: 1px solid #dcdcdc;
  padding-left: 20px;
}

.shop_attributes tbody tr td {
  padding-left: 20px;
}

.shop_attributes tbody tr td p {
  margin-bottom: 0;
}

.related-product {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #dcdcdc;
}

.related-product .product-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #000;
}

/* Product Widgets */
#slider-range {
  margin-bottom: 15px;
}

.ui-widget-header {
  background: #03a9f4;
}

.ui-slider-horizontal {
  height: 7px;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -6px;
  border-radius: 50%;
}

.ui-state-default, .ui-widget-content .ui-state-default {
  border: 3px solid #03a9f4;
  background: #FFF;
  outline: none;
}

.ui-widget-content {
  background: #dcdcdc;
}

.ui-widget.ui-widget-content {
  border: none;
}

.price-filter span {
  font-size: 13px;
  color: #000;
}

.price-filter span #amount {
  width: auto;
  padding: 0;
  margin: 0;
  outline: none;
  font-size: 13px;
  border: none;
  cursor: default;
  background: transparent;
}

/* Product List */
.product_list_widget {
  margin: 0;
  padding: 0;
  list-style: none;
}

.product_list_widget li {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #efefef;
}

.product_list_widget li:last-child {
  border-bottom: none;
}

.product_list_widget li .product-details {
  color: #333;
  font-weight: 600;
}

.product_list_widget li .product-details .product-thumb {
  float: right;
  height: 65px;
  width: 65px;
}

.product_list_widget li .product-details .product-title {
  display: block;
  line-height: 16px;
  transition: all 0.3s ease-in-out;
}

.product_list_widget li .product-details .product-title:hover {
  color: #03a9f4;
}

.product_list_widget li .product-rating {
  height: 1.7em;
  overflow: hidden;
}

.product_list_widget li .product-rating .star-rating {
  height: 1.7em;
}

.product_list_widget li .product-rating .star-rating li {
  font-size: 12px;
  margin-right: -2px;
}

.product_list_widget li ins {
  margin-left: 5px;
  text-decoration: none;
}

/* Cart */
#cart {
  padding: 100px 0;
  background: #f7f7f7;
}

.woocommerce table.shop_table {
  margin-bottom: 60px;
  border: none;
  border-collapse: collapse;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 15px 34px rgba(0, 0, 0, 0.09);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
}

.woocommerce table.shop_table th {
  border: 0;
  text-transform: uppercase;
  position: relative;
}

.woocommerce table.shop_table td {
  padding: 6px 12px;
  border: 0;
  text-transform: uppercase;
  position: relative;
}

.woocommerce table.shop_table thead tr {
  background: #f7f7f7;
}

.woocommerce table.shop_table thead tr th {
  border-bottom: 1px solid #f7f7f7;
  background: #e9e9e9;
  font-size: 15px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.1em;
  padding: 15px 30px;
  text-align: center;
}

.woocommerce table.shop_table tbody tr {
  border-bottom: 1px solid #f7f7f7;
}

.woocommerce table.shop_table tbody tr td.product-item a {
  color: #202020;
  text-decoration: none;
  font-weight: 600;
}

.woocommerce table.shop_table tbody tr td.product-item a.remove {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  background: #e9e9e9;
  transition: all 0.4s ease-in-out;
  position: absolute;
  left: 20px;
  top: 20px;
  border-radius: 100%;
  color: red !important;
  text-decoration: none;
  border: 0;
}

.woocommerce table.shop_table tbody tr td.product-item img {
  width: 70px;
  height: 70px;
  margin-left: 70px;
  margin-right: 15px;
  border-radius: 3px;
}

.woocommerce table.shop_table tbody tr td.product-price {
  line-height: 28px;
  letter-spacing: 0.1em;
  text-align: center;
  border: 1px solid #f7f7f7;
}

.woocommerce table.shop_table tbody tr td.actions {
  padding: 20px;
}

.woocommerce table.shop_table tbody tr td.actions .coupon {
  float: left;
}

.woocommerce table.shop_table tbody tr td.actions .coupon input {
  margin-bottom: 0;
}

.woocommerce table.shop_table tbody tr td.actions .coupon input.input-text {
  margin-right: 10px;
  max-width: 200px;
  padding: 6px 15px;
  border: 2px solid #f7f7f7;
  border-radius: 25px;
  font-size: 14px;
}

.woocommerce table.shop_table tbody tr td.actions .coupon input.button {
  background: #333;
  color: #FFF;
}

.woocommerce table.shop_table tbody tr td.actions .coupon input.button:hover {
  background: #191919;
  color: #FFF;
}

.woocommerce table.shop_table tbody tr td.actions .button {
  box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18);
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap {
  padding: 10px 20px;
  background: #fff;
  border-radius: 15px;
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul {
  margin: 0;
  padding: 0;
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul > li {
  list-style: none;
  padding: 10px 0;
  border-bottom: 1px solid #f7f7f7;
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul > li .title {
  display: inline-block;
  width: 59%;
  font-size: 12px;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul > li span[data-title="Subtotal"], .woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul > li span[data-title="Total"] {
  display: inline-block;
  width: 39%;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: right;
}

.woocommerce .grand-total h4 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

.woocommerce .wc-proceed-to-checkout {
  padding-top: 20px;
}

.woocommerce .wc-proceed-to-checkout .gp-btn {
  padding: 12px 35px;
  color: #FFF;
  font-size: 14px;
  display: inline-block;
  margin: auto;
  float: none;
  text-align: center;
  border-radius: 25px;
  background: #03a9f4;
  font-size: 14px;
  border: none;
  box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18);
}

#checkout {
  padding: 100px 0;
}

#checkout form.checkout.woocommerce-checkout {
  background: #fff;
  padding: 30px;
  border-radius: 25px;
  box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18);
}

.create-account .checkbox {
  display: inline-block;
  margin-left: 10px;
}

.woocommerce-checkout #order_review {
  background: #f7f7f7;
  padding: 30px;
  border: 0;
  border-radius: 25px;
  overflow: hidden;
}

.woocommerce-checkout #order_review .shop_table > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.woocommerce-checkout #order_review .shop_table > ul > li {
  list-style: none;
  padding: 15px 0;
  border-top: 1px solid #f7f7f7;
}

.woocommerce-checkout #order_review .shop_table > ul > li.header {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border-bottom: 1px solid;
}

.woocommerce-checkout #order_review .shop_table > ul > li.order-total {
  font-size: 14px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border-top: 1px solid;
}

.woocommerce-checkout #order_review .shop_table > ul li {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.woocommerce-checkout #order_review .shop_table > ul li .col-left {
  display: inline-block;
  width: 59%;
}

.woocommerce-checkout #order_review .shop_table > ul li .col-right {
  display: inline-block;
  width: 38%;
  text-align: right;
}

.woocommerce-checkout #order_review .order-review-heading, .woocommerce-checkout #order_review .payment-method-heading {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #202020;
}

.woocommerce-checkout #order_review #payment {
  margin-top: 50px;
}

.wc_payment_methods {
  margin: 0;
  padding: 0;
  list-style: none;
}

.form-row-first, .form-row-last {
  width: 47%;
  float: left;
}

.form-row-first {
  margin-right: 38px;
}

.input-text, select {
  width: 100%;
}

.form-row {
  margin-bottom: 20px;
}

/*--------------------------------------------------------------
  ##  Footer
  --------------------------------------------------------------*/
.footer-one {
  background: #ecf0f1;
  position: relative;
}

.footer-one .footer-content p {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}

.footer-inner {
  padding: 100px 0;
  text-align: center;
}

.social-btns .btn,
.social-btns .btn:before,
.social-btns .btn i {
  transition: all 0.35s;
  transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
}

.social-btns .btn:before {
  top: 90%;
  left: -110%;
}

.social-btns .btn i {
  transform: scale(0.8);
}

.social-btns .btn.facebook:before {
  background-color: #3b5998;
}

.social-btns .btn.facebook i {
  color: #3b5998;
}

.social-btns .btn.twitter:before {
  background-color: #3cf;
}

.social-btns .btn.twitter i {
  color: #3cf;
}

.social-btns .btn.google:before {
  background-color: #dc4a38;
}

.social-btns .btn.google i {
  color: #dc4a38;
}

.social-btns .btn.dribbble:before {
  background-color: #f26798;
}

.social-btns .btn.dribbble i {
  color: #f26798;
}

.social-btns .btn.skype:before {
  background-color: #00aff0;
}

.social-btns .btn.skype .fa {
  color: #00aff0;
}

.social-btns .btn:focus:before,
.social-btns .btn:hover:before {
  top: -10%;
  left: -10%;
}

.social-btns .btn:focus .fa,
.social-btns .btn:hover .fa {
  color: #fff;
  transform: scale(1);
}

.social-btns {
  height: 50px;
  margin: auto;
  font-size: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.social-btns .btn {
  display: inline-block;
  background-color: #fff;
  width: 50px;
  height: 50px;
  line-height: 38px;
  margin: 0 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 28%;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  opacity: 0.99;
}

.social-btns .btn:before {
  content: '';
  width: 130%;
  height: 130%;
  position: absolute;
  transform: rotate(45deg);
}

.social-btns .btn i {
  font-size: 24px;
  vertical-align: middle;
}

.copyright {
  padding: 20px 0;
  background: #303030;
}

.copyright p {
  margin: 0;
  font-size: 16px;
  color: #f5f5f5;
}

.copyright p a {
  color: #03a9f4;
  text-decoration: none;
}

#footer {
  background: #181818;
  text-align: center;
}

.footer-details {
  padding: 70px 0;
}

.footer-details .footer-heading {
  font-size: 16px;
  font-weight: 300;
  color: #c2c2c2;
  margin-top: 0;
  margin-bottom: 30px;
}

.footer-logo a {
  display: block;
  margin-bottom: 20px;
}

.footer-soclink {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-soclink li {
  display: inline-block;
  margin: 0 2px;
  text-align: center;
}

.footer-soclink li a {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  display: block;
  color: #FFF;
}

.footer-soclink li a i {
  line-height: 40px;
  transition: all 0.3s ease-in-out;
}

.footer-soclink li.facebook-bg a:hover {
  background: #305fb3;
  color: #FFF;
}

.footer-soclink li.twitter-bg a:hover {
  background: #5eaade;
  color: #FFF;
}

.footer-soclink li.google-bg a:hover {
  background: #d7482a;
  color: #FFF;
}

.footer-soclink li.linkedin-bg a:hover {
  background: #0077B5;
  color: #FFF;
}

.footer-soclink li.pinterest-bg a:hover {
  background: #bd081c;
  color: #FFF;
}

.copy-right {
  padding: 18px 0;
  background: #191919;
  border-top: 1px solid #292929;
}

.copy-right p {
  margin-bottom: 0;
  color: #ccc;
  line-height: 30px;
}

.copy-right p a {
  color: #03a9f4;
}

.copy-right .footer-soclink li a {
  height: 30px;
  width: 30px;
}

.copy-right .footer-soclink li a i {
  line-height: 30px;
  font-size: 12px;
  color: #b5b5b5;
}

.copy-right .footer-soclink li:hover i {
  color: #FFF;
}

#footer-two {
  background: #252525;
}

.footer-title {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  color: #FFF;
  margin-top: 0;
  margin-bottom: 20px;
}

.gp-footer-widget {
  padding: 70px 0;
}

.footer-about p {
  color: #FFF;
}

.recent-post {
  margin-bottom: 10px;
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
}

.recent-post .post-thumb {
  float: left;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.recent-post .post-thumb img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.recent-post .post-content {
  margin-left: 70px;
}

.recent-post .post-content h3 {
  color: #FFF;
  margin: 5px 0 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  transition: all 0.3s ease-in-out;
}

.recent-post .post-content .post-meta p {
  color: #ccc;
  margin-bottom: 0;
}

.recent-post:hover .post-thumb {
  opacity: 0.7;
}

.recent-post:hover .post-content h3 {
  color: #03a9f4;
}

.recent-post:last-child {
  border-bottom: none;
}

/*Instagram Feed*/
#instagram-feed ul {
  padding-left: 0;
  margin: 0;
}

#instagram-feed ul li {
  display: inline-block;
  width: 31.5%;
  padding: 0 3px 6px 0;
}

#instagram-feed ul li a {
  display: block;
}

#instagram-feed img {
  max-width: 100%;
  width: 100%;
}

/* Twitter Feed */
#twitter_feed ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#twitter_feed ul li {
  margin-bottom: 10px;
}

#twitter_feed ul li .tweet {
  color: #FFF;
  margin: 0;
}

#twitter_feed ul li .tweet a {
  color: #03a9f4;
}

#twitter_feed ul li .interact {
  display: none;
}

#twitter_feed ul li .timePosted {
  margin: 0;
}

#twitter_feed ul li .timePosted a {
  color: #ddd;
}

@media screen and (max-width: 768px) {
  .footer-fixed {
    position: static;
  }
  .footer-title {
    margin-top: 20px;
  }
  #instagram-feed ul li {
    width: 16%;
  }
  .copy-right {
    text-align: center;
  }
  .copy-right .footer-soclink {
    margin-top: 10px;
  }
  .copy-right .footer-soclink.text-right {
    text-align: center;
  }
}

/*--------------------------------------------------------------
  ##  Sections
  --------------------------------------------------------------*/
.elements-banner {
  padding: 200px 0;
  position: relative;
}

.elements-banner .breadcrumbs-inner h1 {
  font-size: 60px;
  color: #FFF;
  margin-bottom: 20px;
}

.elements-banner .breadcrumbs-inner h3 {
  font-size: 22px;
  color: #c1c1c1;
}

.style-one {
  padding: 100px 0;
}

.style-two {
  padding: 100px 0;
  background: #f8f8f8;
}

.style-three {
  padding: 100px 0;
  background: #03a9f4;
}

.style-four {
  padding: 100px 0;
  background: #252525;
}

.style-five {
  padding: 100px 0;
  background: #f8f8f8;
}

/* Accordian */
#accordion-wrapper {
  padding: 100px 0;
}

/* Buttons */
#buttons .style-one, #buttons .style-two, #buttons .style-three, #buttons .style-four, #buttons .style-five {
  text-align: center;
}

/* Contact Form */
#contact-form .style-one {
  background: #f3f3f3;
}
