#about-colorfull {
    background: #f8f8f8;

    .about-feature {
        li {
            &:before {
                color: $color-1; } } } }


.about-details {
    padding-left: 20px;

    .about-title {
        font-size: 24px;
        color: #000;
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 10px;
        position: relative;
        padding-bottom: 10px;

        &:after {
            position: absolute;
            content: '';
            width: 50px;
            height: 1px;
            background: $color_theme;
            left: 0;
            bottom: 0; } }

    p {
        font-size: 14px;
        color: #333;
        margin-bottom: 20px; } }

.about-feature {
    margin: 20px 0 0 20px;
    padding: 0;
    list-style: none;

    li {
        font-size: 15px;
        font-weight: 500;
        color: #444;
        line-height: 30px;
        position: relative;

        &:before {
            position: absolute;
            content: "\f046";
            font: normal normal normal 15px/1 FontAwesome;
            color: $color_theme;
            left: -20px;
            top: 5px; } } }

.about-video {
    position: relative;
    border-radius: 15px;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.3);

    img {
        width: 100%;
        height: auto;
        border-radius: 15px; } }

.video-play-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 60px;
    height: 60px;
    font-size: 6rem;
    background-color: $color_theme;
    border-radius: 50%;
    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.2);
    line-height: 44px;
    text-align: center; }

.video-play-icon i {
    margin-left: 5px;
    font-size: 30px;
    color: #FFF; }

.video-play-icon {
    &.color-play-btn {
        background: $color-1;
        box-shadow: 0 0 0 6px rgba($color-1, 0.5); } }

.about-details {
    &.colorfull {
        .about-title {
            &:after {
                background: $color-1; } } } }


section {
    &#about-two {
        padding: 0; } }

.about-wrap {
    background-position: center center;
    background-size: cover;
    padding: 100px 0; }


.about-feature-wrap {}


.about-thumb {
    img {
        width: 100%;
        height: auto; } }

.tabs-container {
    overflow: hidden; }

.tabs-container {
    .tabs {
        margin-bottom: 30px;
        padding: 0; } }

.tabs-container {
    .tabs {
        .tab__title {
            &:not(.btn) {
                padding: 0; } } } }

.tabs-container {
    .tabs {
        li {
            display: inline-block;
            &:not(:last-child) {
                margin-right: 3.25em; } } } }

.tabs-container {
    &:not(.tabs-5) {
        .tabs-content {
            margin-bottom: 0;
            position: relative; } } }

.tabs-container {
    &:not(.tabs-5) {
        .tabs-content {
            > li {
                &.active {
                    .tab__content {
                        animation: fadeIn .75s ease forwards; } } } } } }

.tabs-container {
    &:not(.tabs-5) {
        .tabs-content {
            > li {
                &:not(.active) {
                    position: absolute;
                    top: 0;
                    pointer-events: none; } } } } }

.tabs-container {
    &:not(.tabs-5) {
        .tabs-content {
            > li {
                &:not(.active) {
                    .tab__content {
                        display: block;
                        pointer-events: none;
                        animation: fadeOut .5s ease forwards; } } } } } }

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate3d(20px, 0, 0);
        -webkit-transform: translate3d(20px, 0, 0); }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0); } }


@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0); }

    to {
        opacity: 0;
        transform: translate3d(-20px, 0, 0);
        -webkit-transform: translate3d(-20px, 0, 0); } }


@media all and (max-width: 767px) {
    .tabs-container .tabs {
        margin-bottom: 1.625em; }

    .tabs-container .tabs li {
        margin: 0 1.625em 0.40625em 1.625em; }

    .tabs-container .tabs li:not(:last-child) {
        margin-right: 0; } }


.tabs-1 {
    .tabs {
        li {
            transition: 0.3s linear;
            -webkit-transition: 0.3s linear;
            -moz-transition: 0.3s linear; } } }


.tabs-1 .tabs li:not(.active) {
  opacity: .8; }

.tabs-1 {
    .tabs {
        li {
            &:hover {
                opacity: .9; } } } }

.tabs-1 {
    .tabs {
        li {
            .btn {
                border-color: rgba(0, 0, 0, 0);
                transition: 0.3s ease;
                color: #000;
                backface-visibility: hidden;
                text-transform: uppercase;
                border-bottom: 1px solid #FFF;
                padding: 8px 16px;
                font-size: 12px;
                font-weight: 600; } } } }

.tabs-1 {
    .tabs {
        li {
            &:not(:last-child) {
                margin-right: 1.625em; } } } }

.tabs-1 {
    .tabs {
        li {
            &.active {

                .btn {
                    background: $color_theme;
                    color: #FFF;
                    border-color: $color_theme;
                    box-shadow: 0px 17.321px 40px 0px rgba(34, 35, 40, 0.198);
                    border-radius: 25px; } } } } }


.tabs-content {
    margin: 0;
    padding: 0;
    list-style: none;

    .tab-content-inner {
        h6 {
            font-size: 17px;
            font-weight: 600; }

        p {
            font-size: 14px;
            line-height: 28px;
            color: #707070; } } }

.tabs-2 {
    .tabs h5,h6 {
        margin: 0; } }

.tabs-3 {
    &.tabs-container {
        .tabs {
            li {
                margin-right: 0; } } } }

.tabs-3.tabs-container {
    .tabs {
        .tab__title {
            position: relative;
            overflow: hidden;
            width: 250px;
            height: 150px;
            text-align: center; } } }

.tabs-3.tabs-container {
    .tabs {
        .tab__title {
            h5 {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                -webkit-transform: translate3d(-50%, -50%, 0);
                z-index: 2;
                color: #fff;
                width: 100%; } } } }



.about-details-three {
    h4 {
        color: #4772d9;
        background: $color_gradian;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }

    h2 {
        font-size: 35px;
        color: #202020;
        margin: 20px 0;
        line-height: 40px; }


    p {
        margin-bottom: 30px;
        color: #333;
        line-height: 28px; } }

#about-three {
    padding: 150px 0 250px;
    background: #fafafa;
    background-position: center center;
    background-size: cover; }

.ab-three-thumb {
    position: relative;
    img {
        position: absolute;
        left: 0; } }



@media screen and (max-width: 768px) {
    .about-video {
        max-width: 95%;
        margin: 0 auto 30px; } }


@media screen and (max-width: 500px) {
    .about-wrap {
        .section-title {
            margin-top: 20px; } }

    #feature-two {
        .section-title {

            a {
                margin-bottom: 20px; } } }

    .tabs-1 {
        .tabs {
            li {
                &:not(:last-child) {
                    margin-right: 0; }

                .btn {
                    padding: 8px; } } } }

    .tabs-container {
        .tabs {
            li {
                margin: 0; } } } }

