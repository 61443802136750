#header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 200;

    &.header-one {
        border-bottom: 2px solid #1f6b57;

        .navbar-brand {
            border-right: 2px solid #3c9181; } }

    &.header-two {
        #discovery-main-menu {
            background: rgba(0,0,0,0.8); } }


    &.header-three {
        background: #1b1b1a;
        height: 100px;

        #logo {
            border-right: none;
            padding: 18px 0; }

        #discovery-main-menu {
            position: absolute;
            bottom: -30px;
            width: 100%;
            background: #FFF;
            max-width: 1200px;
            border-radius: 30px;

            > ul {
                margin-left: 50px;

                > li {

                    > a {
                        padding: 18px 10px; }

                    ul {
                        margin: 0;
                        border-top: 1px solid $color_theme;

                        > li {
                            > ul {
                                left: 105%; } } } } } } } }


#discohead {
    position: relative;
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.07);
    backface-visibility: hidden;
    z-index: 1000;


    &.fixed {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        background-color: #fff;
        animation: headerFixedTop .35s;

        #logo {
            border-right: none; }

        .discovery-main-menu {

            > ul {
                > li {
                    .sub-menu {
                        margin-top: 0;
                        border-radius: 5px; } } } } } }

.transparent-header {
    #discohead:not(.fixed) {
        box-shadow: none;
        position: absolute;
        top: 0;
        right: 0;
        left: 0; } }

.admin-bar.transparent-header #discohead:not(.fixed) {
    top: 57px; }

@media screen and (max-width: 782px) {
    .admin-bar {
        &.transparent-header {
            #discohead:not(.fixed) {
                top: 71px; } } }



    .admin-bar {
        #discohead {
            &.fixed {
                top: 32px; } } } }


@media screen and (max-width: 782px) {
    .admin-bar #discohead.fixed {
        top: 46px; } }



@media screen and (max-width: 600px) {
    .admin-bar #discohead.fixed {
        top: 0; } }



@media (min-width: 1024px) {
    #discohead {
        > .container-fluid {
            padding-left: 40px;
            padding-right: 40px; } }



    .transparent-header {
        #discohead-faker {
            display: none; } }


    @-webkit-keyframes headerFixedTop {
        0% {
            opacity: 0;
            transform: translateY(-100%); }

        100% {
            opacity: 1;
            transform: translateY(0); } }



    @keyframes headerFixedTop {
        0% {
            opacity: 0;
            transform: translateY(-100%); }

        100% {
            opacity: 1;
            transform: translateY(0); } }



    #logo {
        display: block;
        padding: 23px 0;
        float: left; }

    #logo {
        .logo-contrast {
            display: none; } }


    .transparent-header {
        #discohead {
            &:not(.fixed) {
                #logo .logo-normal {
                    display: none; } } } }


    .transparent-header {
        #discohead {
            &:not(.fixed) {
                #logo {
                    .logo-contrast {
                        display: block; } } } } }

    .fixed {
        #logo {
            padding: 20px 0; } }


    #nav-toggle {
        display: none;
        width: 50px;
        height: 42px;
        float: right;
        background-color: transparent;
        padding: 10px;
        cursor: pointer;
        margin-left: 15px;
        margin-top: 16px;
        margin-right: -10px; } }


@media (max-width: 768px) {
    #nav-toggle {
        display: block; }

    .closed-header {
        #nav-toggle {
            display: block; } }


    #nav-toggle {
        .toggle-inner {
            position: relative; } }


    #nav-toggle {
        .toggle-inner {
            > span {
                display: block;
                position: relative;
                margin-bottom: 5px;
                width: 100%;
                height: 2px;
                background-color: #000;
                -webkit-transition: all 0.25s ease-in-out;
                transition: all 0.25s ease-in-out; } } }


    #nav-toggle {
        .toggle-inner {
            > span {
                &:nth-child(2) {
                    width: 24px;
                    margin-left: 6px;
                    background-color: $color_theme !important; } } } }


    #nav-toggle {
        .toggle-inner {
            > span {
                &:nth-child(3) {
                    width: 24px;
                    margin-left: 6px;
                    background-color: $color_theme !important; } } } }


    #nav-toggle {
        .toggle-inner {
            > span {
                &:nth-child(4) {
                    margin-bottom: 0; } } } }


    #nav-toggle {
        &.active {
            .toggle-inner {
                > span {
                    &:nth-child(1) {
                        transform: rotate(45deg) translate(7px, 7px); } } } } }


    #nav-toggle {
        &.active {
            .toggle-inner {
                > span {
                    &:nth-child(4) {
                        transform: rotate(-45deg) translate(8px, -8px); } } } } }


    #nav-toggle {
        &.active {
            .toggle-inner {
                > span {
                    &:nth-child(2), &:nth-child(3) {
                        opacity: 0; } } } } }

    .topbar-wrapper {
        display: none; }



    .transparent-header {
        #discohead {
            &:not(.fixed) {
                #nav-toggle {
                    // background-color: rgba(0, 0, 0, 0.8)
                    margin-right: 0;
                    float: right;
                    width: 30px;
                    padding: 5px 0; } } } }


    .transparent-header {
        #discohead {
            &:not(.fixed) {
                #nav-toggle {
                    .toggle-inner {
                        > span {
                            background-color: #fff; } } } } } } }


@media (min-width: 769px) {
    #discovery-main-menu {
        display: block;
        text-align: center; }

    .closed-header {
        #discovery-main-menu {
            transition: all 0.25s ease-in-out;
            opacity: 0;
            visibility: hidden; } }

    .transparent-header {
        #discohead {

            &:not(.fixed) {
                #discovery-main-menu {
                    // margin-top: 18px
 } } } }                    // margin-bottom: 18px

    .transparent-header {
        #discohead {
            &:not(.fixed) {
                #discovery-main-menu {
                    > ul {
                        padding-left: 25px;
                        padding-right: 25px; } } } } }

    .transparent-header {
        #discohead {
            &:not(.fixed) {
                #discovery-main-menu {
                    > ul {
                        display: inline-block;
                        > li {
                            > a {
                                padding: 28px 7px; } } } } } } }

    #discovery-main-menu {
        &.visible {
            visibility: visible;
            opacity: 1; }

        > ul {
            padding: 0;
            margin: 0;
            display: inline-block;


            > li {
                text-align: left;
                display: inline-block;
                position: relative;

                > a {
                    display: block;
                    font-size: 14px;
                    padding: 26px 10px;
                    color: #000;
                    font-weight: 500;

                    &:hover {
                        color: $color_theme; } } } }

        .secondary-navigation {
            display: inline-block;
            padding: 27px 0;

            > ul {
                margin: 0;
                padding: 0;


                > li {
                    display: inline-block;
                    padding: 15px 0;

                    .cart-indication {
                        position: relative;
                        text-decoration: none;
                        margin-right: 20px;
                        span {
                            color: #202020; }

                        .badge {
                            width: 17px;
                            height: 17px;
                            border-radius: 50%;
                            font-size: 10px;
                            line-height: 17px;
                            text-align: center;
                            background: $color_theme;
                            color: #fff;
                            position: absolute;
                            top: -4px;
                            left: 8px;
                            padding: 0; } }


                    &:hover {
                        .sub-menu {
                            opacity: 1;
                            visibility: visible;
                            top: 100%; } }

                    .search-btn {
                        text-decoration: none;
                        span {
                            font-size: 14px;
                            color: #202020; } } } } } }


    .fixed {
        #discovery-main-menu {
            > ul {
                > li {
                    > a {
                        padding: 25px 7px; } } }


            .secondary-navigation {
                padding: 10px 0;
                > ul {
                    > li {
                        padding: 14px 0;

                        .search-btn {
                            span {
                                color: #202020; } } } } } } }

    .transparent-header {
        #discohead {
            &:not(.fixed) {
                #discovery-main-menu {
                    > ul {

                        > li {
                            > a {
                                color: #FFF;

                                &:hover {
                                    color: $color_theme; } } } }

                    .secondary-navigation {
                        > ul {
                            > li {
                                padding: 0;
                                .cart-indication, .search-btn {
                                    span {
                                        color: #FFF; } } } } } } } } }


    #discovery-main-menu {
        > ul {
            > li {
                ul {
                    position: absolute;
                    margin: 0;
                    background-color: rgba(255, 255, 255, 1);
                    padding: 10px;
                    min-width: 240px;
                    top: 100%;
                    left: 0;
                    opacity: 0;
                    visibility: hidden;
                    transform: translateY(10px);
                    transition: all 0.25s ease-in-out; } } } }

    #discovery-main-menu {
        > ul {
            > li {
                ul {
                    box-shadow: 0 15px 45px 0 rgba(0,0,0,0.2);
                    > li {
                        list-style: none;
                        position: relative;

                        > a {
                            display: block;
                            color: #000;
                            padding: 5px 10px;
                            font-size: 13px;

                            &:hover {
                                color: $color_theme;
                                background: #f3f3f3; } }

                        > ul {
                            top: -10px;
                            left: 98%;
                            margin: 0 0 0 12px;
                            border-left: 1px solid $color_theme;

                            &:after, &:before {
                                display: none; } }

                        &.menu-item-has-children {
                            > a {
                                &:after {
                                    content: '\f105';
                                    font: normal normal normal 14px/1 FontAwesome;
                                    position: absolute;
                                    top: 10px;
                                    right: 10px; } }

                            &:hover > ul {
                                visibility: visible;
                                opacity: 1;
                                transform: translateY(0); } } } }

                &.menu-item-has-children {
                    > a {
                        &:after {
                            content: '\f107';
                            font: normal normal normal 14px/1 FontAwesome;
                            padding-left: 10px; } }

                    &:hover {
                        > ul {
                            opacity: 1;
                            visibility: visible;
                            transform: translateY(0); } } } } } }

    #discovery-main-menu {
        > ul {
            > li {
                &:nth-last-child(-n+2) {
                    ul {
                        left: auto;
                        right: 0;

                        &:before {
                            left: auto;
                            right: 10px; }

                        li {
                            &.menu-item-has-children {
                                > a {
                                    &:after {
                                        content: '\f104'; } } }


                            > ul {
                                right: 100%;
                                margin-right: 12px;
                                border-left: none;
                                border-right: 1px solid $color_theme; } } } } } } }

    .menu-two {
        .dt-header {
            #discovery-main-menu {
                text-align: right; } } } }

.secondary-navigation {
    position: relative;

    .sub-menu {
        box-shadow: 0 8px 20px rgba(0,0,0,.06);
        opacity: 0;
        visibility: hidden;
        z-index: 101;
        line-height: 1.8;
        position: absolute;
        background: #FFF;
        text-align: left;
        width: 350px;
        padding: 20px;
        margin-top: 0;
        right: 0;
        border-radius: 5px;
        top: 140%;
        transition: all 0.3s ease-in-out;

        .cart-item {
            margin-bottom: 10px;
            display: block;
            position: relative;
            .product-thumbnail {
                width: 60px;
                margin-right: 1.3em;
                float: left;

                img {
                    width: 100%; } }
            .product-details {
                position: relative;
                .product-title {
                    font-size: 13px;
                    font-weight: 600;
                    color: #000;
                    display: block;
                    text-decoration: none;


                    &:hover {
                        color: $color_theme; } }

                .product-remove {
                    position: absolute;
                    right: 0;
                    top: 42%;
                    height: 18px;
                    width: 18px;
                    font-size: 9px;
                    background: #808080;
                    color: #FFF;
                    border-radius: 50%;
                    text-align: center;
                    padding: 4px 6px;
                    color: #f3f3f3;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        background: #444;
                        color: #FFF; } }

                .product-quantity {
                    display: inline-block; } } }

        .cart-subtotal {
            display: block;
            padding: 15px 0;
            border-top: 1px solid #dcdcdc;
            border-bottom: 1px solid #dcdcdc;
            margin-top: 15px;
            font-size: 14px;
            font-weight: 600;
            color: #000;
            text-transform: uppercase;

            .amount {
                float: right; } }

        .cart-actions {
            display: block;
            padding-top: 20px;
            .view-cart, .checkout {
                padding: 8px 25px;
                text-decoration: none;
                border-radius: 30px;
                transition: all 0.3s ease-in-out;
                border: 2px solid transparent;
                text-transform: uppercase;
                font-size: 12px;
                display: inline-block;
                letter-spacing: 1px;
                font-weight: 600; }

            .view-cart {
                border-color: #dcdcdc;
                color: #333;

                &:hover {
                    background: #dcdcdc;
                    color: #333; } }

            .checkout {
                background: $color_theme;
                color: #FFF;
                float: right;

                &:hover {
                    background: darken($color_theme, 5%); } } } } }



body.search-open {
    overflow: hidden; }


button.close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    background: none;
    border: 0;
    font-size: 20px;
    cursor: pointer;
    color: #FFF;
    background: rgba(255,255,255,0.3);
    border-radius: 50%;
    outline: none;
    opacity: 1;
    height: 40px;
    width: 40px; }

button.close:hover {
    background: $color_theme;
    color: #FFF;
    opacity: 1; }


.search-box.search-elem {
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0,0,0,0.95);
    transition: -webkit-transform .8s ease, -moz-transform .8s ease, -o-transform .8s ease, transform .8s ease, opacity .5s ease, visibility .5s ease;
    transform: scale(0.7);
    z-index: 99999;
    opacity: 0;
    visibility: hidden; }


.search-box.search-elem .inner {
    width: 50%;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }

.search-box.search-elem label {
    color: white;
    font-weight: 300; }

.search-box.search-elem button.submit {
    outline: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 70px;
    padding: 10px 2rem;
    background: transparent;
    font-size: 20px;
    color: #FFF;
    outline: none;
    border: none; }

.search-box.search-elem button.submit[disabled] {
    background: transparent;
    border: none;
    color: #FFF;
    outline: none; }

.search-box.search-elem input[type="text"] {
    padding: 0;
    height: 72px;
    font-size: 36px;
    font-weight: 300;
    border: none;
    border-bottom: solid 2px #999;
    transition: border 0.3s;
    border-radius: 0;
    background: transparent;
    font-weight: 700;
    color: #FFF;
    width: 100%; }

.search-box.search-elem input[type="text"]:focus {
    border-bottom: solid 2px $color_theme;
    box-shadow: none;
    outline: none; }

.search-box.search-elem label.placeholder {
    position: absolute;
    top: 10px;
    left: 2rem;
    font-size: 32px;
    font-weight: 300;
    transition: all .3s;
    color: #f5f5f5; }

.search-box.search-elem label.placeholder.move-up {
    top: -25px;
    color: #f8f8f8;
    font-size: 16px;
    left: 0; }


.search-box.search-elem.search-open {
    transform: scale(1);
    opacity: 1;
    visibility: visible; }


.search-btn {
    text-transform: uppercase;
    font-size: 0.875rem;
    padding: 0 12px;
    display: inline-block; }

.search-btn:hover {
    color: #00A896; }

.menu-two {
    .dt-header {
        background: rgba(0,0,0,0.3);

        #discovery-main-menu {


            #menu-home {
                border-right: 1px solid #5f5f5f; } } }

    #discohead {
        &.fixed {
            top: -35px;

            #discovery-main-menu {
                #menu-home {
                    border-color: #d2d2d2; } } } } }


.topbar-wrapper {
    padding: 5px;
    border-bottom: 1px solid #5f5f5f;

    .topbar-left {
        text-align: left;
        width: 50%;
        float: left;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: inline-block;
                margin-right: 20px;
                color: #FFF;
                position: relative;

                span {
                    font-weight: 600; }

                &:first-child {
                    &:after {
                        content: '';
                        position: absolute;
                        right: -12px;
                        top: 6px;
                        height: 12px;
                        width: 1px;
                        background: #f5f5f5; } } } } }

    .topbar-right {
        text-align: right;
        width: 50%;
        float: right;
        .top-soc-link {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: inline-block;
                margin-right: 10px;
                a {
                    color: #FFF;
                    text-decoration: none;

                    &:hover {
                        color: $color_theme; } } } } } }


.menu-wrap {
    width: 95%;
    margin: 0 auto; }


/* Loader */

.loader-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FFF;
    z-index: 999999; }

.cssload-square {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 12px;
    width: 12px;
    background-color: rgb(3,168,244);
    border-radius: 50%;
    animation: cssload-s1 2.3s infinite {
        -o-animation: cssload-s1 2.3s infinite;
        -ms-animation: cssload-s1 2.3s infinite;
        -webkit-animation: cssload-s1 2.3s infinite;
        -moz-animation: cssload-s1 2.3s infinite; } }


.cssload-square div {
    position: absolute;
    background-color: rgb(216,27,96);
    box-shadow: 0 0 10px rgb(249,100,50) {
        -o-box-shadow: 0 0 10px rgb(249,100,50);
        -ms-box-shadow: 0 0 10px rgb(249,100,50);
        -webkit-box-shadow: 0 0 10px rgb(249,100,50);
        -moz-box-shadow: 0 0 10px rgb(249,100,50); }
    opacity: 0.5;
    width: 100%;
    height: 100%;
    top: 6px;
    right: 6px;
    animation: cssload-s1 2.3s infinite {
        -o-animation: cssload-s1 2.3s infinite;
        -ms-animation: cssload-s1 2.3s infinite;
        -webkit-animation: cssload-s1 2.3s infinite;
        -moz-animation: cssload-s1 2.3s infinite; }
    animation-timing-function: cubic-bezier(1,.45,.45,1) {
        -o-animation-timing-function: cubic-bezier(1,.45,.45,1);
        -ms-animation-timing-function: cubic-bezier(1,.45,.45,1);
        -webkit-animation-timing-function: cubic-bezier(1,.45,.45,1);
        -moz-animation-timing-function: cubic-bezier(1,.45,.45,1); }
    transform-origin: 50% {
        -o-transform-origin: 50%;
        -ms-transform-origin: 50%;
        -webkit-transform-origin: 50%;
        -moz-transform-origin: 50%; } }

.cssload-square div + div, .cssload-square div + div div {
    position: absolute;
    background-color: rgb(0,200,80);
    height: 100%;
    width: 100%;
    top: -6px;
    right: -6px; }

.cssload-two {
    transform: rotate(90deg) {
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg); }
    animation: cssload-s1 1.15s infinite {
        -o-animation: cssload-s1 1.15s infinite;
        -ms-animation: cssload-s1 1.15s infinite;
        -webkit-animation: cssload-s1 1.15s infinite;
        -moz-animation: cssload-s1 1.15s infinite; } }


/*Back To Top */
.return-to-top {
    position: fixed;
    bottom: -42px;
    right: 42px;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    cursor: pointer;
    z-index: 998;
    border-radius: 50%;
    opacity: 0;
    -webkit-transition: bottom .5s ease, opacity .5s ease;
    -moz-transition: bottom .5s ease, opacity .5s ease;
    transition: bottom .5s ease, opacity .5s ease;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        opacity: 1;
        display: block;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        transition: all .3s ease;
        border-radius: inherit;
        -webkit-transition: transform .5s ease, opacity .6s ease;
        -moz-transition: transform .5s ease, opacity .6s ease;
        transition: transform .5s ease, opacity .6s ease; }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        background: rgba(0,0,0,0.25);
        -webkit-box-shadow: 0px 0px 0px 0px transparent;
        box-shadow: 0px 0px 0px 0px transparent;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        transition: all .3s ease;
        border-radius: inherit; }

    > i {
        position: relative;
        overflow: hidden;
        font-size: 12px;
        width: inherit;
        height: inherit;
        line-height: inherit;
        display: block;
        color: transparent;
        text-shadow: 0px 0px #fff, 0px 50px #fff;
        -webkit-transition: text-shadow .2s ease;
        -moz-transition: text-shadow .2s ease;
        transition: text-shadow .2s ease;
        z-index: 1; }

    &:hover {
        &:after {
            transform: scale(1.07);
            background: $color_theme;
            -webkit-box-shadow: 0px 10px 20px 8px rgba(0,0,0,0.15);
            box-shadow: 0px 10px 20px 8px rgba(0,0,0,0.15); }

        > i {
            text-shadow: 0px -50px #fff, 0px 0px #fff; } }

    &.back-top {
        bottom: 42px;
        opacity: 1; } }

@keyframes cssload-s1 {
    50% {
        transform: rotate(360deg);
        opacity: .7; } }



@-o-keyframes cssload-s1 {
    50% {
        -o-transform: rotate(360deg);
        opacity: .7; } }



@-ms-keyframes cssload-s1 {
    50% {
        -ms-transform: rotate(360deg);
        opacity: .7; } }



@-webkit-keyframes cssload-s1 {
    50% {
        -webkit-transform: rotate(360deg);
        opacity: .7; } }



@-moz-keyframes cssload-s1 {
    50% {
        -moz-transform: rotate(360deg);
        opacity: .7; } }




// /* Colorfull Navbar */
// #home-colorfull
//  #discohead
//      &.fixed
//          background: #e91e63
//          box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)

//  #discovery-main-menu
//      > ul
//          > li
//              > a
//                  color: #FFF

//              ul
//                  > li
//                      > a:hover
//                          color: $color-1



//  .fixed
//      #discovery-main-menu
//          .secondary-navigation
//              > ul
//                  > li
//                      .search-btn
//                          span
//                              color: #FFF

//                      .cart-indication
//                          span
//                              color: #FFF

@media (max-width: 768px) {

    .transparent-header {
        #discohead {
            background: rgba(0,0,0,0.3);
            padding: 10px 20px;
            position: relative;

            .logo-normal {
                display: none; }

            &.fixed {
                #nav-toggle {
                    float: right; } } } }

    #discovery-main-menu {
        position: absolute;
        background-color: #fff;
        top: 100%;
        right: 0;
        left: 0;
        padding: 15px 15px;
        border-bottom: 1px solid #eee;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out; }

    #discovery-main-menu.visible {
        visibility: visible;
        opacity: 1; }

    #discovery-main-menu > ul > li > a {
        color: #000;
        font-weight: 600;
        text-transform: uppercase; }

    #discovery-main-menu > ul > li > a:hover {
        color: $color_theme; }

    #discovery-main-menu ul {
        padding: 0;
        list-style: none;
        margin: 0;
        border-top: 1px solid #eee; }

    #discovery-main-menu ul.sub-menu {
        display: none; }

    #discovery-main-menu ul > li {
        position: relative; }

    #discovery-main-menu ul > li > a {
        display: block;
        padding: 7px 5px;
        border-top: 1px solid #eee;
        color: #000;
        position: relative; }

    #discovery-main-menu ul > li > a:hover {
        color: $color_theme; }

    #discovery-main-menu ul > li:first-child > a {
        border-top: none; }

    #discovery-main-menu ul > li > ul {
        padding-left: 10px; }

    #discovery-main-menu ul > li.menu-item-has-children > a:after {
        content: '\f105';
        font: normal normal normal 14px/1 FontAwesome;
        position: absolute;
        top: 0;
        right: 5px;
        height: 100%;
        text-align: center;
        line-height: 2.8em; }

    #discovery-main-menu ul > li.menu-item-has-children.sub-menu-open > a:after {
        content: '\f107'; } }

