.footer-one {
    background: #ecf0f1;
    position: relative;

    .footer-content {
        p {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 30px; } } }

.footer-inner {
    padding: 100px 0;
    text-align: center; }

.social-btns .btn,
.social-btns .btn:before,
.social-btns .btn i {
    transition: all 0.35s;
    transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59); }

.social-btns .btn:before {
    top: 90%;
    left: -110%; }

.social-btns .btn i {
    transform: scale(0.8); }

.social-btns .btn.facebook:before {
    background-color: #3b5998; }

.social-btns .btn.facebook i {
    color: #3b5998; }

.social-btns .btn.twitter:before {
    background-color: #3cf; }

.social-btns .btn.twitter i {
    color: #3cf; }

.social-btns .btn.google:before {
    background-color: #dc4a38; }

.social-btns .btn.google i {
    color: #dc4a38; }

.social-btns .btn.dribbble:before {
    background-color: #f26798; }

.social-btns .btn.dribbble i {
    color: #f26798; }

.social-btns .btn.skype:before {
    background-color: #00aff0; }

.social-btns .btn.skype .fa {
    color: #00aff0; }

.social-btns .btn:focus:before,
.social-btns .btn:hover:before {
    top: -10%;
    left: -10%; }

.social-btns .btn:focus .fa,
.social-btns .btn:hover .fa {
    color: #fff;
    transform: scale(1); }

.social-btns {
    height: 50px;
    margin: auto;
    font-size: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }

.social-btns .btn {
    display: inline-block;
    background-color: #fff;
    width: 50px;
    height: 50px;
    line-height: 38px;
    margin: 0 10px;
    text-align: center;
    position: relative;
    overflow: hidden;
    border-radius: 28%;
    box-shadow: 0 5px 15px -5px rgba(0,0,0,0.1);
    opacity: 0.99; }

.social-btns .btn:before {
    content: '';
    width: 130%;
    height: 130%;
    position: absolute;
    transform: rotate(45deg); }

.social-btns .btn i {
    font-size: 24px;
    vertical-align: middle; }

.copyright {
    padding: 20px 0;
    background: #303030;

    p {
        margin: 0;
        font-size: 16px;
        color: #f5f5f5;

        a {
            color: $color_theme;
            text-decoration: none; } } }



#footer {
    background: #181818;
    text-align: center; }

.footer-details {
    padding: 70px 0;

    .footer-heading {
        font-size: 16px;
        font-weight: 300;
        color: #c2c2c2;
        margin-top: 0;
        margin-bottom: 30px; } }

.footer-logo {
    a {
        display: block;
        margin-bottom: 20px; } }

.footer-soclink {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        margin: 0 2px;
        text-align: center;

        a {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            background: rgba(255,255,255,0.2);
            display: block;
            color: #FFF;

            i {

                line-height: 40px;
                transition: all 0.3s ease-in-out; } }



        &.facebook-bg {
            a {
                &:hover {
                    background: #305fb3;
                    color: #FFF; } } }

        &.twitter-bg {
            a {
                &:hover {
                    background: #5eaade;
                    color: #FFF; } } }

        &.google-bg {
            a {
                &:hover {
                    background: #d7482a;
                    color: #FFF; } } }

        &.linkedin-bg {
            a {
                &:hover {
                    background: #0077B5;
                    color: #FFF; } } }

        &.pinterest-bg {
            a {
                &:hover {
                    background: #bd081c;
                    color: #FFF; } } } } }

.copy-right {
    padding: 18px 0;
    background: #191919;
    border-top: 1px solid #292929;

    p {
        margin-bottom: 0;
        color: #ccc;
        line-height: 30px;

        a {
            color: $color_theme; } }

    .footer-soclink {
        li {
            a {
                height: 30px;
                width: 30px;

                i {
                    line-height: 30px;
                    font-size: 12px;
                    color: #b5b5b5; } }


            &:hover {
                i {
                    color: #FFF; } } } } }

#footer-two {
    background: #252525; }



.footer-title {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    color: #FFF;
    margin-top: 0;
    margin-bottom: 20px; }

.gp-footer-widget {
    padding: 70px 0; }

.footer-about {
    p {
        color: #FFF; } }

.recent-post {
    margin-bottom: 10px;
    border-bottom: 1px solid #333;
    padding-bottom: 10px;

    .post-thumb {
        float: left;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;

        img {
            width: 100%;
            height: auto;
            border-radius: 50%; } }

    .post-content {
        margin-left: 70px;

        h3 {
            color: #FFF;
            margin: 5px 0 0;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            transition: all 0.3s ease-in-out; }

        .post-meta {
            p {
                color: #ccc;
                margin-bottom: 0; } } }

    &:hover {

        .post-thumb {
            opacity: 0.7; }

        .post-content {
            h3 {
                color: $color_theme; } } }

    &:last-child {
        border-bottom: none; } }


/*Instagram Feed*/

#instagram-feed {
    ul {
        padding-left: 0;
        margin: 0;

        li {
            display: inline-block;
            width: 31.5%;
            padding: 0 3px 6px 0;

            a {
                display: block; } } } }


#instagram-feed {
    img {
        max-width: 100%;
        width: 100%; } }


/* Twitter Feed */
#twitter_feed {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin-bottom: 10px;

            .tweet {
                color: #FFF;
                margin: 0;

                a {
                    color: $color_theme; } }

            .interact {
                display: none; }

            .timePosted {
                margin: 0;
                a {
                    color: #ddd; } } } } }

@media screen and (max-width: 768px) {

    .footer-fixed {
        position: static; }

    .footer-title {
        margin-top: 20px; }

    #instagram-feed {
        ul {
            li {
                width: 16%; } } }

    .copy-right {
        text-align: center;

        .footer-soclink {
            margin-top: 10px;
            &.text-right {
                text-align: center; } } } }
