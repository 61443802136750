#client {
    padding: 70px 0 100px; }

.brand-logo {
    img {
        width: 90%; } }


#client-two {
    padding: 100px 0; }

.client {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        width: 24%;
        padding: 25px;
        text-align: center;
        border-right: 1px solid #e7e7e7;
        border-bottom: 1px solid #e7e7e7;
        float: left;
        transition: all 0.3s ease-in-out;


        img {
            width: 120px;
            height: 120px;
            -webkit-filter: grayscale(1);
            filter: grayscale(1); }

        &:hover {
            transform: scale(1.05);
            box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
            border-color: transparent;
            background: #FFF;

            img {
                filter: grayscale(0); } } } }

@media screen and (max-width: 768px) {
    .client {
        li {
            width: 32%;

            &.no-border-right {
                border-right: 1px solid #e7e7e7 !important; }

            &.no-border-bottom {
                border-bottom: 1px solid #e7e7e7 !important; } } } }

@media screen and (max-width: 500px) {
    .client {
        li {
            width: 50%;

            img {
                width: 100px;
                height: 100px; } } } }
