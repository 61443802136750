#work-prosses {
    padding: 100px 0; }


.pss-wrapper {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        width: 22%;
        text-align: center;
        position: relative;
        margin-right: 32px;

        &:after {
            position: absolute;
            content: '';
            width: 35px;
            height: 5px;
            background: #f3f3f3;
            right: -35px;
            top: 26%;
            border-radius: 30px; }

        &:last-child {
            &:after {
                display: none; } } } }

.pss-container {
    .icon-circle {
        height: 160px;
        width: 160px;
        background: #f3f3f3;
        border-radius: 50%;
        margin: 0 auto 20px;
        position: relative;
        box-shadow: 0 5px 50px rgba(0,0,0,0.05);

        i {
            font-size: 60px;
            color: $color_theme;
            line-height: 160px; } } }

.pss-text-area {
    h4 {
        font-size: 24px;
        font-weight: 800;
        margin-bottom: 10px;
        font-family: $font_header;
        letter-spacing: 1px; }

    .count {
        font-size: 20px;
        font-weight: 600;
        color: $color_theme;
        display: block;
        margin-bottom: 15px; } }

/* Work Stape Two */
.work-prosses {
    &#work-prosses-two {
        background: #f5f5f5;
        padding: 100px 0; } }



.gp-process-steps {
    &.gp-process-steps-two {
        .icon-circle {
                background: #FFF; } } }


/* Prosses Colorfull */
.pss-container {
    &.color-one, {
        .icon-circle {
            i {
                color: $color-1; } }

        .pss-text-area {
            .count {
                color: $color-1; } } } }

.pss-container {
    &.color-two, {
        .icon-circle {
            i {
                color: $color-2; } }

        .pss-text-area {
            .count {
                color: $color-2; } } } }

.pss-container {
    &.color-three, {
        .icon-circle {
            i {
                color: $color_theme; } }

        .pss-text-area {
            .count {
                color: $color_theme; } } } }

.pss-container {
    &.color-four, {
        .icon-circle {
            i {
                color: $color-3; } }

        .pss-text-area {
            .count {
                color: $color-3; } } } }


.pss-container {
    &.greadient {
        .icon-circle {
            i {} } } }

.pss-container {
    &.gredient {
        .icon-circle {
            i {
                background: $color_gradian;
                color: #FFF;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; } }

        .pss-text-area {
            .count {
                background: $color_gradian;
                color: #FFF;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; } } } }



@media screen and (max-width: 768px) {
    .pss-wrapper {
        li {
            width: 44%;
            margin-bottom: 20px;
            margin-right: 28px;

            &:nth-child(2) {
                &:after {
                    display: none; } } } } }

@media screen and (max-width: 500px) {
    .pss-wrapper {
        li {
            width: 100%;

            &:after {
                display: none; } } } }
