#skills {
    padding: 100px 0; }

.skill-bar, .skill-bar-two {
    text-align: left;
    margin: 50px 0 0 0;
    padding-left: 0;
    list-style: none;
    max-width: 600px;

    li {
        &.progress {
            border-radius: 0;
            height: 18px;
            margin-bottom: 40px;
            overflow: visible;
            box-shadow: none;
            background: #eee;
            margin-bottom: 50px;
            border-radius: 20px; }

        .skill-bar-wrap {
            margin-bottom: 20px;
            box-shadow: none; }

        .progress-bar {
            height: 18px;
            width: 0;
            display: block;
            text-align: right;
            overflow: visible !important;
            position: relative;
            box-shadow: none;
            border-radius: 20px;
            opacity: 1;
            background: $color_theme;


            &:after {
                position: absolute;
                content: '';
                top: -8px;
                right: 20px;
                content: '';
                width: 1px;
                height: 6px;
                background: #a5a5a5; } }



        .skill-name, .percentage {
            color: #000;
            position: relative;
            top: -25px;
            font-size: 16px; }

        .skill-name {
            position: absolute;
            left: 0;
            font-weight: 700;
            font-size:  16px; } } }

.skill-bar-two {
    li {
        .progress-bar {
            height: 5px; }

        &.progress {
            height: 5px; } } }

.panel-group .panel {
    margin-bottom: 10px;
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none; }

.panel-group .panel-heading {
    padding: 0;
    background: none;
    border: 0; }

.panel-group .panel-heading .panel-title {
    color: #303030;
    font-size: 16px;
    line-height: 1;
    border-radius: 0;
    transition: all 150ms ease-in-out;
    background: rgba(30, 30, 30, 0.1); }

.panel-group .panel-title > a {
    display: block;
    padding: 14px 20px;
    color: #303030;
    font-size: 14px;
    font-weight: 600; }

.panel-group .panel-active a,
.panel-group .panel-title > a:hover,
.panel-group .panel-title > a:focus {
    background: $color_theme;
    border: 0;
    color: #fff;
    position: relative;
    text-decoration: none; }

.panel-group .panel-active a:after {
    left: 25px; }

.panel-default > .panel-heading + .panel-collapse .panel-body {
    border: 0; }

.panel-body {
    padding: 20px;
    background: #f5f5f5; }

.acc-border-radius .panel-group .panel-title > a {}

.acc-border-radius .panel-group .panel-title,
.acc-border-radius .panel-group .panel-active a,
.acc-border-radius .panel-group .panel-title > a:hover,
.acc-border-radius .panel-group .panel-title > a:focus {
    border-radius: 30px; }



.acc-border-radius .panel-body {
    background: transparent; }

section#skills-two {
    padding: 0;

    #skill-bar {
        padding-left: 50px; } }

section#skills-colorfull {
    padding: 0;

    #skill-bar {
        padding-right: 50px; } }

.skill-bar {
    &.gradients {
        li {
            .progress-bar {
                background: $color_gradian; } } }

    li {
       .progress-bar {
            &.color-one {
                background: $color-1; }

            &.color-two {
                background: $color-2; }

            &.color-three {
                background: $color-3; }

            &.color-four {
                background: $color-4; } } } }

.flex-wrap {
    display: flex;
    align-items: center; }

.skills-thumb {
    img {
        width: 100%; } }


