/*!
	Theme Name: Avatar
	Theme URI: http://www.gpthemes.co/products/discovery/
	Author: Golden Plus Theme
	Author URI: http://www.gpthemes.co/
	Description: One Page Multipurpose Business HTML Template
	Version: 1.0.0
	License: GNU General Public License v2 or later
	License URI: http://www.gnu.org/licenses/gpl-2.0.html
	Text Domain: avatar
	Tags: agency,business,coporate,startup
	*/
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Header
    ## Banner
    ## About
    ## Feature
    ## Portfolio
    ## Work Prosses
    ## Service
    ## Pricing
    ## Client
    ## Blog
    ## Testimonial
    ## Skills
    ## Team
    ## Call To Action
    ## Countup
    ## Newsletter
    ## Contact
    ## Product
    ## Footer

  --------------------------------------------------------------*/
@import "./base/variables";
@import "./base/mixins";
@import "./base/base";
@import "./sections/element";
