#main_slider {
    overflow: hidden; }

.tparrows {
    background: transparent;

    &:before {
        font-size: 35px; }

    &:hover {
        background: transparent;

        &:before {
            color: $color_theme; } } }

.tp-bullet {
    border-radius: 50%; }

.tp-bullet {
    &.selected, &:hover {
        background: $color_theme; } }

.tp-caption {
    &.gp-slider-title {
        span {
            color: $color_theme; } } }




#slider_two .zeus .tp-bullet {
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    width: 13px;
    height: 13px;
    border: 2px solid rgb(255, 255, 255); }


#slider_two .zeus .tp-bullet:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease; }


#slider_two .zeus .tp-bullet:hover:after,
#slider_two .zeus .tp-bullet.selected:after {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }


#slider_two .zeus .tp-bullet-image,
#slider_two .zeus .tp-bullet-imageoverlay {
    width: 135px;
    height: 60px;
    position: absolute;
    background: #000;
    background: rgba(0, 0, 0, 0.5);
    bottom: 13px;
    margin-bottom: 10px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    box-sizing: border-box;
    background-size: cover;
    background-position: center center;
    visibility: hidden;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: 4px; }


#slider_two .zeus .tp-bullet-title,
#slider_two .zeus .tp-bullet-imageoverlay {
    z-index: 2;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%); }


#slider_two .zeus .tp-bullet-title {
    color: rgb(255, 255, 255);
    text-align: center;
    line-height: 15px;
    font-size: 13px;
    font-weight: 600;
    z-index: 3;
    visibility: hidden;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: absolute;
    bottom: 45px;
    width: 135px;
    vertical-align: middle; }


#slider_two .zeus .tp-bullet:hover .tp-bullet-title,
#slider_two .zeus .tp-bullet:hover .tp-bullet-image,
#slider_two .zeus .tp-bullet:hover .tp-bullet-imageoverlay {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px) translateX(-50%);
    transform: translateY(0px) translateX(-50%); }


#slider_two .zeus.nav-dir-vertical .tp-bullet-image,
#slider_two .zeus.nav-dir-vertical .tp-bullet-imageoverlay {
    bottom: auto;
    margin-right: 10px;
    margin-bottom: 0px;
    right: 13px;
    transform: translateX(0px) translateY(-50%);
    -webkit-transform: translateX(0px) translateY(-50%); }


#slider_two .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-image {
    transform: translateX(0px) translateY(-50%);
    -webkit-transform: translateX(0px) translateY(-50%); }


#slider_two .zeus.nav-dir-vertical .tp-bullet-title,
#slider_two .zeus.nav-dir-vertical .tp-bullet-imageoverlay {
    z-index: 2;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    transform: translateX(0px) translateY(-50%);
    -webkit-transform: translateX(0px) translateY(-50%); }


#slider_two .zeus.nav-dir-vertical .tp-bullet-title {
    bottom: auto;
    right: 100%;
    margin-right: 10px; }


#slider_two .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-title,
#slider_two .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-image,
#slider_two .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-imageoverlay {
    transform: translateX(0px) translateY(-50%);
    -webkit-transform: translateX(0px) translateY(-50%); }


#slider_two .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-image,
#slider_two .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-imageoverlay {
    bottom: auto;
    margin-left: 10px;
    margin-bottom: 0px;
    left: 13px;
    transform: translateX(0px) translateY(-50%);
    -webkit-transform: translateX(0px) translateY(-50%); }


#slider_two .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet:hover .tp-bullet-image {
    transform: translateX(0px) translateY(-50%);
    -webkit-transform: translateX(0px) translateY(-50%); }


#slider_two .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-title,
#slider_two .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-imageoverlay {
    z-index: 2;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    transform: translateX(0px) translateY(-50%);
    -webkit-transform: translateX(0px) translateY(-50%); }


#slider_two .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-title {
    bottom: auto;
    left: 100%;
    margin-left: 10px; }


#slider_two .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-image,
#slider_two .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-imageoverlay {
    bottom: auto;
    top: 13px;
    margin-top: 10px;
    margin-bottom: 0px;
    left: 0px;
    transform: translateY(0px) translateX(-50%);
    -webkit-transform: translateX(0px) translateX(-50%); }


#slider_two .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet:hover .tp-bullet-image {
    transform: scale(1) translateY(0px) translateX(-50%);
    -webkit-transform: scale(1) translateY(0px) translateX(-50%); }


#slider_two .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-title,
#slider_two .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-imageoverlay {
    z-index: 2;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    transform: translateY(0px) translateX(-50%);
    -webkit-transform: translateY(0px) translateX(-50%); }


#slider_two .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-title {
    bottom: auto;
    top: 13px;
    margin-top: 20px; }


.custom.tparrows {
    cursor: pointer;
    background: #000;
    background: rgba(0,0,0,0.5);
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 100; }

.custom.tparrows:hover {
    background: #000; }

.custom.tparrows:before {
    font-family: "revicons";
    font-size: 15px;
    color: #fff;
    display: block;
    line-height: 40px;
    text-align: center; }

.custom.tparrows.tp-leftarrow:before {
    content: "\e824"; }

.custom.tparrows.tp-rightarrow:before {
    content: "\e825"; }


.new-bullet-bar.tp-bullets:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 10px;
    margin-left: -10px;
    margin-top: -10px;
    box-sizing: content-box; }

.new-bullet-bar .tp-bullet {
    width: 60px;
    height: 3px;
    position: absolute;
    background: #aaa;
    background: rgba(45,48,50,0.2);
    cursor: pointer;
    box-sizing: content-box; }

.new-bullet-bar .tp-bullet:hover,.new-bullet-bar .tp-bullet.selected {
    background: rgba(45,48,50,0.65); }

.new-bullet-bar .tp-bullet-image {}
.new-bullet-bar .tp-bullet-title {}


/* Creative Banner */

#banner-creative {
    position: relative;
    overflow: hidden;
    height: 100vh; }

.modules.scrollview {
    width: 100%;
    height: 100%; }


.modules.scrollview .module {
    width: 100%;
    height: 100%;
    position: absolute; }


.modules.scrollview .module .page {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden; }


.modules.scrollview .module .page .container-1 {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    background-image: url(/assets/img/fff.png);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden; }

.modules.scrollview .module .page .container-1 .container-2 {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    background-image: url(/assets/img/fff.png);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden; }

.modules.scrollview .module .page .container-1 .container-2 .image {
    width: 100%;
    height: 100%; }


.modules.scrollview .module .page .container-1 .container-2 .image .custom {
    width: 100%;
    height: 100%; }

.modules.scrollview .module .page .container-1 .container-2 .image .custom .content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10; }



.banner-bg {
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }

.scene {
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: #0f0f13;
    width: 100%;
    height: 100%; }

// .scene li
//     top: 50% !important
//     left: 50% !important


.layer {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center; }


.layer .imatge {

    // height: 2000px
    // width: 4000px
    // top: -1000px
    // left: -2000px

    // position: absolute

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    animation-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
    animation-name: float;
    animation-iteration-count: infinite;
    animation-direction: alternate; }


@keyframes float {
    0% {
        transform: translate(0%, 0%); }

    100% {
        transform: translate(100%, 100%); } }


.layer img {
    width: 100%; }



/* DUST DELANTE */

#img-1 {
    opacity: 0.8;
    background: url('/assets/img/dust-delante.png');
    background-repeat: repeat;
    animation-duration: 330s;
    -webkit-animation-direction: normal /* Chrome, Safari, Opera */;
    animation-direction: normal; }



/* DUST MEDIO */

#img-2 {
    opacity: 1;
    background: url('/assets/img/dust-medio.png');
    background-repeat: repeat;
    animation-duration: 555s;
    -webkit-animation-direction: normal /* Chrome, Safari, Opera */;
    animation-direction: normal; }



/* FONDO NEGRO ESTRELLAS GRISES */

#img-3 {
    opacity: 1;
    background: url('/assets/img/bg-dust.png');
    background-repeat: repeat;
    /*animation-duration: 800s*/
    -webkit-animation:  800s ease-in-out infinite;
    -webkit-animation-direction: normal /* Chrome, Safari, Opera */;
    animation-direction: normal; }


/* AZUL */

.color1 {
    -webkit-animation: color1 10s ease-in-out infinite; }


@keyframes color1 {
    0% {
        -webkit-transform: rotate(0) translateY(0); }

    25% {
        -webkit-transform: rotate(-3deg) translateY(-25px) translateX(-25px); }

    50% {
        -webkit-transform: rotate(-3deg) translateY(-50px) translateX(-60px); }

    75% {
        -webkit-transform: rotate(-3deg) translateY(-25px) translateX(-25px); }

    100% {
        -webkit-transform: rotate(0) translateY(0); } }


/* LILA */
.colors {
    left:50% !important {}
    top:50% !important {} }

.color2 {
    -webkit-animation: color2 18s ease-in-out infinite; }


@keyframes color2 {
    50% {
        -webkit-transform: rotate(8deg) scale(0.7) translateY(30px) translateX(60px); } }




/* ROJO */

.color3 {
    -webkit-animation: color3 6s ease-in-out infinite; }


@keyframes color3 {
    50% {
        -webkit-transform: rotate(-20deg) translateY(-30px) translateX(45px); } }




/* VERDE */

.color4 {
    -webkit-animation: color4 12s ease-in-out infinite; }


@keyframes color4 {
    50% {
        -webkit-transform: rotate(8deg) scale(1.2) translateY(10px) translateX(-10px); } }









.content {
    width: 100%;
    height: 100%;
    overflow: hidden; }


.content .modules .module.header .content>div {
    position: relative;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    top: 47.5%;
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out; }


.content .modules .module.header .content>div h1 {
    font-family: 'Playfair Display', sans-serif;
    -webkit-font-smoothing: subpixel-antialiased !important;
    font-weight: 700;
    font-size: 80px;
    line-height: 1.1em;
    letter-spacing: 0em;
    max-width: 75%;
    margin: auto;
    color: #fff;
    text-rendering: geometricPrecision; }


.content .modules .module.header .content>div h1 span {

    margin: 0px auto 20px auto;
    color: $color-5;
    font-weight: 700;
    text-rendering: geometricPrecision;
    font-family: 'Montserrat', sans-serif; }




.content .modules .module.header .content>div h1 {
    transform: translate3d(0px, 60px, 0px);
    transition: all .5s ease-in-out; }


.content .modules .module.header .content>div.visible {
    opacity: 1; }


.content .modules .module.header .content>div.visible h1 {
    transform: translate3d(0px, 0px, 0px); }




.content .modules .module .custom>a {
    position: absolute;
    bottom: 32px;
    left: 50%;
    margin-left: -26px;
    width: 51px;
    height: 51px;
    text-align: center;
    z-index: 9;
    background-image: url(/assets/img/down.png) transparent 0 0 no-repeat;
    background-image: none, url("/assets/img/down.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
    opacity: 1;
    z-index: 20; }


.content .modules .module .custom>a:hover {
    opacity: 0.3; }

.typed-cursor {
    opacity: 1;
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite; }

.typed-fade-out {
    opacity: 0;
    animation: 0;
    transition: opacity .25s; }


@keyframes blink {
    0% {
        opacity: 1; }
    50% {
        opacity: 0; }
    100% {
        opacity: 1; } }

@-webkit-keyframes blink {
    0% {
        opacity: 1; }
    50% {
        opacity: 0; }
    100% {
        opacity: 1; } }

@-moz-keyframes blink {
    0% {
        opacity: 1; }
    50% {
        opacity: 0; }
    100% {
        opacity: 1; } }


#banner-particales, #banner-ripple {
    height: 100vh;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    position: relative; }


.header-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;

    h1 {
        color: #fff;
        font-size: 70px;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-weight: 700;
        span {
            font-family: $font_secandary; } }



    p {
        color: #FFF;
        margin-bottom: 30px;
        font-size: 16px; }


    .gp-btn {
        &.btn-border {
            margin-right: 10px;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 500;

            &:hover, &:focus {
                color: #FFF;
                background: $color_theme;
                border-color: $color_theme; } } }

    .gp-btn {

        margin-left: 10px;
        border: 2px solid $color_theme;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;

        &:hover, &:focus {
            border-color: #fff;
            color: #202020;
            background: #FFF; } } }


.scrolldown {
    position: absolute;
    left: 50%;
    bottom: 32px;
    font-size: 20px;
    color: #FFF; }

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .header-content {
        h1 {
            font-size: 40px;
            margin-bottom: 20px; }

        p {
            font-size: 14px;
            margin-bottom: 20px; }

        .gp-btn {
            font-size: 14px !important;
            &.larg {
                padding: 12px 25px; } } }

    .content .modules {
        .module {
            &.header {
                .content {
                    > div {
                        h1 {
                            font-size: 50px; } } } } } } }


@media screen and (min-width: 480px) and (max-width: 767px) {
    .content .modules .module.header .custom>a {
        bottom: 7px; }

    .content .modules .module.project .custom>a {
        bottom: 0px;
        right: 30px;
        margin: 0;
        left: auto; }


    .header-content {
        h1 {
            font-size: 30px;
            margin-bottom: 10px; }

        p {
            font-size: 12px;
            margin-bottom: 10px; }

        .gp-btn {
            font-size: 14px !important;
            &.larg {
                padding: 10px 20px; } } }

    .content .modules {
        .module {
            &.header {
                .content {
                    > div {
                        h1 {
                            font-size: 32px; } } } } } } }



@media screen and (min-width: 320px) and (max-width: 479px) {
    .content .modules .module.header .custom>a {
        bottom: 7px; }

    .content .modules .module.project .custom>a {
        bottom: 0px;
        right: 30px;
        margin: 0;
        left: auto; }



    .header-content {
        h1 {
            font-size: 28px;
            margin-bottom: 10px; }

        p {
            font-size: 12px;
            margin-bottom: 10px;
            width: 350px;
            margin: 0 auto 20px;
            br {
                display: none; } }


        .gp-btn {
            font-size: 14px !important;
            &.larg {
                padding: 10px 20px; } } }

    .content .modules {
        .module {
            &.header {
                .content {
                    > div {
                        h1 {
                            font-size: 32px; } } } } } } }
